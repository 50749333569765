import React, { Fragment } from 'react';
import { Switch, Route, Redirect, Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Button } from 'reactstrap';
import { Helmet } from 'react-helmet';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import SubjectView from './SubjectView';
import ThemedProgress from '../../components/themed/ThemedProgress';
import ThemedFooterButton from '../../components/themed/ThemedFooterButton';
import ThemedTrainingCompleted from '../../components/themed/ThemedTrainingCompleted';
import ThemedLessonsCompleted from '../../components/themed/ThemedLessonsCompleted';
import {
    ThemedText,
    ThemedButton,
} from '../../components/themed/ThemedComponents';
import { formatDate, querystring } from '../../utils/helpers';
import QuizIntro from '../../components/assessment/intro';
import QuizReview from '../../components/assessment/review';
import ShortSubjectsList from '../../components/company/ShortSubjectsList';
import Modal from 'react-modal';
import SendCertificateForm from '../SendCertificateForm';
import classes from 'classnames';
import { useStores } from '../../hooks/use-stores';
import AssessmentsView from '../Assessments/AssessmentsView';
import AssessmentsAnswerView from '../Assessments/AssessmentsAnswerView';

export default observer(({ parentUrl, training, subjectId }) => {
    const { t } = useTranslation();
    const [isFinished, setIsFinished] = React.useState(false);
    const [certificateModal, setCertificateModal] = React.useState(false);
    const [showMobileMenu, setShowMobileMenu] = React.useState(false);
    const { trainingStore, assessmentStore, store, companyStore } = useStores();
    const scrollRefTop = React.createRef();
    const subject = trainingStore.training.subjects.find(
        (x) => x.id === subjectId
    );
    const isStarted = training.assessment && training.assessment.status.started;
    const hasFinishedAssessment =
        training.assessment && training.assessment.status.finished; //training.assessment && training.assessment.status.questionsDone.length === training.assessment.definition.questions.length;

    const getCertificate = () => {
        setCertificateModal(true);
    };

    const closeCertificateModal = () => {
        setCertificateModal(false);
    };

    const sendCertificate = async (name, updateName) => {
        await store.sendCertificate(
            companyStore.currentCompany.company_id,
            trainingStore.training.id,
            name,
            updateName
        );
    };

    const showLessonMenu = () => {
        setShowMobileMenu(true);
    };
    const hideLessonMenu = () => {
        setShowMobileMenu(false);
    };

    const onNextLesson = async () => {
        await trainingStore.reloadTraining();
    };
    const onQuizStart = async () => {
        await assessmentStore.startAssessment(
            training.assessment_id,
            training.assessment
        );
    };

    const onFinish = async () => {
        await trainingStore.reloadTraining();
        let lastNextEnabled = trainingStore.training.nextSubjectDate
            ? true
            : trainingStore.certificateQualified;

        if (lastNextEnabled) setIsFinished(true);
    };

    React.useEffect(() => {
        try {
            if (scrollRefTop.current) {
                scrollRefTop.current.scrollTo(0, 0);
            }
        } catch (e) {
            console.log('Scroll disabled');
        }
    }, [subjectId]);

    const certificateSending = store.certificateSending;
    const {
        certificateQualified,
        assessmentAvailable,
        availableUnfinishedSubjects,
    } = trainingStore;

    let lastNextEnabled = training.nextSubjectDate
        ? true
        : certificateQualified;

    let score =
        training.subjectsSent > 0
            ? (100 * (training.viewed || 0)) / training.subjectsSent
            : 0;

    return (
        <div
            className={classes('wizard', {
                scorm: subject && subject.type === 2,
            })}
        >
            <div className={'wizard-header'}>
                <Link className={'wizard-close'} to={parentUrl}>
                    <img src="/static/img/close.svg" alt={t('Close')} />
                </Link>
                <h1>{training.name}</h1>

                <div
                    className={classes('wizard-mobile-menu ', {
                        show: !showMobileMenu,
                    })}
                    onClick={() => showLessonMenu()}
                ></div>
                <div
                    className={classes('wizard-mobile-menu close', {
                        show: showMobileMenu,
                    })}
                    onClick={() => hideLessonMenu()}
                ></div>
            </div>
            <ThemedProgress narrow value={score} />

            {certificateModal && (
                <Modal
                    className="Modal certificateModal"
                    isOpen={true}
                    onRequestClose={closeCertificateModal}
                >
                    <SendCertificateForm
                        certificateSending={certificateSending}
                        onCancel={closeCertificateModal}
                        onSend={sendCertificate}
                        t={t}
                    />
                </Modal>
            )}

            <Switch>
                <Route
                    path={`${parentUrl}/subjects/quiz/answers`}
                    exact
                    render={() => {
                        if (
                            training.assessment &&
                            !training.assessment.status.started
                        )
                            return (
                                <Redirect to={`${parentUrl}/subjects/quiz`} />
                            );

                        let questionIndex = querystring('index');
                        return (
                            <AssessmentsAnswerView
                                companyId={
                                    companyStore.currentCompany.company_id
                                }
                                assessmentId={training.assessment_id}
                                trainingId={training.id}
                                parentUrl={parentUrl}
                                index={questionIndex}
                            />
                        );
                    }}
                />
                <Route
                    path={`${parentUrl}/subjects/quiz/run`}
                    exact
                    render={() => {
                        if (
                            training.assessment &&
                            !training.assessment.status.started
                        )
                            return (
                                <Redirect to={`${parentUrl}/subjects/quiz`} />
                            );

                        return (
                            <AssessmentsView
                                companyId={
                                    companyStore.currentCompany.company_id
                                }
                                assessmentId={training.assessment_id}
                                trainingId={training.id}
                                onCertificateClick={getCertificate}
                                parentUrl={parentUrl}
                            />
                        );
                    }}
                />
                <Route
                    path={`${parentUrl}/subjects/:id`}
                    render={(props) => {
                        const isQuiz = props.match.params.id === 'quiz';
                        const withQuiz = !!training.assessment_id;
                        const index = _.findIndex(
                            availableUnfinishedSubjects,
                            (x) => x.id === Number(props.match.params.id)
                        );
                        const isCompleted = index < 0;
                        const isFirst = index === 0;
                        const isLast = training.assessment_id
                            ? isQuiz
                            : index === availableUnfinishedSubjects.length - 1;

                        return (
                            <Fragment>
                                {isFinished ? (
                                    <div className="wizard-body">
                                        <div className="completed-banner d-flex flex-fill flex-column align-items-center justify-content-center">
                                            {training.nextSubjectDate ? (
                                                <Fragment>
                                                    <Helmet>
                                                        <title>
                                                            {t('Training')}
                                                        </title>
                                                    </Helmet>
                                                    <ThemedLessonsCompleted />
                                                    <span>
                                                        <strong>
                                                            {t(
                                                                'All Assigned lessons'
                                                            )}{' '}
                                                            <ThemedText>
                                                                {t('completed')}
                                                            </ThemedText>
                                                        </strong>
                                                    </span>
                                                    <span className="details">
                                                        {t(
                                                            'Great job! Next video will be available on'
                                                        )}{' '}
                                                        {formatDate(
                                                            training.nextSubjectDate
                                                        )}
                                                    </span>
                                                </Fragment>
                                            ) : (
                                                <Fragment>
                                                    <Helmet>
                                                        <title>
                                                            {t('Training')}{' '}
                                                            {t('completed')}
                                                        </title>
                                                    </Helmet>
                                                    <ThemedTrainingCompleted />
                                                    <h3>
                                                        {t('Training')}{' '}
                                                        <ThemedText>
                                                            {t('completed')}
                                                        </ThemedText>
                                                    </h3>
                                                    <p
                                                        className="details text-center"
                                                        style={{
                                                            maxWidth: 470,
                                                            fontSize: '14px',
                                                            lineHeight: '1.5em',
                                                        }}
                                                    >
                                                        {training.name}{' '}
                                                        {t(
                                                            'successfully completed'
                                                        )}
                                                    </p>
                                                    <p
                                                        className="details text-center"
                                                        style={{
                                                            maxWidth: 470,
                                                            fontSize: '14px',
                                                            lineHeight: '1.5em',
                                                        }}
                                                    >
                                                        {t(
                                                            'Click the button below to download your'
                                                        )}{' '}
                                                        <ThemedText>
                                                            {t('certificate')}
                                                        </ThemedText>
                                                    </p>
                                                    <br />
                                                    <ThemedButton
                                                        primary
                                                        onClick={getCertificate}
                                                    >
                                                        {t(
                                                            'Download Certificate'
                                                        )}
                                                    </ThemedButton>
                                                </Fragment>
                                            )}
                                        </div>
                                    </div>
                                ) : (
                                    <div className="wizard-body">
                                        <div
                                            className={classes('sidebar ', {
                                                open: showMobileMenu,
                                            })}
                                            onClick={() => hideLessonMenu()}
                                        >
                                            <ShortSubjectsList
                                                certificateQualified={
                                                    certificateQualified
                                                }
                                                assessment_id={
                                                    training.assessment_id
                                                }
                                                assessmentAvailable={
                                                    assessmentAvailable
                                                }
                                                onCertificateRequest={onFinish}
                                                subjects={training.subjects}
                                                parentUrl={parentUrl}
                                                selectedId={
                                                    props.match.params.id
                                                }
                                                isQuiz={isQuiz}
                                                hasFinishedAssessment={
                                                    hasFinishedAssessment
                                                }
                                            />
                                            {subject && subject.type !== 2 && (
                                                <div className="btn-fixed">
                                                    <Button className="btn-link">
                                                        {t('Back to training')}
                                                    </Button>
                                                </div>
                                            )}
                                        </div>
                                        <div
                                            className={'wizard-content'}
                                            ref={scrollRefTop}
                                        >
                                            {isQuiz ? (
                                                <Fragment>
                                                    {hasFinishedAssessment ? (
                                                        <QuizReview
                                                            parentUrl={
                                                                parentUrl
                                                            }
                                                            assessment={
                                                                training.assessment
                                                            }
                                                            assessmentId={
                                                                training.assessment_id
                                                            }
                                                            trainingId={
                                                                training.id
                                                            }
                                                        />
                                                    ) : isStarted ? (
                                                        <Redirect
                                                            to={`${parentUrl}/subjects/quiz/run`}
                                                        />
                                                    ) : (
                                                        <QuizIntro
                                                            onStart={
                                                                onQuizStart
                                                            }
                                                            assessment={
                                                                training.assessment
                                                            }
                                                        />
                                                    )}
                                                </Fragment>
                                            ) : (
                                                <SubjectView
                                                    training={training}
                                                    subject={subject}
                                                    language={store.language}
                                                />
                                            )}
                                        </div>
                                    </div>
                                )}
                                {((subject && subject.type !== 2) ||
                                    isCompleted) && (
                                    <div className={'wizard-footer'}>
                                        <div className={'actions'}>
                                            {!isFirst && !isCompleted && (
                                                <Button
                                                    tag={Link}
                                                    className="mobile-arrow-back"
                                                    color={'link'}
                                                    size={'lg'}
                                                    to={`${parentUrl}/subjects/${
                                                        availableUnfinishedSubjects[
                                                            index - 1
                                                        ].id
                                                    }`}
                                                >
                                                    {t('Previous')}
                                                </Button>
                                            )}
                                            {!isLast &&
                                                (withQuiz ? (
                                                    <ThemedFooterButton
                                                        primary
                                                        continue
                                                        to={`${parentUrl}/subjects/quiz`}
                                                        onClick={onNextLesson}
                                                    >
                                                        {t('Next')}
                                                    </ThemedFooterButton>
                                                ) : (
                                                    <ThemedFooterButton
                                                        primary
                                                        continue
                                                        to={`${parentUrl}/subjects/${
                                                            availableUnfinishedSubjects[
                                                                index + 1
                                                            ].id
                                                        }`}
                                                        onClick={onNextLesson}
                                                    >
                                                        {t('Next lesson')}
                                                    </ThemedFooterButton>
                                                ))}
                                            {(isLast ||
                                                hasFinishedAssessment) &&
                                                !isFinished && (
                                                    <ThemedFooterButton
                                                        primary
                                                        disabled={
                                                            !lastNextEnabled
                                                        }
                                                        continue
                                                        onClick={onFinish}
                                                    >
                                                        {t('Finish')}
                                                    </ThemedFooterButton>
                                                )}
                                            {isLast && isFinished && (
                                                <ThemedFooterButton
                                                    primary
                                                    continue
                                                    to={`${parentUrl}`}
                                                >
                                                    {t('Finish')}
                                                </ThemedFooterButton>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </Fragment>
                        );
                    }}
                />

                {assessmentAvailable &&
                availableUnfinishedSubjects.length === 0 ? (
                    <Route
                        render={() => {
                            return (
                                <Redirect to={`${parentUrl}/subjects/quiz`} />
                            );
                        }}
                    />
                ) : (
                    <Route
                        render={() => {
                            return (
                                training.subjects.length > 0 && (
                                    <Redirect
                                        to={`${parentUrl}/subjects/${availableUnfinishedSubjects[0].id}`}
                                    />
                                )
                            );
                        }}
                    />
                )}
            </Switch>
        </div>
    );
});
