import React from 'react';
import { observer } from 'mobx-react';
//import VideoPlayer from "../components/subject/video-player";
import VideoPlayer from '../components/subject/video-player-decisions';
import { useStores } from '../hooks/use-stores';

export default observer(
    ({ subject, logEvent, onChange, enableAssessmentFinish }) => {
        const { store } = useStores();

        console.log('In video-player with subject:');

        const loadData = async () => {
            if (!store.videoPlayerUrl) await store.loadVideoPlayerUrl();
            if (
                subject &&
                (subject.id || subject.subject_id) &&
                !store.tokenIndex[subject.id || subject.subject_id]
            )
                await store.videoToken(subject.id || subject.subject_id);
        };
        React.useEffect(() => {
            loadData();
        }, [subject]);

        const { videoPlayerUrl, tokenIndex } = store;
        let token = tokenIndex[subject.id || subject.subject_id];

        const videoTokenRequested = async (videoId) => {
            store.videoToken(videoId);
        };

        return (
            <VideoPlayer
                videoTokenRequested={videoTokenRequested}
                token={token && token.token}
                videoPlayerUrl={videoPlayerUrl}
                subject={subject}
                logEvent={logEvent}
                onChange={onChange}
                enableAssessmentFinish={enableAssessmentFinish}
                presetCaptionsLanguage={store.playerPresetCaptionsLanguage}
                captionsSettings={store.playerCaptionsSettings}
            />
        );
    }
);
