import React, { useEffect } from 'react';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import Spinner from '../../components/Spinner';
import { useStores } from '../../hooks/use-stores';
import classes from 'classnames';
import ThemedProgress from '../../components/themed/ThemedProgress';
import ThemedFooterButton from '../../components/themed/ThemedFooterButton';
import ErrorMessage from '../../components/errormessage';
import QuestionAnswer from '../QuestionAnswer';
import SemiCircleProgress from '../../components/semi-circle-progress';
import pluralize from 'pluralize';
import "@awarego/awarego-components/lib/index.css";

export default observer(
    ({ companyId, parentUrl, assessmentId, trainingId, language, index }) => {
        const {
            assessmentStore,
            questionStore,
            store,
            authStore,
            brandingStore,
        } = useStores();
        const {
            loadingData,
            assessment,
            assessmentAnswers,
            loadingAssessmentId,
        } = assessmentStore;
        const { currentUser } = authStore;
        const history = useHistory();
        const { t } = useTranslation();
        const [reviewingQuestionIndex, setReviewingQuestionIndex] =
            React.useState(0);
        const [questionId, setQuestionId] = React.useState(null);
        const {
            error,
            question,
            loadingData: loadingQuestionData,
        } = questionStore;
        const [blockNum, setBlockNum] = React.useState(0);
        const [visibleBlocks, setVisibleBlocks] = React.useState(null);

        const calculateColor = (percentage) => {
            let def = brandingStore.getScoreDefinition(percentage);
            if (def) return def.color;
            if (percentage <= 33) return brandingStore.redColor;
            if (percentage <= 67) return brandingStore.yellowColor;
            return brandingStore.greenColor;
        };

        const getScoreTitle = (percentage) => {
            let def = brandingStore.getScoreDefinition(percentage);
            if (def) return t(def.name);

            //fallback support
            if (percentage <= 33) return 'Bad';
            if (percentage <= 67) return 'Very good';
            return 'Excellent';
        };

        useEffect(() => {
            if (blockNum !== -1 && questionId) {
                window.localStorage.setItem(
                    `block-num-${currentUser.id}-${questionId}-${assessmentId}`,
                    blockNum.toString()
                );
            }
        }, [blockNum, questionId]);

        useEffect(() => {
            loadData();
        }, [assessmentId, language]);

        useEffect(() => {
            if (assessmentAnswers[questionId]) {
                //Only show blocks with an answer
                let visibleBlocks =
                    question &&
                    question.ui.blocks.filter((block) => {
                        return !!assessmentAnswers[questionId].blocks[
                            block.actionId
                        ];
                    });
                setVisibleBlocks(visibleBlocks);
            }
        }, [assessmentAnswers, question]);

        useEffect(() => {
            (async () => {
                if (companyId && questionId) {
                    await questionStore.loadQuestion(
                        companyId,
                        questionId,
                        assessmentId
                    );

                    let _blockNum;
                    _blockNum =
                        parseInt(
                            window.localStorage.getItem(
                                `block-num-${currentUser.id}-${questionId}-${assessmentId}`
                            )
                        ) || 0;
                    setBlockNum(_blockNum);
                }
            })();
        }, [questionId, companyId]);

        const loadData = async () => {
            await assessmentStore.loadAssessment(assessmentId);
            await assessmentStore.loadAnswers(companyId, assessmentId);
            const assessment = assessmentStore.assessment;

            if (!assessment) {
                return history.push('/assessments');
            }

            const isFinished =
                assessment &&
                (assessment.status.finished ||
                    assessment.status.questionsDone.length ===
                        assessment.definition.questions.length);

            if (!isFinished) {
                console.log("User hasn't finished this assessment");
                return history.push('/assessments');
            }

            //If supplying index to start at a specific question
            index = parseInt(index, 10) || 0;
            let q = assessment.definition.questions[index];

            setReviewingQuestionIndex(index);
            setQuestionId(q);
        };

        const onMoveLessonBy = async (indexJump, startAtLastBlock) => {
            let nextReviewQuestionIndex = reviewingQuestionIndex + indexJump;
            setQuestionId(
                assessment.definition.questions[nextReviewQuestionIndex]
            );
            if (indexJump < 0 && startAtLastBlock) {
                setBlockNum(
                    Object.keys(
                        assessmentAnswers[
                            assessment.definition.questions[
                                nextReviewQuestionIndex
                            ]
                        ].blocks
                    ).length - 1 || 0
                );
            } else {
                setBlockNum(0);
            }
            setReviewingQuestionIndex(nextReviewQuestionIndex);
        };

        const onNextBlock = async () => {
            let newBlockNum = Math.min(
                blockNum + 1,
                question.ui.blocks.length - 1
            );
            setBlockNum(newBlockNum);
        };
        const onPrevBlock = async () => {
            let newBlockNum = Math.max(blockNum - 1, 0);
            setBlockNum(newBlockNum);
        };

        const onNextLesson = async () => {
            return onMoveLessonBy(1);
        };

        const onPreviousLesson = async (e, startAtLastBlock = false) => {
            return onMoveLessonBy(-1, startAtLastBlock);
        };

        const onNext = async () => {
            if (!question) return;
            if (hasNextBlock()) return onNextBlock();
            return onNextLesson();
        };

        const onPrevious = async () => {
            if (!question) return;
            if (hasPreviousBlock()) return onPrevBlock();
            return onPreviousLesson(null, true);
        };

        const hasNextBlock = function () {
            return (
                visibleBlocks &&
                blockNum < visibleBlocks.length - 1 &&
                visibleBlocks[blockNum]
            );
        };

        const hasPreviousBlock = function () {
            return blockNum !== 0;
        };

        if (loadingData || loadingAssessmentId || !assessment) {
            return (
                <div className={classes('wizard')}>
                    <br />
                    <br />
                    <h1 className="spinner d-flex justify-content-center">
                        {t('One moment')}
                    </h1>
                    <Spinner />
                </div>
            );
        }

        //TODO: Fix the progress bar, also in AssessmentView.js
        const done =
            assessment &&
            reviewingQuestionIndex + 1 / assessment.definition.questions.length;
        /*const  isFinished = assessment
      && ( assessment.status.finished
          || assessment.status.questionsDone.length === assessment.definition.questions.length )*/
        const isLast =
            reviewingQuestionIndex + 1 ===
            assessment.definition.questions.length;
        const isFirst = reviewingQuestionIndex === 0;

        const scorePercentage = assessmentAnswers[questionId]
            ? Math.max(
                  0,
                  Math.round(
                      (assessmentAnswers[questionId].totalScore /
                          assessmentAnswers[questionId].maxScore) *
                          100
                  )
              )
            : 0;

        if (
            assessment &&
            !assessment.status.started &&
            !assessment.status.finished
        ) {
            if (trainingId)
                return <Redirect to={`${parentUrl}/subjects/quiz`} />;
            else return <Redirect to={`${parentUrl}/${assessmentId}`} />;
        }

        return (
            <div className={classes('wizard')}>
                <div className={'wizard-header'}>
                    <Link className={'wizard-close'} to={parentUrl}>
                        <img src="/static/img/close.svg" alt={t('Close')} />
                    </Link>
                    <div className="wizard-mobile-header">
                        <h1 className="started">{assessment.name}</h1>
                        <div className="wizard-header-status">
                            {reviewingQuestionIndex + 1}/
                            {assessment.definition.questions.length}{' '}
                            {pluralize(
                                t('question'),
                                assessment.definition.questions.length
                            )}
                        </div>
                    </div>
                    <div className="desktopOnly">
                        <div className="wizard-header-answers">
                            <span>
                                {reviewingQuestionIndex + 1}/
                                {assessment.definition.questions.length}{' '}
                                {pluralize(
                                    t('question'),
                                    assessment.definition.questions.length
                                )}
                            </span>
                            <div>
                                {' '}
                                {question
                                    ? t('assessment:' + question.name)
                                    : ''}
                            </div>
                        </div>
                        <div className="wizard-header-score">
                            {assessmentAnswers[questionId] ? (
                                <>
                                    <span>
                                        {t('Your score is')}{' '}
                                        <span
                                            style={{
                                                color: calculateColor(
                                                    scorePercentage
                                                ),
                                            }}
                                        >
                                            {' '}
                                            {getScoreTitle(scorePercentage)}
                                        </span>
                                    </span>
                                    <div className="progress-semicircle small">
                                        <SemiCircleProgress
                                            percentage={scorePercentage}
                                            size="small"
                                        />
                                    </div>
                                </>
                            ) : (
                                ''
                            )}
                        </div>
                    </div>
                </div>
                <ThemedProgress narrow value={done} />
                <div className="wizard-body">
                    <div className={'wizard-content wide'}>
                        <div className="score-line">
                            <div className="score-group">
                                <span className="progress-semicircle small">
                                    <SemiCircleProgress
                                        percentage={scorePercentage}
                                        size="small"
                                    />
                                </span>
                                <span
                                    className="score-in-words"
                                    style={{
                                        color: calculateColor(scorePercentage),
                                    }}
                                >
                                    {' '}
                                    {getScoreTitle(scorePercentage)}
                                </span>
                            </div>
                            <a href="#question-answer-anchor">
                                <div className="score-details">
                                    {t('Score details')} ↓
                                </div>
                            </a>
                        </div>
                        <ErrorMessage error={error} />
                        <QuestionAnswer
                            loadingData={loadingQuestionData}
                            question={question}
                            blockNum={blockNum}
                            onPrevBlock={onPrevBlock}
                            onNextBlock={onNextBlock}
                            questionId={questionId}
                            assessmentId={assessmentId}
                            userId={currentUser.id}
                            userName={currentUser.name}
                            userEmail={currentUser.email}
                            language={store.language}
                            questionAnswers={assessmentAnswers[questionId]}
                            visibleBlocks={visibleBlocks}
                        />
                    </div>
                </div>

                <div className={'wizard-footer'}>
                    <div className={'actions answers'}>
                        <ThemedFooterButton
                            secondary
                            continue
                            style={{ marginLeft: 'auto' }}
                            disabled={
                                loadingQuestionData ||
                                (isFirst && !hasPreviousBlock())
                            }
                            onClick={onPrevious}
                        >
                            {t('Previous')}
                        </ThemedFooterButton>
                        {isLast && !hasNextBlock() ? (
                            <Link to={parentUrl}>
                                <ThemedFooterButton
                                    primary
                                    continue
                                    style={{ marginLeft: '20px' }}
                                >
                                    {t('Close')}
                                </ThemedFooterButton>
                            </Link>
                        ) : (
                            <>
                                <ThemedFooterButton
                                    secondary
                                    continue
                                    style={{ marginLeft: '20px' }}
                                    disabled={loadingQuestionData}
                                    onClick={onNext}
                                >
                                    {t('Next')}
                                </ThemedFooterButton>
                            </>
                        )}
                    </div>
                </div>
            </div>
        );
    }
);
