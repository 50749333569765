import React, { useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import { observer } from 'mobx-react';
import TrainingView from './TrainingView';
import { useStores } from '../../hooks/use-stores';
import TrainingsList from './TrainingsList';
import HomeViewContainer from '../Home/HomeViewContainer';

export default observer(({ companyId }) => {
    const { store } = useStores();
    useEffect(() => {
        store.resetContext();
    }, [companyId]);

    const language = store.language;

    return (
        <Switch>
            <Route
                path={`/trainings/`}
                exact
                render={() => (
                    <HomeViewContainer
                        layoutTitle="Awareness and Compliance Training"
                        code={'trainings'}
                        pageTitle={'training programs'}
                        content={<TrainingsList />}
                    />
                )}
            />

            <Route
                path={`/trainings/:id`}
                render={(props) => (
                    <TrainingView
                        match={props.match}
                        companyId={companyId}
                        trainingId={props.match.params.id}
                        language={language}
                    />
                )}
            />
        </Switch>
    );
});
