import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import SemiCircleProgress from '../semi-circle-progress';
import Score from './score';
import { observer } from 'mobx-react';
import { useStores } from '../../hooks/use-stores';
import {
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Hidden,
} from '@material-ui/core';
import { formatDateAndTime } from '../../utils/helpers';
import pluralize from 'pluralize';
import Spinner from '../Spinner';
import { useTranslation } from 'react-i18next';

export default observer(
    ({ parentUrl, assessment, assessmentId, trainingId }) => {
        const { assessmentStore } = useStores();
        const { loadingAssessmentResults, results } = assessmentStore;
        const { t } = useTranslation();

        useEffect(() => {
            if (!loadingAssessmentResults && assessmentId) {
                assessmentStore.loadResults(assessmentId);
            }
        }, [assessmentId]);

        return (
            <div className="quiz-slide center assessment-review">
                <h3>{assessment.name}</h3>
                <p className="mobileOnly">
                    <i className="date-icon done"></i>
                    {t('Completed on')}{' '}
                    {formatDateAndTime(assessment.end || assessment.completed)}
                </p>
                <h4 style={{ textAlign: 'left' }} className="desktopOnly">
                    {results.length} {pluralize(t('question'), results.length)},{' '}
                    {t('completed on')} {formatDateAndTime(assessment.end)}.
                </h4>
                {assessment && (
                    <Score
                        parentUrl={parentUrl}
                        assessment={assessment}
                        horizontal
                    />
                )}
                <br className="desktopOnly" />

                <Table className="quiz-results-table">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <span className="mobileOnly">
                                    {t('Question')}/{t('Threat area')}
                                </span>
                                <span className="desktopOnly">
                                    {t('Question')}
                                </span>
                            </TableCell>
                            <Hidden smDown>
                                <TableCell>{t('Threat area')}</TableCell>
                            </Hidden>
                            <TableCell align="center">{t('Score')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loadingAssessmentResults ? (
                            <TableRow>
                                <TableCell colSpan={3}>
                                    <div
                                        className="spinner-table small"
                                        style={{ textAlign: 'center' }}
                                    >
                                        <Spinner />
                                    </div>
                                </TableCell>
                            </TableRow>
                        ) : (
                            results.map((row, i) => {
                                return (
                                    <TableRow key={i}>
                                        <TableCell>
                                            <Link
                                                to={`${parentUrl}${
                                                    trainingId
                                                        ? '/subjects/quiz/answers'
                                                        : `/${assessment.id}/answers`
                                                }?index=${i}`}
                                            >
                                                {t('assessment:' + row.name)}
                                            </Link>
                                            <div className="mobileOnly">
                                                <br />
                                                {t('assessment:' + row.area)}
                                            </div>
                                        </TableCell>
                                        <Hidden smDown>
                                            <TableCell>
                                                {t('assessment:' + row.area)}
                                            </TableCell>
                                        </Hidden>
                                        <TableCell>
                                            <div className="progress-semicircle small">
                                                <SemiCircleProgress
                                                    percentage={Math.max(
                                                        0,
                                                        Math.round(
                                                            (row.total_score /
                                                                row.max_score) *
                                                                100
                                                        )
                                                    )}
                                                    size="small"
                                                />
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                );
                            })
                        )}
                    </TableBody>
                </Table>
            </div>
        );
    }
);
