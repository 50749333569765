import React, { Fragment, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Col, Row, Progress } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import pluralize from 'pluralize';
import Spinner from '../../components/Spinner';
import { percentToColor, formatDate } from '../../utils/helpers';
import { ThemedButton } from '../../components/themed/ThemedComponents';
import { useStores } from '../../hooks/use-stores';
import EmptyItem from '../Home/EmptyItem';

export default observer(() => {
    const { t } = useTranslation();
    const { trainingStore, store } = useStores();
    const { loadingData, trainings } = trainingStore;

    useEffect(() => {
        store.setLayoutTitle(t('Awareness and Compliance Training'));
    }, [store, t]);

    return (
        <Fragment>
            {loadingData ? (
                <div className="d-flex flex-column justify-content-center ">
                    <br />
                    <br />
                    <h1 className="spinner">{t('One moment')}</h1>
                    <Spinner />
                </div>
            ) : trainings.length > 0 ? (
                <div className="training-list">
                    {trainings.reverse().map((automat, i) => {
                        let weeksFromStart = Math.max(
                            moment(automat.created).diff(moment(), 'weeks'),
                            1
                        );
                        let score =
                            (100 * (automat.viewed || 0)) /
                            automat.subjectsSent;
                        let sent =
                            (100 * (automat.subjectsSent || 0)) /
                            automat.totalSubjects;

                        return (
                            <div
                                className="training-progress block mobile"
                                key={i}
                            >
                                <Link to={`/trainings/${automat.id}`}>
                                    <div className={'training-progress-title'}>
                                        <h3>
                                            {automat.name ||
                                                `${t(
                                                    'Training created on'
                                                )} ${formatDate(
                                                    automat.created
                                                )}`}
                                        </h3>
                                        <div className={'details-time'}>
                                            {' '}
                                            {automat.minutesSent && (
                                                <Fragment>
                                                    {automat.minutesSent}{' '}
                                                    {t('minutes delivered')}{' '}
                                                    {t('through')}{' '}
                                                    {weeksFromStart}{' '}
                                                    {pluralize(
                                                        t('week'),
                                                        weeksFromStart
                                                    )}
                                                </Fragment>
                                            )}
                                        </div>
                                    </div>
                                    <Row>
                                        <Col xs="12" md="3">
                                            <strong>{t('Your Score')}</strong>
                                        </Col>
                                        <Col xs="10" md="8">
                                            <Progress
                                                color={percentToColor(score)}
                                                value={score}
                                            />
                                        </Col>
                                        <Col
                                            xs="2"
                                            md="1"
                                            className="nowrap flex-start p0"
                                        >
                                            <strong>
                                                {score.toFixed(0)}
                                                <small>%</small>
                                            </strong>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs="12" md="3">
                                            <strong>
                                                {t('Available lessons')}
                                            </strong>
                                        </Col>
                                        <Col xs="10" md="8">
                                            <Progress
                                                color={percentToColor(sent)}
                                                value={sent}
                                            />
                                        </Col>
                                        <Col
                                            xs="2"
                                            md="1"
                                            className="nowrap flex-start p0"
                                        >
                                            <strong>
                                                {automat.subjectsSent}/
                                                {automat.totalSubjects}
                                            </strong>
                                        </Col>
                                    </Row>
                                    {automat.active ? (
                                        <Row>
                                            <Col
                                                md="12"
                                                className="opacity08 text-size-09"
                                            >
                                                {automat.nextSubjectDate ? (
                                                    <Fragment>
                                                        {automat.scheduleType ===
                                                        1 ? (
                                                            <Fragment>
                                                                {t(
                                                                    'All lessons delivered.'
                                                                )}
                                                            </Fragment>
                                                        ) : (
                                                            <Fragment>
                                                                {
                                                                    automat.subjectsSent
                                                                }{' '}
                                                                {t('of')}{' '}
                                                                {
                                                                    automat.totalSubjects
                                                                }{' '}
                                                                {t(
                                                                    'lessons delivered.'
                                                                )}
                                                                {t('Next')}{' '}
                                                                {automat.nextSubjectsToSend >
                                                                    1 &&
                                                                    automat.nextSubjectsToSend}{' '}
                                                                {t(
                                                                    'scheduled on'
                                                                )}{' '}
                                                                {formatDate(
                                                                    automat.nextSubjectDate
                                                                )}
                                                            </Fragment>
                                                        )}
                                                    </Fragment>
                                                ) : (
                                                    t('All delivered')
                                                )}
                                                <div className="opacity08 text-size-09"></div>
                                            </Col>
                                        </Row>
                                    ) : (
                                        <Row>
                                            <Col md="3">
                                                <strong>Status</strong>
                                            </Col>
                                            <Col md="9">
                                                {t('Finished')}{' '}
                                                {automat.finished && (
                                                    <Fragment>
                                                        {' '}
                                                        {t('on')}{' '}
                                                        {moment(
                                                            automat.finished
                                                        ).format('LL')}{' '}
                                                    </Fragment>
                                                )}
                                            </Col>
                                        </Row>
                                    )}
                                    <Row>
                                        <Col md="12">
                                            <div className="d-flex flex-column flex-fill  justify-content-center align-items-end">
                                                <ThemedButton secondary>
                                                    {t('View')}
                                                </ThemedButton>
                                            </div>
                                        </Col>
                                    </Row>
                                </Link>
                            </div>
                        );
                    })}
                </div>
            ) : (
                <EmptyItem item="training" />
            )}
        </Fragment>
    );
});
