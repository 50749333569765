import React from 'react';
import { Row, Col } from 'reactstrap';
import chunk from 'lodash/chunk';
import { observer } from 'mobx-react';
import { ThemedAnswer } from '../themed/ThemedComponents';
import { useTranslation } from 'react-i18next';

const CONSTANTS = require('../../utils/constants');

export default observer(
    ({ subject, question, answers, isAnswered, logEvent }) => {
        const { t } = useTranslation();
        const [chunks, setChunks] = React.useState(chunk(answers, 2));
        const [clicked, setClicked] = React.useState({});

        React.useEffect(() => {
            setChunks(chunk(answers, 2));
            setClicked({});
        }, [answers]);

        const answer = (answer, position) => {
            if (!isAnswered && !clicked[position]) {
                setClicked({ ...clicked, [position]: true });
                if (answer.correct) {
                    logEvent(subject, CONSTANTS.EVENTS.ANSWARE_CORRECT);
                } else {
                    logEvent(subject, CONSTANTS.EVENTS.ANSWARE_INCORRECT);
                }
            }
        };

        if (!question) {
            return null;
        } else {
            return (
                <div className="section-exam">
                    <div className="exam-question-holder">
                        {/* Todo add question counter*/}
                        <div className="exam-question-counter">
                            {t('Question')} 1/1
                        </div>
                        <div className="exam-question">{question}</div>
                        <Row>
                            {chunks.map((x, i) => {
                                return (
                                    <Col
                                        md="6"
                                        xs="12"
                                        className="mobile-mb-20"
                                        key={'col-' + i}
                                    >
                                        {x.map((a, j) => {
                                            return (
                                                <ThemedAnswer
                                                    correct={
                                                        a.correct &&
                                                        (isAnswered ||
                                                            clicked[
                                                                `${i}-${j}`
                                                            ])
                                                    }
                                                    incorrect={
                                                        !a.correct &&
                                                        clicked[`${i}-${j}`]
                                                    }
                                                    onClick={() =>
                                                        answer(a, `${i}-${j}`)
                                                    }
                                                    key={'ans-' + j}
                                                >
                                                    {a.text}
                                                </ThemedAnswer>
                                            );
                                        })}
                                    </Col>
                                );
                            })}
                        </Row>
                    </div>
                </div>
            );
        }
    }
);
