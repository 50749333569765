/* eslint-disable no-undef */
import { observable, action, computed } from 'mobx';
import services from '../services';
import { awareGoTheme as styledComponentsThemeFile } from '../theme';
import { AwareGOTheme as muiThemeFile } from '../theme-mui';
import { reaction } from 'mobx/lib/mobx';
import _ from 'lodash';
import { querystring } from '../utils/helpers';

const { REACT_APP_LMS_URL: lmsUrl } = process.env;
const pattern = /^((http|https):\/\/)/;

class BrandingStore {
    @observable brandingLoaded = false;
    @observable branding = null;

    redColor = '#EB5757';
    yellowColor = '#F2C94C';
    greenColor = '#20A26B';

    constructor(companyStore) {
        this.companyStore = companyStore;

        reaction(
            () => this.companyStore.currentCompany,
            (company) => {
                if (company) {
                    this.loadBranding(company.company_id);
                } else this.loadBranding();
            }
        );

        reaction(
            () => this.companyStore.noCompanies,
            (data) => {
                if (data) {
                    this.loadBranding();
                }
            }
        );
    }

    @action
    async loadBranding(companyId) {
        this.brandingLoaded = false;

        let companyInUrl = Number(querystring('o'));

        try {
            let result = companyId
                ? await services.Companies.BrandingService(
                      companyId || companyInUrl
                  ).list({
                      returnUrl:
                          window.location.pathname + window.location.search,
                  })
                : await services.Branding.list({
                      companyId: companyInUrl,
                      returnUrl:
                          window.location.pathname + window.location.search,
                  });
            this.branding = result;
            this.brandingLoaded = true;
        } catch (e) {}
    }

    @computed get logo() {
        let logoPath = '/static/img/logo_simple.png';

        if (
            this.companyStore.currentCompany &&
            this.companyStore.currentCompany.logo_name
        ) {
            logoPath =
                process.env.REACT_APP_RESOURCES_URL +
                '/' +
                process.env.REACT_APP_COMPANY_RESOURCES_DIR +
                '/' +
                this.companyStore.currentCompany.logo_name;
        } else if (this.branding && this.branding['ui.logo']) {
            logoPath = this.branding['ui.logo'];
        }
        return logoPath;
    }

    @computed get favicon() {
        if (!this.brandingLoaded) return null;
        return (
            (this.branding && this.branding['ui.favicon']) ||
            'favicon_generic.ico'
        );
    }

    @computed get name() {
        if (!this.brandingLoaded) return null;
        return (this.branding && this.branding['name']) || 'AwareGo';
    }

    @computed get account_name() {
        if (!this.brandingLoaded) return null;
        return (this.branding && this.branding['account_name']) || 'AwareGO';
    }

    @computed get supportEmail() {
        if (!this.brandingLoaded) return null;
        return (
            (this.branding && this.branding['contact.support']) ||
            'support@awarego.com'
        );
    }

    @computed get loginUrl() {
        if (!this.brandingLoaded) return null;
        return this.branding && this.branding['loginUrl'];
    }

    @computed get lmsDomain() {
        if (!this.brandingLoaded) return lmsUrl;
        if (this.branding && this.branding['lms_domain']) {
            let url = this.branding['lms_domain'];
            if (!pattern.test(url)) {
                url = 'https://' + url;
            }
            return url;
        }
        return lmsUrl;
    }

    @computed get privacyUrl() {
        const defaultPrivacyUrl = 'https://www.awarego.com/privacy';
        if (!this.brandingLoaded) return defaultPrivacyUrl;
        return (
            (this.branding && this.branding['ui.privacy_url']) ||
            defaultPrivacyUrl
        );
    }

    getScoreDefinition(percentage) {
        let roundedPercentage = Math.floor(percentage);
        if (this.brandingLoaded && this.branding.assessmentScoreRanges) {
            let def = this.branding.assessmentScoreRanges.company.find(
                (x) =>
                    roundedPercentage >= x.from_score &&
                    roundedPercentage <= x.to_score
            );
            if (!def)
                def = this.branding.assessmentScoreRanges.global.find(
                    (x) =>
                        roundedPercentage >= x.from_score &&
                        roundedPercentage <= x.to_score
                );

            if (def && !def.color) {
                switch (def.category) {
                    case 'error':
                        def.color = this.redColor;
                        break;
                    case 'warn':
                        def.color = this.yellowColor;
                        break;
                    case 'info':
                    case 'success':
                    default:
                        def.color = this.greenColor;
                }
            }
            return def;
        }
    }

    @computed get theme() {
        let perPartner = {};

        if (this.brandingLoaded) {
            if (this.branding['ui.colors.primary'])
                perPartner.primary = this.branding['ui.colors.primary'];
            if (this.branding['ui.colors.secondary'])
                perPartner.secondary = this.branding['ui.colors.secondary'];
        }

        let perCompany = {};
        if (this.companyStore.currentCompany) {
            if (this.companyStore.currentCompany.color_main)
                perCompany.primary =
                    this.companyStore.currentCompany.color_main;
            if (this.companyStore.currentCompany.color_secondary)
                perCompany.secondary =
                    this.companyStore.currentCompany.color_secondary;
        }

        let theme = _.defaults(
            {},
            perCompany,
            perPartner,
            styledComponentsThemeFile
        );
        return theme;
    }

    @computed get muiTheme() {
        const theme = muiThemeFile;

        if (this.brandingLoaded) {
            if (this.branding['ui.colors.primary'])
                theme.palette.primary = theme.palette.augmentColor({
                    main: this.branding['ui.colors.primary'],
                });
            if (this.branding['ui.colors.secondary'])
                theme.palette.secondary = theme.palette.augmentColor({
                    main: this.branding['ui.colors.secondary'],
                });
        }

        return theme;
    }
}

export default BrandingStore;
