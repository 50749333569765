import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import classes from 'classnames';
import { Col, Container, Row } from 'reactstrap';

import { GroupOfBlocks, RadioButtons } from '@awarego/awarego-components';
import { CheckBoxes } from '@awarego/awarego-components';
import { NumericalScale } from '@awarego/awarego-components';
import { OrderableList } from '@awarego/awarego-components';
import { Questionwithbuttons } from '@awarego/awarego-components';
import { Questionwithimage } from '@awarego/awarego-components';
import { Questionwithsocialpost } from '@awarego/awarego-components';
import { YesNoList } from '@awarego/awarego-components';
import { Conversation } from '@awarego/awarego-components';
import { PasswordTester } from '@awarego/awarego-components';
import { DecisionAnswers } from '@awarego/awarego-components';
import { BlockAnswer } from '@awarego/awarego-components';

import { Illustration } from '@awarego/awarego-components';
import { InteractiveImg } from '@awarego/awarego-components';
import { Message } from '@awarego/awarego-components';
import { Password } from '@awarego/awarego-components';
import { Question } from '@awarego/awarego-components';
import { Video } from '@awarego/awarego-components';

import { EmailQuizGeneral } from '@awarego/awarego-components';
import { CustomPresentation } from '@awarego/awarego-components';
import { EmailQuizMdlzNewsletter } from '@awarego/awarego-components';

import { Icon, Fab } from '@material-ui/core';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { useStores } from '../hooks/use-stores';

import { useTranslation } from 'react-i18next';
import { keyBy } from 'lodash';

export default observer(
    ({
        question,
        loadingData,
        questionAnswers,
        visibleBlocks,
        userName,
        userEmail,
        blockNum,
        onNextBlock,
        onPrevBlock,
    }) => {
        let blockAnswers;
        const [hideBlock, doHideBlock] = React.useState(false);
        const [token, setToken] = React.useState(null);
        const { brandingStore, store, companyStore } = useStores();
        const { t } = useTranslation('assessment');
        const {
            videoPlayerUrl,
            tokenIndex,
            tokens,
            playerPresetCaptionsLanguage,
            playerCaptionsSettings,
        } = store;
        const { currentCompany } = companyStore;

        const videoTokenRequested = async (videoId) => {
            await store.videoToken(videoId);
        };

        React.useEffect(() => {
            const loadVideoData = async (subject) => {
                if (!videoPlayerUrl) await store.loadVideoPlayerUrl();
                if (
                    subject &&
                    (subject.id || subject.subject_id) &&
                    !tokenIndex[subject.id || subject.subject_id]
                ) {
                    await store.videoToken(subject.id || subject.subject_id);
                }
                //TODO: Why isn't tokenIndex from store working?
                let tokenArray = keyBy(tokens, 'id');
                setToken(tokenArray[subject.id || subject.subject_id]);
            };

            doHideBlock(
                question &&
                    question.ui.presentation &&
                    (question.ui.presentation.type === 'password' ||
                        question.ui.presentation.fullScreen)
            );
            if (
                question &&
                question.ui.presentation &&
                question.ui.presentation.type === 'video'
            )
                loadVideoData(question.ui.presentation.subject);
        }, [question]);

        const renderBlock = (block) => {
            if (!block) return;
            blockAnswers =
                questionAnswers && questionAnswers.blocks[block.actionId];
            switch (block.type) {
                case 'scale':
                    return 'scale';
                case 'checkbox':
                    return <CheckBoxes ui={block} answer={blockAnswers} />;
                case 'radio':
                    return <RadioButtons ui={block} answer={blockAnswers} />;
                case 'password-tester':
                    return <PasswordTester ui={block} answer={blockAnswers} />;
                case 'numericalscale':
                    return <NumericalScale ui={block} answer={blockAnswers} />;
                case 'orderablelist':
                    return <OrderableList ui={block} answer={blockAnswers} />;
                case 'yes-no-list':
                    return <YesNoList ui={block} answer={blockAnswers} />;
                case 'conversation':
                    return <Conversation ui={block} answer={blockAnswers} />;
                case 'questionwithbuttons':
                    return (
                        <Questionwithbuttons ui={block} answer={blockAnswers} />
                    );
                case 'questionwithimage':
                    return (
                        <Questionwithimage ui={block} answer={blockAnswers} />
                    );
                case 'questionwithsocialpost':
                    return (
                        <Questionwithsocialpost
                            ui={block}
                            answer={blockAnswers}
                        />
                    );
                case 'groupOfBlocks':
                    return <GroupOfBlocks ui={block} answer={blockAnswers} />;
                case 'decision':
                    return <DecisionAnswers ui={block} answer={blockAnswers} />;
                default:
                    return 'Unknown block type: ' + block.type;
            }
        };

        const calculateColor = (percentage) => {
            let def = brandingStore.getScoreDefinition(percentage);
            if (def) return def.color;
            if (percentage <= 33) return brandingStore.redColor;
            if (percentage <= 67) return brandingStore.yellowColor;
            return brandingStore.greenColor;
        };

        const renderBlockAnswer = (block) => {
            if (!block) return;
            blockAnswers =
                questionAnswers && questionAnswers.blocks[block.actionId];

            /** Getting additional text from settings, will be added at the bottom if the answer description */
            const additionalText =
                currentCompany.settings[
                    `ui.assessments.additional_answer_text.${question.id}`
                ] || '';

            return (
                <BlockAnswer
                    blockAnswers={blockAnswers}
                    hideBlock={hideBlock}
                    question={question}
                    calculateColor={calculateColor}
                    blockType={block.type}
                    additionalText={additionalText}
                />
            );
        };

        const renderPresentation = (presentation) => {
            let score = questionAnswers && questionAnswers.totalScore;
            switch (presentation && presentation.type) {
                case 'illustration':
                    return <Illustration ui={presentation} />;
                case 'interactive-img':
                    return <InteractiveImg ui={presentation} />;
                case 'email':
                    return (
                        <EmailQuizGeneral
                            ui={presentation}
                            userEmail={userEmail}
                            userName={userName}
                            isInAnswerMode={true}
                        />
                    );
                case 'custom':
                    return <CustomPresentation ui={presentation} />;
                case 'email-mdlz-newsletter':
                    return (
                        <EmailQuizMdlzNewsletter
                            ui={presentation}
                            userEmail={userEmail}
                        />
                    );
                case 'message':
                    return <Message ui={presentation} />;
                case 'password':
                    return (
                        <Password ui={presentation} scorePercentage={score} />
                    );
                case 'question':
                    return <Question ui={presentation} />;
                case 'video':
                    //return <Video ui={presentation}/>
                    return (
                        <Video
                            ui={presentation}
                            videoTokenRequested={videoTokenRequested}
                            videoPlayerUrl={videoPlayerUrl}
                            token={token}
                            playerPresetCaptionsLanguage={
                                playerPresetCaptionsLanguage
                            }
                            playerCaptionsSettings={playerCaptionsSettings}
                        />
                    );
                default:
                    return 'Unknown presentation type: ' + presentation.type;
            }
        };

        if (loadingData || !question) return;

        return (
            <Container>
                <div className="home">
                    <Row>
                        {question && question.ui.presentation && (
                            <Col md="6" xs="12">
                                <div
                                    className={classes({
                                        'full-screen':
                                            question.ui.presentation.fullScreen,
                                    })}
                                >
                                    {renderPresentation(
                                        question.ui.presentation
                                    )}
                                </div>
                            </Col>
                        )}
                        <Col
                            md={
                                question && question.ui.presentation
                                    ? '6'
                                    : '12'
                            }
                            xs="12"
                            className="assessment-block-type-2"
                            id="question-answer-anchor"
                        >
                            <div className="d-flex justify-content-between assessment-block-type-2 desktopOnly">
                                {visibleBlocks && visibleBlocks.length > 1 ? (
                                    <div className="assessment-carousel desktopOnly--carousel">
                                        <div className="assessment-carousel-progress">
                                            {visibleBlocks.map((x, i) => {
                                                if (blockNum === i) {
                                                    return (
                                                        <Icon key={'icon-' + i}>
                                                            <img
                                                                src="/static/img/icon-step-active.svg"
                                                                alt={t(
                                                                    'Next step'
                                                                )}
                                                            />
                                                        </Icon>
                                                    );
                                                } else {
                                                    return (
                                                        <Icon key={'icon-' + i}>
                                                            <img
                                                                src="/static/img/icon-step-following.svg"
                                                                alt={t(
                                                                    'Next step'
                                                                )}
                                                            />
                                                        </Icon>
                                                    );
                                                }
                                            })}
                                        </div>

                                        <div className="assessment-carousel-buttons">
                                            <Fab
                                                size={'small'}
                                                disabled={blockNum === 0}
                                                onClick={onPrevBlock}
                                            >
                                                <NavigateBeforeIcon />
                                            </Fab>
                                            <Fab
                                                size={'small'}
                                                disabled={
                                                    blockNum ===
                                                        visibleBlocks.length -
                                                            1 ||
                                                    !visibleBlocks[blockNum]
                                                }
                                                onClick={onNextBlock}
                                            >
                                                <NavigateNextIcon />
                                            </Fab>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="assessment-padding" />
                                )}
                            </div>
                            <div className="desktopOnly">
                                {!hideBlock &&
                                    visibleBlocks &&
                                    renderBlock(visibleBlocks[blockNum])}
                                {visibleBlocks &&
                                    renderBlockAnswer(visibleBlocks[blockNum])}
                            </div>
                            <div className="mobileOnly">
                                {visibleBlocks &&
                                    visibleBlocks.map((block, i) => (
                                        <Fragment key={i}>
                                            {visibleBlocks.length > 1 ? (
                                                <div className="mobile-subquestion">
                                                    {t('SUB-QUESTION')} {i + 1}
                                                </div>
                                            ) : (
                                                ''
                                            )}
                                            {renderBlock(visibleBlocks[i])}
                                            {renderBlockAnswer(
                                                visibleBlocks[i]
                                            )}
                                        </Fragment>
                                    ))}
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        );
    }
);
