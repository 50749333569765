import React, { Fragment, useEffect, useState } from 'react';
import { Block } from '@material-ui/icons';
import { Link, useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { find, map } from 'lodash';
import pluralize from 'pluralize';
import Spinner from '../../components/Spinner';
import { useStores } from '../../hooks/use-stores';
import classes from 'classnames';
import ThemedProgress from '../../components/themed/ThemedProgress';
import ThemedFooterButton from '../../components/themed/ThemedFooterButton';
import Question from '../Question';
import QuizIntro from '../../components/assessment/intro';
import Modal from 'react-modal';
import Countdown from 'react-countdown';
import { ThemedButton } from '../../components/themed/ThemedComponents';
import ErrorMessage from '../../components/errormessage';
import Snackbar from '../../components/Snackbar';
import QuizReview from '../../components/assessment/review';
import { pick } from 'lodash';
import { isMobile, getUA } from 'react-device-detect';
import '@awarego/awarego-components/lib/index.css';

function filterBlockValues(blockValues) {
    let result = {};
    map(blockValues, (v, k) => {
        if (v != null && k !== null && k !== 'undefined') result[k] = v;
    });
    return result;
}

export default observer(({ companyId, parentUrl, assessmentId, language }) => {
    const {
        assessmentStore,
        trainingStore,
        questionStore,
        store,
        authStore,
        commonStore,
    } = useStores();
    const { loadingData, assessment, loadingAssessmentId } = assessmentStore;
    const { currentUser } = authStore;
    const history = useHistory();
    const { t } = useTranslation();
    const [isStarted, setIsStarted] = useState(false);
    const [isNextAvailable, setIsNextAvailable] = useState(false);
    const [timeOutModal, setimeOutModal] = useState(false);
    const [questionActions, setQuestionActions] = useState(null);
    const [questionId, setQuestionId] = useState(null);
    const { error, question, loadingData: loadingQuestionData } = questionStore;
    const [blockNum, setBlockNum] = useState(-1);
    const [blockValues, setBlockValues] = useState(null);
    const [completedBlocks, setCompletedBlocks] = useState(null);
    const [questionComplete, setQuestionComplete] = useState(false);
    const [blockTransition, setBlockTransition] = useState(false);
    const [blockFadeIn, setBlockFadeIn] = useState(false);
    const [blockFadeOut, setBlockFadeOut] = useState(false);
    const [transitionTimer, setTransitionTimer] = useState(null);
    const [visibleBlocks, setVisibleBlocks] = useState(null);

    useEffect(() => {
        window.addEventListener('beforeunload', alertUser);
        return () => {
            window.removeEventListener('beforeunload', alertUser);
        };
    });

    const alertUser = (event) => {
        event.preventDefault();
        event.returnValue = '';
    };

    useEffect(() => {
        (async () => {
            if (blockTransition) {
                await onNext(true, false);
            }
            setBlockFadeIn(true);
            setBlockFadeOut(false);
        })();
    }, [blockTransition]);

    useEffect(() => {}, [blockFadeIn]);

    useEffect(() => {}, [blockFadeOut]);

    useEffect(() => {
        if (blockNum !== -1 && questionId) {
            window.localStorage.setItem(
                `block-num-${currentUser.id}-${questionId}-${assessmentId}`,
                blockNum.toString()
            );
        }
    }, [blockNum, questionId]);

    useEffect(() => {
        if (questionActions && questionId) {
            window.localStorage.setItem(
                `question-actions-${currentUser.id}-${questionId}-${assessmentId}`,
                JSON.stringify(questionActions)
            );
        }
    }, [questionActions, questionId]);

    useEffect(() => {
        if (blockValues && questionId) {
            window.localStorage.setItem(
                `block-values-${currentUser.id}-${questionId}-${assessmentId}`,
                JSON.stringify(blockValues)
            );
        }
        if (blockValues && question) {
            let filteredValues = filterBlockValues(blockValues);

            let visibleBlocks = question.ui.blocks.filter((block) => {
                if (block.condition) {
                    if (blockValues[block.condition.actionId]) {
                        if (
                            Array.isArray(
                                blockValues[block.condition.actionId]
                            ) &&
                            blockValues[block.condition.actionId].includes(
                                block.condition.value
                            )
                        ) {
                            return true;
                        } else if (
                            blockValues[block.condition.actionId] ===
                            block.condition.value
                        ) {
                            return true;
                        }
                    }
                } else {
                    return true;
                }
                return false;
            });
            setVisibleBlocks(visibleBlocks);

            //let completedBlocks =  question.ui.blocks.map(block=>{
            let completedBlocks = visibleBlocks.map((block) => {
                let actionId = block.actionId;
                let x = filteredValues[actionId];

                if (Array.isArray(x) || typeof x === 'object') {
                    if (block.type === 'yes-no-list') {
                        if (
                            typeof x === 'object' &&
                            Object.keys(x).length === block.questions.length
                        ) {
                            return true;
                        }
                    } else if (x.length > 0) {
                        return true;
                    }
                } else if (typeof x === 'string') {
                    return true;
                }
                return false;
            });
            setCompletedBlocks(completedBlocks || []);
        } else {
            setCompletedBlocks([]);
            setVisibleBlocks([]);
        }
    }, [blockValues, questionId]);

    useEffect(() => {
        loadData();
    }, [assessmentId, language]);

    /*useEffect(() => {
      //console.log('---- visibleBlocks are now: ', visibleBlocks);
  }, [visibleBlocks])

  useEffect(() => {
      //console.log('---- completedBlocks are now: ', completedBlocks);
  }, [completedBlocks])*/

    useEffect(() => {
        //If this question has a fullscreen presentation, then there are no blocks,
        // so it automatically is set as complete ('next' goes to next lesson not next block)
        if (question && question.ui.presentation.fullScreen) {
            enableAssessmentFinish();
        } else if (question && completedBlocks) {
            setQuestionComplete(
                visibleBlocks.length === completedBlocks.filter((x) => x).length
            );
        } else setQuestionComplete(false);
    }, [questionId, completedBlocks]);

    useEffect(() => {
        if (question && blockNum > -1 && blockValues) {
            //let  currentBlock = question.ui.blocks[blockNum];
            let currentBlock = visibleBlocks && visibleBlocks[blockNum];

            if (currentBlock) {
                let actionId = currentBlock.actionId;
                switchContinueButton(blockValues[actionId]);
            } else {
                console.log('currentBlock is undefined');
            }
        } else {
            setQuestionComplete(false);
        }
    }, [question, blockNum, blockValues, companyId]);

    useEffect(() => {
        (async () => {
            if (companyId && questionId) {
                await questionStore.loadQuestion(
                    companyId,
                    questionId,
                    assessmentId
                );

                let _questionActions = [];
                let _blockValues = {};
                let _blockNum;
                try {
                    let v = window.localStorage.getItem(
                        `question-actions-${currentUser.id}-${questionId}-${assessmentId}`
                    );
                    if (v) _questionActions = JSON.parse(v);
                } catch (e) {
                    _questionActions = [];
                }
                try {
                    let v = window.localStorage.getItem(
                        `block-values-${currentUser.id}-${questionId}-${assessmentId}`
                    );
                    if (v) _blockValues = JSON.parse(v);
                } catch (e) {
                    _blockValues = {};
                }
                _blockNum =
                    parseInt(
                        window.localStorage.getItem(
                            `block-num-${currentUser.id}-${questionId}-${assessmentId}`
                        )
                    ) || 0;
                setBlockNum(_blockNum);
                //console.log("setBlockValues", _blockNum, _blockValues)
                setBlockValues(filterBlockValues(_blockValues));
                setQuestionActions(_questionActions);
            }
        })();
    }, [questionId, companyId]);

    const closeSnackbar = () => {
        commonStore.hideMessage();
    };

    const getActualBlockCount = () => {
        return Math.max(
            visibleBlocks ? visibleBlocks.length : 0,
            question.ui.minimumBlockCount || 0
        );
    };

    const switchContinueButton = (blockValue) => {
        if (Array.isArray(blockValue) || typeof blockValue === 'object') {
            if (
                visibleBlocks[blockNum] &&
                visibleBlocks[blockNum].type === 'yes-no-list'
            ) {
                if (
                    typeof blockValue === 'object' &&
                    Object.keys(blockValue).length ===
                        visibleBlocks[blockNum].questions.length
                ) {
                    onQuestionDone(true);
                }
            } else if (
                visibleBlocks[blockNum] &&
                visibleBlocks[blockNum].type === 'decision'
            ) {
                //onQuestionDone(finishedDecisionTree)
            } else if (
                visibleBlocks[blockNum] &&
                visibleBlocks[blockNum].type === 'groupOfBlocks'
            ) {
                onQuestionDone(blockValue && allBlocksHaveValue(blockValue));
            } else {
                onQuestionDone(blockValue && blockValue.length > 0);
            }
        } else {
            onQuestionDone(!!blockValue);
        }
    };

    /** Returns true if the given groupValue has a value for each block within it, that is not the empty string
     * Example of such groupValue:
     * [
     *   {
     *     "check_for_updates": "check_manually"
     *   },
     *   {
     *      "install_updates": ""
     *   },
     *   {
     *     "updates_from_websites": "automatically"
     *   }
     * ]
     *
     * */
    const allBlocksHaveValue = (groupValue) => {
        return (
            visibleBlocks[blockNum] &&
            visibleBlocks[blockNum].group &&
            groupValue.length === visibleBlocks[blockNum].group.length &&
            !groupValue.find(
                (valueObject) => Object.values(valueObject)[0] === ''
            )
        );
    };

    const handleBlockChange = (
        value,
        actionId,
        block,
        omitTransition = false
    ) => {
        let blockActionId = actionId || visibleBlocks[blockNum].actionId;
        let updatedBlockValues = { ...blockValues };
        let newVal = { ...updatedBlockValues, ...{ [blockActionId]: value } };
        let actualBlockCount = getActualBlockCount();

        setBlockValues(filterBlockValues(newVal));
        switchContinueButton(value);

        if (
            !omitTransition &&
            actualBlockCount > 1 &&
            blockNum < actualBlockCount - 1
        ) {
            setBlockFadeIn(false);
            setBlockFadeOut(true);
            if (transitionTimer) {
                try {
                    clearTimeout(transitionTimer);
                } catch (e) {}
            }
            setTransitionTimer(
                setTimeout(() => {
                    setTransitionTimer(null);
                    setBlockTransition(false);
                    setBlockTransition(true);
                }, 500)
            );
        }
    };

    const loadData = async () => {
        await assessmentStore.loadAssessment(assessmentId);
        const assessment = assessmentStore.assessment;

        if (!assessment) {
            return history.push('/assessments');
        }
        const isTimedOut =
            assessment && assessment.status && assessment.status.timeLeft <= 0;

        const isFinished =
            assessment &&
            (assessment.status.finished ||
                assessment.status.questionsDone.length ===
                    assessment.definition.questions.length);

        if (assessment && isTimedOut && !isFinished) {
            setimeOutModal(true);
        }

        if (assessment && assessment.status.started) setIsStarted(true);

        setIsNextAvailable(false);
        let q =
            assessment.definition.questions[
                assessment.status.questionsDone.length
            ];
        setQuestionId(q);
    };

    const closeTimeOutModal = async () => {
        assessment.status.timeLeft = 0;
        setimeOutModal(false);
    };

    const onTimeOver = async () => {
        setimeOutModal(true);
    };

    /** Get only the blockValues of visible blocks (others don't matter) **/
    const getActualBlockValues = () => {
        const visibleBlockActionIds = visibleBlocks.map(
            (visibleBlock) => visibleBlock.actionId
        );
        const actualBlockValues = pick(blockValues, visibleBlockActionIds);
        //console.log({actualBlockValues});
        return actualBlockValues;
    };

    const onFinish = async () => {
        // complete last question first:
        let ok = await assessmentStore.completeQuestion(
            assessmentId,
            questionId,
            [
                ...questionActions,
                {
                    action: 'complete_question',
                    value: getActualBlockValues(),
                    details: {
                        isMobile: isMobile,
                        UA: getUA,
                    },
                },
            ],
            trainingStore.training && trainingStore.training.assessment
        );
        if (ok) {
            if (!assessment.status.questionsDone.includes(questionId))
                assessment.status.questionsDone.push(questionId);
            delete localStorage[
                `question-actions-${currentUser.id}-${questionId}-${assessmentId}`
            ];
            delete localStorage[
                `block-values-${currentUser.id}-${questionId}-${assessmentId}`
            ];
            delete localStorage[
                `block-num-${currentUser.id}-${questionId}-${assessmentId}`
            ];
        }

        setQuestionId(null);
    };

    const onQuestionAction = async (questionId, action, value, immediate) => {
        // if ( immediate ) {
        //     await assessmentStore.questionAction(assessmentId, questionId, action, value)
        // }
        // else{

        //Keeping questionActions up-to-date, but maybe we don't need this...just POST every action
        let existingQuestionValue = find(questionActions, {
            questionId,
            action,
        });
        if (existingQuestionValue) {
            existingQuestionValue.value = value;
            setQuestionActions([...questionActions]);
        } else {
            existingQuestionValue = { questionId, action, value };
            setQuestionActions([...questionActions, existingQuestionValue]);
        }

        //do not post  every time.  at least use immediate flag  for exact actions which should be posted right when happens
        // await assessmentStore.questionAction(assessmentId, questionId, action, value);
    };

    const onNextLesson = async () => {
        const questionId =
            assessment.definition.questions[
                assessment.status.questionsDone.length
            ];

        let ok = await assessmentStore.completeQuestion(
            assessmentId,
            questionId,
            [
                ...questionActions,
                {
                    action: 'complete_question',
                    value: blockValues,
                    details: {
                        isMobile: isMobile,
                        UA: getUA,
                    },
                },
            ],
            trainingStore.training && trainingStore.training.assessment
        );
        if (ok) {
            if (!assessment.status.questionsDone) {
                assessment.status.questionsDone = [];
            }
            if (!assessment.status.questionsDone.includes(questionId))
                assessment.status.questionsDone.push(questionId);

            delete localStorage[
                `question-actions-${currentUser.id}-${questionId}-${assessmentId}`
            ];
            delete localStorage[
                `block-values-${currentUser.id}-${questionId}-${assessmentId}`
            ];
            delete localStorage[
                `block-num-${currentUser.id}-${questionId}-${assessmentId}`
            ];

            setQuestionId(
                assessment.definition.questions[
                    assessment.status.questionsDone.length
                ]
            );
            setBlockNum(0);
            setQuestionActions([]);
            setBlockValues({});
        }
    };
    const onNextBlock = async () => {
        let newBlockNum = Math.min(blockNum + 1, visibleBlocks.length - 1);
        setBlockNum(newBlockNum);
        let block = visibleBlocks[newBlockNum];
        let blockActionId = block.actionId;
        handleBlockChange(
            blockValues[blockActionId],
            blockActionId,
            block,
            true
        );
    };
    const onPrevBlock = async () => {
        let newBlockNum = Math.max(blockNum - 1, 0);
        setBlockNum(newBlockNum);
        //let  block = question.ui.blocks[newBlockNum]
        let block = visibleBlocks[newBlockNum];
        let blockActionId = block.actionId;
        handleBlockChange(
            blockValues[blockActionId],
            blockActionId,
            block,
            true
        );
    };

    /* Call this on each answer. Will call next block, next lesson or finish assessment based on the assessment state*/
    const onNext = async (e, isTransition = false) => {
        if (!isLastStep()) {
            if (!question) return;
            if (questionComplete && !isTransition) return onNextLesson();

            return onNextBlock();
        } else {
            return await onFinish();
        }
    };

    const isLastStep = () => {
        return (
            assessment &&
            assessment.status &&
            assessment.status.questionsDone.length ===
                assessment.definition.questions.length - 1 &&
            questionComplete
        );
    };

    /* Called to set the current question to 'Finish' state, meaning it can be completed (the 'Finish/Next' button enabled) */
    const enableAssessmentFinish = () => {
        setQuestionComplete(true);
        onQuestionDone(true);
    };

    const onQuestionDone = (nextAvailable) => {
        setIsNextAvailable(nextAvailable);
    };

    const onStart = async () => {
        await assessmentStore.startAssessment(assessmentId, assessment);
        if (
            (assessmentStore.assessment.started ||
                assessmentStore.assessment.status.started) &&
            assessment.status.timeLeft > 0
        )
            setIsStarted(true);
    };

    const renderTimeLeft = (props) => {
        let secondsLeft =
            props.hours * 60 * 60 + props.minutes * 60 + props.seconds;
        if (props.hours > 0) {
            return (
                <span>{`${props.hours} ${t(pluralize('hour', props.hours))} ${t(
                    'left'
                )}`}</span>
            );
        } else if (secondsLeft < 300) {
            return (
                <span className={classes({ error: secondsLeft < 120 })}>
                    {props.hours.toString().padStart(2, '0') +
                        ':' +
                        props.minutes.toString().padStart(2, '0') +
                        ':' +
                        props.seconds.toString().padStart(2, '0')}
                </span>
            );
        } else {
            return (
                <span>{`${(props.minutes + 1).toString()} ${t(
                    pluralize('minute', props.minutes)
                )} ${t('left')}`}</span>
            );
        }
    };

    if (loadingData || loadingAssessmentId || !assessment) {
        return (
            <div className="wizard">
                <br />
                <br />
                <h1 className="spinner d-flex justify-content-center">
                    {t('One moment')}
                </h1>
                <Spinner />
            </div>
        );
    }

    const done =
        assessment &&
        assessment.status &&
        (assessment.status.questionsDone.length /
            assessment.definition.questions.length) *
            100;
    const isFinished =
        assessment &&
        assessment.status &&
        (assessment.status.finished ||
            assessment.status.questionsDone.length ===
                assessment.definition.questions.length);
    const isExpired =
        assessment &&
        assessment.status &&
        assessment.status.expired &&
        !assessment.status.started;
    const isTimedOut =
        assessment && assessment.status && assessment.status.timeLeft <= 0;

    if (isExpired) {
        return (
            <div className={classes('wizard')}>
                <div className={'wizard-header'}>
                    <Link className={'wizard-close'} to={parentUrl}>
                        <img src="/static/img/close.svg" alt={t('Close')} />
                    </Link>
                    <h1>{assessment.name}</h1>
                </div>
                <h2>
                    <Block />
                </h2>
                <h3>{t('This assessment has expired.')}</h3>
            </div>
        );
    }

    return (
        <div className={classes('wizard')}>
            <div className={'wizard-header'}>
                <Link className={'wizard-close'} to={parentUrl}>
                    <img src="/static/img/close.svg" alt={t('Close')} />
                </Link>
                <div className="wizard-mobile-header">
                    <h1
                        className={`${
                            isStarted && !isFinished ? 'started' : 'desktopOnly'
                        }`}
                    >
                        {assessment.name}
                    </h1>
                    {!isFinished && !isExpired && isStarted && (
                        <div className="wizard-header-status">
                            <strong>
                                {assessment.status.questionsDone.length + 1}/
                                {assessment.definition.questions.length}{' '}
                                {pluralize(
                                    t('question'),
                                    assessment.definition.questions.length
                                )}
                            </strong>
                            <span>
                                <>&nbsp;·&nbsp; </>
                            </span>
                            {isTimedOut ? (
                                <span className="error">00:00</span>
                            ) : (
                                <Countdown
                                    date={assessment.end}
                                    onComplete={onTimeOver}
                                    renderer={renderTimeLeft}
                                />
                            )}
                        </div>
                    )}
                </div>
                <div className="desktopOnly">
                    <h1
                        className={`${
                            isStarted && !isFinished ? 'started' : ''
                        }`}
                    >
                        {assessment.name}
                    </h1>
                    {!isFinished && !isExpired && isStarted && (
                        <div className="wizard-header-status">
                            <strong>
                                {assessment.status.questionsDone.length + 1}/
                                {assessment.definition.questions.length}{' '}
                                {pluralize(
                                    t('question'),
                                    assessment.definition.questions.length
                                )}
                            </strong>
                            <br />
                            {isTimedOut ? (
                                <span className="error">00:00</span>
                            ) : (
                                <Countdown
                                    date={assessment.end}
                                    onComplete={onTimeOver}
                                    renderer={renderTimeLeft}
                                />
                            )}
                        </div>
                    )}
                </div>
            </div>
            <div className="wizard-header-progress">
                <ThemedProgress narrow value={done} />
            </div>
            <Fragment>
                <Modal
                    className="modal slim"
                    isOpen={timeOutModal}
                    onRequestClose={closeTimeOutModal}
                >
                    <div className="d-flex flex-column">
                        <h3>{t('Time is up!')}</h3>
                        <p className="modalText">
                            {t(
                                'Unfortunately, time is up. Your answers have been submitted.'
                            )}
                        </p>
                        <div className="certificate-button mt20 align-self-end">
                            <ThemedButton primary onClick={closeTimeOutModal}>
                                {t('Ok')}
                            </ThemedButton>
                        </div>
                    </div>
                </Modal>

                {!isStarted && !isExpired ? (
                    <div className="wizard-body scroll">
                        <div className="quiz-slide">
                            <QuizIntro
                                onStart={onStart}
                                assessment={assessment}
                            />
                        </div>
                    </div>
                ) : isFinished && !isExpired ? (
                    <div className="wizard-body scroll">
                        <QuizReview
                            parentUrl={parentUrl}
                            assessment={assessment}
                            assessmentId={assessment.id}
                        />
                    </div>
                ) : (
                    <div className="wizard-body">
                        <div className={'wizard-content wide'}>
                            <ErrorMessage error={error} />
                            <Question
                                handleBlockChange={handleBlockChange}
                                loadingData={loadingQuestionData}
                                question={question}
                                blockNum={blockNum}
                                onPrevBlock={onPrevBlock}
                                onNextBlock={onNextBlock}
                                blockValues={blockValues}
                                questionId={questionId}
                                assessmentId={assessmentId}
                                completedBlocks={completedBlocks}
                                visibleBlocks={visibleBlocks}
                                userId={currentUser.id}
                                userName={currentUser.name}
                                userEmail={currentUser.email}
                                language={store.language}
                                onQuestionAction={onQuestionAction}
                                enableAssessmentFinish={enableAssessmentFinish}
                                onNextQuestion={onNext}
                                blockFadeIn={blockFadeIn}
                                blockFadeOut={blockFadeOut}
                            />
                        </div>
                    </div>
                )}
                <div className={'wizard-footer'}>
                    <div className={'actions'}>
                        {!isStarted && (
                            <ThemedFooterButton
                                className="mobileOnly"
                                primary
                                continue
                                onClick={onStart}
                            >
                                {t('Start now')}
                            </ThemedFooterButton>
                        )}
                        {isStarted && !isFinished && (
                            <ThemedFooterButton
                                primary
                                continue
                                disabled={isTimedOut || !isNextAvailable}
                                onClick={onNext}
                            >
                                {t(isLastStep() ? 'Finish' : 'Continue')}
                            </ThemedFooterButton>
                        )}
                        {isStarted && isFinished && (
                            <ThemedFooterButton
                                primary
                                continue
                                to={`${parentUrl}`}
                            >
                                {t('Finish')}
                            </ThemedFooterButton>
                        )}
                    </div>
                </div>
                <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    autoHideDuration={6000}
                    variant={commonStore.messageType}
                    message={commonStore.message || ''}
                    open={commonStore.messageShown}
                    onClose={closeSnackbar}
                ></Snackbar>
            </Fragment>
        </div>
    );
});
