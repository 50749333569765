import React from 'react';
import { withRouter } from 'react-router';
import { ThemedButton } from './ThemedComponents';
import styled, { css } from 'styled-components';

const ThemedFooterLinkButton = styled(ThemedButton)`
    margin: 0 20px;
    ${(props) =>
        props.continue &&
        css`
            margin-left: auto;
        `};
`;

const ThemedFooterButton = (props) => {
    const {
        history,
        location,
        match,
        staticContext,
        to,
        onClick,
        // ⬆ filtering out props that `button` doesn't know what to do with.
        ...rest
    } = props;
    return (
        <ThemedFooterLinkButton
            {...rest} // `children` is just another prop!
            onClick={(event) => {
                onClick && onClick(event);
                history.push(to);
            }}
        />
    );
};

export default withRouter(ThemedFooterButton);
