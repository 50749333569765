/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'reactstrap';
import moment from 'moment';

export default observer(({ match, assessment }) => {
    const { t } = useTranslation();

    return (
        <div className="flex-fill">
            <div className="listItem assessment ">
                <Link
                    className="subject-list-link"
                    to={`${match.url}/subjects/quiz`}
                >
                    <Row>
                        <Col md="4" xs="12">
                            <div className="icon">
                                <img
                                    src="/static/img/assessment-icon.svg"
                                    alt={t('Quiz')}
                                />
                            </div>
                        </Col>
                        <Col md="8" xs="12">
                            <div className="d-flex flex-column flex-fill justify-content-center">
                                <h3>{assessment.name}</h3>
                                <div className="subject-list-description">
                                    <p>{assessment.description}</p>
                                </div>
                                {assessment.duration != null && (
                                    <span>
                                        {t('Available on')}{' '}
                                        {moment(assessment.start).format('LL')}
                                    </span>
                                )}
                                <div className="subject-list-description">
                                    <p>30 min • 10 questions</p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Link>
                <hr />
            </div>
        </div>
    );
});
