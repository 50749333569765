import React, { Fragment } from 'react';
import { Input, Label, FormGroup } from 'reactstrap';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import {
    ThemedText,
    ThemedCertificateButton,
} from '../components/themed/ThemedComponents';

@withTranslation()
@inject('store', 'authStore', 'companyStore')
@observer
class SendCertificateForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showSent: false,
            checked: true,
            name: this.props.authStore.currentUser.name,
            email: this.props.authStore.currentUser.email,
            error: '',
        };
    }

    onSendClick = async () => {
        // When name change is disabled, name isn't required and therefore don't show error when it is missing.
        if (
            this.props.companyStore.currentCompany.settings[
                'ui.certificate_name_change_disabled'
            ]
        ) {
            let name = this.state.name || this.state.email || '';
            await this.props.onSend(name, this.state.checked);
            this.setState({ showSent: true });
        } else {
            if (this.state.name) {
                this.setState({ error: '' });
                await this.props.onSend(this.state.name, this.state.checked);
                console.log(this.props.store.certificateError);
                if (this.props.store.certificateError) {
                    this.setState({ error: this.props.store.certificateError });
                } else {
                    this.setState({ showSent: true });
                }
            } else {
                this.setState({ error: this.props.t('Name is required') });
            }
        }
    };

    onCheckUpdate = (e) => {
        this.setState({ checked: !this.state.checked });
    };

    onChangeName = (e) => {
        this.setState({ name: e.target.value });
    };

    render() {
        const { t } = this.props;
        const { currentUser } = this.props.authStore;
        const { creatingCertificate } = this.props.store;
        const { currentCompany } = this.props.companyStore;
        const slackOnly = currentCompany.settings['ui.slackOnly'] === '1';

        return (
            <Fragment>
                <Helmet>
                    <title>{t('Get your Certificate!')}</title>
                </Helmet>
                <div className="title"> {t('Get your Certificate!')} </div>

                {!this.state.showSent &&
                    !currentCompany.settings[
                        'ui.certificate_name_change_disabled'
                    ] && (
                        <FormGroup className="fields">
                            <Label className="subtitle">
                                {' '}
                                {t('Name on certificate')}{' '}
                                <Input
                                    onChange={this.onChangeName}
                                    type={'text'}
                                    placeholder={t('Enter name')}
                                    value={this.state.name}
                                />
                            </Label>
                            <span className={'error'}>
                                {this.state.error || creatingCertificate}
                            </span>
                            <Label className="checkfield">
                                <Input
                                    type={'checkbox'}
                                    checked={this.state.checked}
                                    onChange={this.onCheckUpdate}
                                />{' '}
                                {t('Update name in Profile')}
                            </Label>
                        </FormGroup>
                    )}
                <hr />
                <div className="certificate-button">
                    {!this.state.showSent && (
                        <ThemedCertificateButton
                            primary
                            onClick={this.onSendClick}
                        >
                            {slackOnly
                                ? t('Send my Certificate!')
                                : t('Email my Certificate!')}
                        </ThemedCertificateButton>
                    )}
                </div>
                <button onClick={this.props.onCancel} className="modalClose">
                    <img src="/static/img/close.svg" alt={t('Close')} />
                </button>
                {!slackOnly ? (
                    this.state.showSent ? (
                        <Fragment>
                            <div className="description">
                                {t('Certificate sent to')} {currentUser.email}
                            </div>
                        </Fragment>
                    ) : (
                        <Fragment>
                            <div className="description">
                                {t('Certificate will be sent to')}
                                <ThemedText>{currentUser.email}</ThemedText>
                            </div>
                        </Fragment>
                    )
                ) : this.state.showSent ? (
                    <Fragment>
                        <div className="description">
                            {t('Certificate sent to user')} {currentUser.email}
                        </div>
                    </Fragment>
                ) : (
                    <Fragment>
                        <div className="description">
                            {t('Certificate will be sent to user')}
                            <ThemedText>{currentUser.email}</ThemedText>
                        </div>
                    </Fragment>
                )}
            </Fragment>
        );
    }
}

export default SendCertificateForm;
