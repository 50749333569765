import React, { Fragment } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Container, Button } from 'reactstrap';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';

@withTranslation()
@inject('authStore')
@observer
class SignIn extends React.Component {
    handleSignout = (event) => {
        this.props.authStore.logout();
        this.props.history.push('/');
    };

    render() {
        const { isAuthenticated, currentUser } = this.props.authStore;
        const { t } = this.props;

        return (
            <Fragment>
                {isAuthenticated && (
                    <Container className="d-flex">
                        <br />
                        <br />
                        <br />
                        <div className="d-flex flex-column flex-fill  justify-content-center align-items-center">
                            <br />
                            <br />
                            <br />
                            <div className="p-2">
                                <p>
                                    {t('You are signed in as')}{' '}
                                    <strong>{currentUser.email}</strong>
                                </p>
                            </div>
                            <div className="p-2">
                                <p>
                                    <Link to="/">
                                        <Button color="primary">
                                            {t('Go to frontpage')}
                                        </Button>
                                    </Link>
                                </p>
                            </div>
                            <div className="p-2">
                                <Button
                                    type="submit"
                                    className="btn-text"
                                    onClick={this.props.handleSignout}
                                >
                                    {t('Login as another user')}
                                </Button>
                            </div>
                        </div>
                        <br />
                        <br />
                    </Container>
                )}
                {!isAuthenticated && (
                    <Container>
                        <br />
                        <h2>{t('Unable to sign in')}</h2>
                        <p>
                            {t(
                                'The link you tried to use to sign in was not valid.'
                            )}
                        </p>
                        <p>
                            <Link to="/auth/signin">
                                <Button>
                                    {t('Request a new sign in link.')}
                                </Button>
                            </Link>
                        </p>
                    </Container>
                )}
            </Fragment>
        );
    }
}

export default withRouter(SignIn);
