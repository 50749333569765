import styled, { css } from 'styled-components';
import { lighten, darken, rgba } from 'polished';
import { Slider } from '@material-ui/core';

const colors = {
    heavyMetal: '#343534',
    correct: '#20A26B',
    incorrect: '#AD2931',
};

export const ThemedText = styled.span`
    color: ${(props) => props.color || props.theme.primary};
`;

export const ThemedBigText = styled(ThemedText)`
    font-size: 1.4em;
    font-weight: 700;
`;

export const ThemedButton = styled.button`
    border: none;
    border-radius: 0.25rem;
    padding: 0.7rem 2rem;
    padding-top: 0.8rem;
    padding-bottom: 0.6rem;
    font-size: 13px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.04em;
    cursor: pointer;
    &:focus {
        box-shadow: 0 0 0 3px
            ${(props) => rgba(props.color || props.theme.primary, 0.25)};
        outline: none;
    }
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    ${(props) =>
        props.primary &&
        css`
            color: white;
            background-color: ${(props) => props.color || props.theme.primary};
            transition: ease 0.2s all;
            &:hover {
                background-color: ${(props) =>
                    darken(0.1, props.color || props.theme.primary)} !important;
                border-color: ${(props) =>
                    darken(
                        0.05,
                        props.color || props.theme.primary
                    )} !important;
            }

            &:active {
                background-color: ${(props) =>
                    darken(0.2, props.color || props.theme.primary)} !important;
                border-color: ${(props) =>
                    darken(
                        0.15,
                        props.color || props.theme.primary
                    )} !important;
            }

            &:focus {
                box-shadow: 0 0 0 0.2rem
                    ${(props) =>
                        lighten(
                            0.05,
                            props.color || props.theme.primary
                        )} !important;
            }
        `};

    ${(props) =>
        props.secondary &&
        css`
            color: ${(props) => props.color || props.theme.primary};
            background-color: ${(props) =>
                rgba(props.color || props.theme.primary, 0.1)};
            transition: ease 0.2s all;
            &:hover {
                color: white;
                background-color: ${(props) =>
                    darken(0.1, props.color || props.theme.primary)};
            }
        `};
    ${(props) =>
        props.disabled &&
        css`
            color: white;
            opacity: 0.25;
            background-color: ${(props) => props.color || props.theme.primary};
            pointer-events: none;
        `};
`;

export const ThemedCertificateButton = styled(ThemedButton)`
    flex: 1;
    margin-top: 10px;
    background: ${(props) => props.color || props.theme.primary};
    line-height: 24px;
    font-size: 18px;
    font-weight: 700;
    color: white;
    padding-left: 0px;
    padding-right: 23px;
    min-width: 400px;
    margin: 10px auto 0 auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    position: relative;
    overflow: visible;
    transition: ease 0.2s background;
    border: none;
    &:hover {
        background: ${(props) =>
            lighten(0.1, props.color || props.theme.primary)}
        &:after {
            background-size: 34px auto;
        }
    }
    @media (max-width: 992px) {
        max-width: 96%;
        min-width: unset;
        padding-left: 20px;
        padding-right: 60px;

    }
    &:after {
        content: "";
        transition: ease 0.2s background;

        position: absolute;
        background: url('/static/img/icon-send.svg') no-repeat center center;
        background-size: 32px auto;
        right: 14px;
        top: 4px;
        height: 44px;
        width: 44px;
    }
`;

export const ThemedSlider = styled(Slider)`
    color: ${(props) => props.color || props.theme.primary};
`;

export const ThemedStep = styled.div`
    position: relative;
    white-space: nowrap;
    line-height: 36px;
    padding-left: 30px;
    padding-right: 10px;
    font-size: 12px;
    text-transform: uppercase;
    color: ${lighten(0.6, colors.heavyMetal)};
    font-weight: 700;
    border-left-color: white;
    border-left-style: solid;
    border-left-width: 4px;
    border-right-color: white;
    justify-content: space-between;
    flex-direction: row;
    display: flex;
    letter-spacing: 0.04em;
    padding-right: 20px;
    a {
        width: 100%;
    }
    &:after {
        position: absolute;
        content: '';
        width: 20px;
        height: 36px;
        right: 0px;
        top: 0px;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 14px auto;
        transition: ease 0.2s all;
    }

    ${(props) =>
        props.enabled &&
        css`
            opacity: 1;
            cursor: pointer;
            color: ${colors.heavyMetal};
            &:hover {
                color: ${(props) =>
                    lighten(0.1, props.color || props.theme.primary)};
                border-left-color: ${lighten(0.4, colors.heavyMetal)};
                border-right-color: ${lighten(0.4, colors.heavyMetal)};
            }
            &:after {
                position: absolute;
                content: '';
                width: 20px;
                height: 36px;
                right: 0px;
                top: 0px;
                background-size: 14px auto;
            }
        `};
    ${(props) =>
        props.active &&
        css`
            border-left-color: ${(props) => props.color || props.theme.primary};
            border-right-color: ${(props) =>
                props.color || props.theme.primary};
            border-left-style: solid
                ${(props) => props.color || props.theme.primary};
            border-left-width: 4px;
            a {
                color: ${(props) => props.color || props.theme.primary};
                font-weight: 800;
            }
            span {
                font-weight: 600;
            }
        `};
    ${(props) =>
        props.viewed &&
        css`
            &:after {
                background-image: url('/static/img/icon-subject-inprogress.svg');
            }
            &.answered {
                &:after {
                    background-image: url('/static/img/icon-subject-completed.svg');
                }
            }
        `};
    ${(props) =>
        props.answered &&
        css`
            &:after {
                background-image: url('/static/img/icon-subject-completed.svg');
            }
        `};
    ${(props) =>
        props.incorrect &&
        css`
            &:after {
                background-image: url('/static/img/icon-subject-incorrect.svg');
            }
        `};
    ${(props) =>
        props.certificate &&
        css`
            &:after {
                background-image: url('/static/img/icon-certificate2.svg');
                background-repeat: no-repeat;
                background-size: 14px auto;
                top: 0;
            }
        `};
    ${(props) =>
        props.assessment &&
        css`
            &:after {
                background-image: url('/static/img/icon-quiz.svg');
                background-repeat: no-repeat;
                background-size: 14px auto;
                top: 0;
            }
        `};
`;

export const ThemedAnswer = styled.div`
    padding: 10px 30px;
    margin: 20px 40px;
    text-align: center;
    border: ${(props) => rgba(props.color || props.theme.primary, 0.1)};
    background: ${(props) => rgba(props.color || props.theme.primary, 0.1)};
    border-radius: 3px;
    transition: 0.2s ease all;
    font-size: 0.9em;
    color: ${(props) => props.color || props.theme.primary};
    font-weight: 700;
    @media (max-width: 992px) {
        margin: 20px 10px;
    }
    &:hover {
        background: ${(props) => props.color || props.theme.primary};
        color: white;
        cursor: pointer;
    }
    &:active {
        background: ${(props) => rgba(props.color || props.theme.primary, 0.4)};
        color: white;
        cursor: pointer;
    }

    ${(props) =>
        props.correct &&
        css`
            background: ${colors.correct};
            color: white;
            position: relative;
            pointer-events: none;
            &:before {
                content: '';
                position: absolute;
                right: 16px;
                top: 10px;
                width: 20px;
                height: 20px;
                background-image: url('/static/img/icon-check-white.svg');
                background-repeat: no-repeat;
            }
        `};

    ${(props) =>
        props.incorrect &&
        css`
            background: ${colors.incorrect};
            color: white;
            position: relative;
            pointer-events: none;
            &:before {
                content: '';
                position: absolute;
                right: 12px;
                top: 15px;
                width: 20px;
                height: 20px;
                background-image: url('/static/img/icon-x-white.svg');
                background-repeat: no-repeat;
            }
        `};
`;
