import React, { Fragment, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { useStores } from '../../hooks/use-stores';
import ErrorMessage from '../../components/errormessage';

function HomeViewContainer({ layoutTitle, code, pageTitle, content }) {
    const { t } = useTranslation();
    const { store, companyStore } = useStores();
    const { currentCompany } = companyStore;
    const { error } = store;

    useEffect(() => {
        store.setLayoutTitle(t(layoutTitle));
    }, [store, t, layoutTitle]);

    const currentUserName = store.authStore.currentUser.name;

    const tabs = useMemo(
        () => [
            {
                code: 'trainings',
                title: 'Trainings',
                enabled: currentCompany && currentCompany.isTrainingEnabled,
                active: code === 'trainings',
            },
            {
                code: 'assessments',
                title: 'Assessments',
                enabled: currentCompany && currentCompany.isAssessmentEnabled,
                active: code === 'assessments',
            },
        ],
        [currentCompany, code]
    );

    return (
        <Fragment>
            <Helmet>
                <title>{t(`Your ${pageTitle}`)}</title>
            </Helmet>
            <div className="container max-1280">
                <ErrorMessage error={error} />
                <div className="helmet">
                    <h2>
                        {t('Welcome back')}, {currentUserName}
                    </h2>
                    <p>{t(`Here are your ${code}`)}.</p>
                </div>
                <Row>
                    <Col md="8" xs="12">
                        <div className="block tab-list">
                            <div className="block-tabs">
                                <ul>
                                    {tabs.map(
                                        (t) =>
                                            t.enabled && (
                                                <Link
                                                    to={`/${t.code}`}
                                                    key={t.code}
                                                >
                                                    <li
                                                        className={
                                                            t.active
                                                                ? 'active'
                                                                : undefined
                                                        }
                                                    >
                                                        {t.title}
                                                    </li>
                                                </Link>
                                            )
                                    )}
                                </ul>
                            </div>
                            {content}
                        </div>
                    </Col>
                </Row>
            </div>
        </Fragment>
    );
}

export default observer(HomeViewContainer);
