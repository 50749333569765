import React from 'react';
import { observer } from 'mobx-react';
import { ThemedButton } from '../themed/ThemedComponents';
import '../_scss/decisionOverlay.scss';
import { useTranslation } from 'react-i18next';

export default observer(({ decisionQuestion, onAnswer, isAtEnd }) => {
    const { t } = useTranslation('assessment');

    const madeDecision = (decision) => {
        onAnswer(decisionQuestion.id, decision);
    };

    React.useEffect(() => {
        console.log('In overlay with decisionQuestion: ', decisionQuestion);
    }, [decisionQuestion]);

    return (
        <div className={'decision-player'}>
            <div className="question">
                <h1>
                    {isAtEnd ? t('The end') : t(decisionQuestion.questionText)}
                </h1>
            </div>
            {!isAtEnd && (
                <div className="buttons">
                    <ThemedButton
                        primary
                        onClick={() =>
                            madeDecision(decisionQuestion.decision_1)
                        }
                    >
                        {t(decisionQuestion.decision_1.text)}
                    </ThemedButton>
                    <ThemedButton
                        primary
                        onClick={() =>
                            madeDecision(decisionQuestion.decision_2)
                        }
                    >
                        {t(decisionQuestion.decision_2.text)}
                    </ThemedButton>
                </div>
            )}
        </div>
    );
});
