import RestService from './RestService';

export default class Events extends RestService {
    constructor() {
        super('/events');
    }

    askTestEvent() {
        this.get(`${this.baseUrl}/testSocketIO`);
    }
}
