import { Col, Row } from 'reactstrap';
import React from 'react';
import { Link } from 'react-router-dom';
import SemiCircleProgress from '../semi-circle-progress';
import { ThemedButton } from '../themed/ThemedComponents';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useStores } from '../../hooks/use-stores';
import classes from 'classnames';

export default observer(({ parentUrl, assessment, trainingId, stars }) => {
    const { t } = useTranslation();
    const { brandingStore } = useStores();

    const percentage = Math.max(
        0,
        Math.round((assessment.score / assessment.max_score) * 100)
    );

    const calculateColor = () => {
        let def = brandingStore.getScoreDefinition(percentage);
        if (def) return def.color;
        //fallback support
        if (percentage <= 33) return brandingStore.redColor;
        if (percentage <= 67) return brandingStore.yellowColor;
        return brandingStore.greenColor;
    };

    const getScoreDescription = () => {
        let def = brandingStore.getScoreDefinition(percentage);
        if (def) return def.description;

        //fallback support
        return 'You have shown a very good understanding of topics presented in this training. With some additional training you will easily earn an Excellent.';
    };
    const getScoreTitle = () => {
        let def = brandingStore.getScoreDefinition(percentage);
        if (def) return def.name;

        //fallback support
        if (percentage <= 33) return 'Bad';
        if (percentage <= 67) return 'Very good';
        return 'Excellent';
    };

    return (
        <div className="quiz-results block-shadow">
            <Row>
                <Col md="4" xs="12">
                    <div
                        className={classes('progress-semicircle', {
                            'with-stars yellow': stars,
                        })}
                    >
                        <SemiCircleProgress
                            percentage={percentage}
                            size="large"
                        />
                    </div>
                </Col>
                <Col md="8" xs="12">
                    <div className="center">
                        <h4 className="scoreHeading">
                            {t('Your score is')}:{' '}
                            <span style={{ color: calculateColor() }}>
                                {t(getScoreTitle())}
                            </span>
                        </h4>
                        <p>{t(getScoreDescription())}</p>
                        <Link
                            to={`${parentUrl}${
                                trainingId
                                    ? '/subjects/quiz/answers'
                                    : `/${assessment.id}/answers`
                            }`}
                        >
                            <ThemedButton secondary>
                                {t('Review answers')}
                            </ThemedButton>
                        </Link>
                    </div>
                </Col>
            </Row>
            <br />
        </div>
    );
});
