import {action, observable, computed, reaction} from 'mobx';
import keyBy from 'lodash/keyBy';
import defaults from 'lodash/defaults';
import services from "../services"
import Events from './events.js';
import i18n from "i18next";
import moment from "moment";
import {querystring, extractErrorMessage} from "../utils/helpers";
import {EVENTS} from "../utils/constants";
import shuffle from "lodash/shuffle";

class MainStore {

    @observable layoutTitle = '';
    @observable company = null;
    @observable currentSubjectTextContent = '';
    @observable showSubjectDoneAnimation = false;
    @observable managerCompanies = null;
    @observable i18n = null;
    @observable hasConfirmed = false;
    @observable error = undefined;
    @observable certificateError =null
    @observable creatingCertificate = false
    @observable campaign_id = parseInt(querystring("c", window.location.search))
    @observable subject_id = parseInt(querystring("s", window.location.search))
    @observable automationEvent_id = parseInt(querystring("a", window.location.search))
    @observable training_id = parseInt(querystring("t", window.location.search))
    @observable utm_source = querystring("utm_source", window.location.search)
    @observable utm_term = querystring("utm_term", window.location.search)
    @observable language  = window.localStorage.getItem('language') || "en";
    @observable loadingTokens = [];
    @observable loadingScorms = [];
    @observable answers = observable([]);
    @observable answersFor = null;
    @observable loadingVideoUrl = false;
    @observable loadingID = false;
    @observable playerPresetCaptionsLanguage  = null;
    @observable playerCaptionsSettings  = null;
    @observable player  = null
    @observable statusUpdate  = 0
    @observable tokens  = []
    @observable scorms  = []
    @observable sessionEvents  = {}

    constructor(commonStrore, authStore, companyStore) {
        this.authStore = authStore
        this.companyStore = companyStore
        this.commonStrore = commonStrore
        reaction(
            () => this.authStore.currentUser,
            user => {
                if (!user) {
                    this.logout()
                }
                else {
                    if (user.language)
                        this.setLanguage(user.language, true)
                }
            }
        )

        reaction(
            () => this.language,
            locale => {
                i18n.changeLanguage(locale);
            }
        );
        reaction(
            () => this.companyStore.currentCompany,
            company => {
                if( !this.authStore.currentUser) return
                if ( this.authStore.currentUser.language === null && company.defaultLanguage)
                {
                    this.setLanguage(company.defaultLanguage, true)
                }
                // Hardcode Czech subtitles appearing by default when user has Czech as language, if another language gets added like this
                // it should be made a more integrated option (flag in database for language?)
                if(this.authStore.currentUser.language === 'cs') {
                    this.playerPresetCaptionsLanguage = 'Czech'
                }
                if ( company.settings['ui.preset_caption_language']) {
                    this.playerPresetCaptionsLanguage = company.settings['ui.preset_caption_language'];
                }
                if ( company.settings['ui.captions_settings']) {
                    this.playerCaptionsSettings = company.settings['ui.captions_settings'];
                }
            }
        );
    }

    @computed get defaultHomeRedirect() {
        if (this.companyStore.currentCompany.isTrainingEnabled) {
            return 'trainings';
        } else if (this.companyStore.currentCompany.isAssessmentEnabled) {
            return 'assessments';
        } else {
            return '';
        }
    }
    @action
    async loadTrainingByEvent(eventId) {
        this.loadingID = true;
        try {
            this.training_id = await services.Trainings.idByEvent(eventId)
        }
        catch (e) {

        }
        finally {
            this.loadingID = false;
        }
    }

    @action logout = () => {
        this.companies = null;
        this.company = null;
        this.managerCompanies = null;
    }

    @action setI18n(i18n) {
        this.i18n = i18n;
    }

    @action
    async setLanguage(language_code, skipSaving) {
        await this.commonStrore.languagesLoader;
        if (!skipSaving ) {
            await services.Users.switchLanguage(language_code);
        }
        window.localStorage.setItem('language', language_code);
        let changed = false
        if ( this.language !== language_code ) {
            changed = true

        }
        this.language = language_code
        if (changed )
            this.tokens.replace([])
        i18n.changeLanguage(language_code)
        moment.locale(language_code)
        document.documentElement.lang = language_code.replace('_','-');
        //this.loadServerData()

    }

    @action setHasConfirmed = (hasConfirmed) => {
        this.hasConfirmed = hasConfirmed;
    }

    @action setError = (error) => {
        this.error = error;
    }

    @action setLayoutTitle = (title) => {
        this.layoutTitle = title;
    }

    @action setCurrentSubjectTextContent = (content) => {
        this.currentSubjectTextContent = content;
    }

    @action setShowSubjectDoneAnimation = (showAnimation) => {
        this.showSubjectDoneAnimation = showAnimation;
    }

    @action setCampaignById = (campaign_id) => {
        this.campaign_id = parseInt(campaign_id);
    }

    @action setCurrentSubjectAnswers = (subject) => {


        if ( this.answersFor !== subject.subject_id) {
            console.log("shuffle")
            this.answersFor = subject.subject_id
            let answers = subject ? [
                {text: subject.wrong_answer1, correct: false},
                {text: subject.wrong_answer2, correct: false},
                {text: subject.wrong_answer3, correct: false},
                {text: subject.correct_answer, correct: true}] : [];
            this.answers.replace(shuffle(answers))
        }
    }



    @action
    async getCurrentSubjectContentText(subject) {
        if (subject && subject.api_id_content) {
            const textContent = await services.Subjects.getTextContent(subject.api_id_content);
            if ( textContent &&  textContent.content && textContent.content.rendered )
                this.setCurrentSubjectTextContent(textContent.content.rendered);
            else
                this.setCurrentSubjectTextContent('');
        } else {
            this.setCurrentSubjectTextContent('');
        }
    }

    @action setCompanyById = (id) => {
        id = parseInt(id);
        if (id && this.companies && this.companies.length > 0) {
            for (let a = 0, lengthCompanies = this.companies.length; a < lengthCompanies; a++) {
                if (this.companies[a]) {
                    if (this.companies[a].company_id === id) {
                        this.company = this.companies[a];
                        return;
                    }
                }
            }
        }
    }

    @computed get contextParams() {
        let params = {}
        if (this.companyStore.currentCompany)
            params.o = this.companyStore.currentCompany.company_id
        if (this.campaign_id)
            params.c = this.campaign_id
        if (this.automationEvent_id)
            params.a = this.automationEvent_id
        if (this.training_id)
            params.t = this.training_id
        if (this.language)
            params.l = this.language

        if (this.utm_source)
            params.utm_source = this.utm_source
        if (this.utm_term)
            params.utm_term = this.utm_term

        return params
    }

    @action resetContext(){
        this.automationEvent_id = null;
        this.training_id = null;
    }

    @action
    onLogEvent = (trainingId, subject, event_id, context, options ) => {
        options = defaults( {}, options, {send: true} )
        let email = null;
        let company_id = -1;
        let subject_id = null;
        let subject_name = '';
        let company_name = '';
        //this.showSubjectDoneNotification(event_id);

        if (this.authStore.currentUser) {
            email = this.authStore.currentUser.email;
        }
        if (this.companyStore.currentCompany) {
            company_id = this.companyStore.currentCompany.company_id;
            company_name = this.companyStore.currentCompany.company_name;
        }
        if (subject &&  ( typeof (subject) === "object" ) ) {
            subject_id = subject.id;
            subject_name = subject.title;
        }
        else
            subject_id = subject

        const alwaysLog = [EVENTS.ANSWARE_INCORRECT].includes( event_id )
        const eventKey = `${company_id}:${trainingId}:${subject_id}:${event_id}:`
        if (email && options.send && ( alwaysLog ||  !this.sessionEvents[eventKey] ) ) {
            Events.log(event_id, trainingId, company_id, subject_id, context );
            Events.callGoogleAnalytics(event_id, subject_name, company_name, context);
            this.sessionEvents[eventKey] =  true
        }
    }


    @action async sendCertificate( companyId,  trainingId, name, updateName ){
        this.certificateError =null
        this.creatingCertificate = true

        try {
            await services.Companies.certificateService(companyId).create({trainingId, name, updateName})
        }
        catch(e){
            let err= extractErrorMessage(e)
            console.log( err )
            this.certificateError = err
        }
        finally {
            this.creatingCertificate = false
        }
    }

    @action async videoToken (videoId)  {
        if ( this.loadingTokens.includes(videoId) ) return false

        this.loadingTokens.push(videoId)
        try {

            let token = await services.Companies.subjectsService(this.companyStore.currentCompany.company_id).getVideoToken(videoId, this.language ); //videoId

            let t = this.tokens.find(x => x.id === videoId)
            if (t)
                this.tokens.remove(t)
            this.tokens.push({id: videoId, token})
        }
        finally {
            this.loadingTokens.remove(videoId)
        }

    };


    @action async loadVideoPlayerUrl()  {
        if ( this.loadingVideoUrl ) return false
        this.player = null
        this.loadingVideoUrl = true
        try {
            this.player = await services.Companies.subjectsService(this.companyStore.currentCompany.company_id).getVideoPlayerUrl();
        }
        catch(e){

        }
        finally {
            this.loadingVideoUrl = false
        }
    };

    @computed get videoPlayerUrl(){

        if (this.player &&  this.player.url && this.player.exp>new Date().getTime() )
            return this.player.url
        return null
    }


    @computed get tokenIndex(){

        return keyBy( this.tokens, "id")
    }



    @action async scormInfo (subjectId)  {
        if ( this.loadingScorms.includes(subjectId) ) return false

        this.loadingScorms.push(subjectId)
        try {

            let url = await services.Companies.subjectsService(this.companyStore.currentCompany.company_id).getScormInfo(subjectId, this.language); //videoId

            let t = this.scorms.find(x => x.id === subjectId)
            if (t)
                this.scorms.remove(t)
            this.scorms.push({id: subjectId, url})
        }
        finally {
            this.loadingScorms.remove(subjectId)
        }

    };



    @computed get scormIndex(){

        return keyBy( this.scorms, "id")
    }



}

export default MainStore