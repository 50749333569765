import ReactGA from 'react-ga';
import services from '../services';

ReactGA.initialize('UA-6397241-1', {
    testMode: process.env.NODE_ENV === 'test',
});
const CONSTANTS = require('../utils/constants');

export default class {
    static async log(event_id, training_id, company_id, subject_id, context) {
        try {
            await services.Events.create({
                event_id,
                training_id,
                company_id,
                subject_id,
                context,
            });
        } catch (e) {
            console.log('created.event failed');
        }
    }

    static callGoogleAnalytics(event_id, subject_name, company_name, context) {
        let label = '';
        let action = '';
        switch (event_id) {
            case CONSTANTS.EVENTS.LOGIN:
                label = 'Login';
                action = 'Login';
                break;
            case CONSTANTS.EVENTS.LOGOUT:
                label = 'Logout';
                action = 'Logout';
                break;
            case CONSTANTS.EVENTS.SUBJECT_OPEN:
                label = 'Open';
                action = 'Subject Open';
                break;
            case CONSTANTS.EVENTS.SUBJECT_CLOSE:
                label = 'Close';
                action = 'Subject Close';
                break;
            case CONSTANTS.EVENTS.ANSWARE_CORRECT:
                label = 'Correct';
                action = 'Question';
                break;
            case CONSTANTS.EVENTS.ANSWARE_INCORRECT:
                label = 'Incorrect';
                action = 'Question';
                break;
            case CONSTANTS.EVENTS.VIDEO_START:
                label = 'Opened';
                action = 'started';
                break;
            case CONSTANTS.EVENTS.VIDEO_25:
                label = '25%';
                action = 'Watched';
                break;
            case CONSTANTS.EVENTS.VIDEO_50:
                label = '50%';
                action = 'Watched';
                break;
            case CONSTANTS.EVENTS.VIDEO_75:
                label = '75%';
                action = 'Watched';
                break;
            case CONSTANTS.EVENTS.VIDEO_95:
                label = '95%';
                action = 'Watched';
                break;
            case CONSTANTS.EVENTS.VIDEO_100:
                label = '100%';
                action = 'Watched';
                break;
            case CONSTANTS.EVENTS.NOTIFICATION_LINK:
                label = `${context.utm_source}:${context.utm_term}` ;
                action ='open_link' ;
                break;
            default:
                label = 'unknown';
                action = 'unknown';
                break;
        }

        if (event_id !== '' && action !== '' && label !== '') {
            let category = company_name + '-' + subject_name;
            //console.log('event: ' + company_name + ' - ' + subject_name + ' action: ' + action + ' label: ' + label);
            ReactGA.event({
                category,
                action: action,
                label: label,
            });
        }
    }
}
