/* eslint-disable no-undef */
import i18n from 'i18next';
import Backend from './backend';
import moment from 'moment';
import 'moment/locale/fr';
import 'moment/locale/es';
import 'moment/locale/de';
import 'moment/locale/is';
import 'moment/locale/nb';
import 'moment/locale/da';

import 'moment/locale/ar-kw';
import 'moment/locale/zh-cn';
import 'moment/locale/nl';
import 'moment/locale/fi';
import 'moment/locale/el';
//import 'moment/locale/kl';
import 'moment/locale/it';
import 'moment/locale/ja';
import 'moment/locale/ko';
import 'moment/locale/pl';
import 'moment/locale/pt';
import 'moment/locale/sv';

let instance;

function init(commonStore) {
    instance = i18n.use(Backend).init({
        react: {
            useSuspense: false,
        },
        lng: window.localStorage.getItem('language') || 'en',
        fallbackLng: 'en',
        // load: "languageOnly",
        ns: ['common', 'assessment'],
        defaultNS: 'common',
        keySeparator: false,
        backend: {
            loadPath: `/static/locales/{{lng}}/{{ns}}.json`,
            commonStore,
        },
        debug: false,
    });
    let lng = window.localStorage.getItem('language') || 'en'
    setMomentLocale(lng);
    return instance;
}

i18n.on('languageChanged', function (lng) {
    setMomentLocale(lng);
});

function setMomentLocale(lng) {
    //For Norwegian we use 'no' but moment uses 'nb' (Norsk bokmål)
    if (lng === 'no') {
        lng = 'nb'
    }
    moment.locale(lng);
}



export default { init };
