import React from 'react';
import { Alert, Container } from 'reactstrap';
import PropTypes from 'prop-types';
import sanitizeHtml from 'sanitize-html';

class ErrorMessage extends React.Component {
    /* ************************************************************

    More info on available alert types:
      https://reactstrap.github.io/components/alerts/
  ************************************************************  */
    render() {
        //console.log(this.props.error)
        if (!this.props.error) {
            return null;
        } else {
            // Display error
            return (
                <Alert color={this.props.errorType} className="header">
                    <Container
                        dangerouslySetInnerHTML={{
                            __html: sanitizeHtml(this.props.error),
                        }}
                    ></Container>
                </Alert>
                /*
          <div className="warning">
            <strong>{this.props.error}</strong>
          </div>
        */
            );
        }
    }
}

ErrorMessage.propTypes = {
    error: PropTypes.string,
    errorType: PropTypes.string,
};

ErrorMessage.defaultProps = {
    errorType: 'warning',
    error: '',
};

export default ErrorMessage;
