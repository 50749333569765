import React from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { Form, Input } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { ThemedButton } from '../components/themed/ThemedComponents';

@withTranslation()
@inject('authStore')
@observer
class SignIn extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
        };
    }

    handleEmailChange = (event) => {
        this.setState({
            email: event.target.value.trim(),
        });
    };

    handleSubmit = async (event) => {
        event.preventDefault();
        const result = await this.props.authStore.signin(
            this.state.email,
            this.props.company_id,
            this.props.campaign_id,
            this.props.subject_id
        );
        if (result === 'redirect') return;

        if (result) this.props.history.push('/auth/check-email');
    };

    render() {
        const { loginError } = this.props.authStore;
        const { t } = this.props;
        return (
            <div className="sign-in-form">
                <h1>{t('Sign in')}</h1>
                <p>{t('Use your work email')}</p>
                <Form
                    id="signin"
                    method="post"
                    action="/auth/email/signin"
                    onSubmit={this.handleSubmit}
                >
                    <div className="form-group">
                        <Input
                            name="email"
                            type="email"
                            className="form-control"
                            placeholder={t('Enter email')}
                            id="email"
                            aria-describedby="emailHelp"
                            value={this.state.email}
                            onChange={this.handleEmailChange}
                        />
                        <small id="emailHelp" className="form-text text-muted">
                            {t(
                                'You will receive an email to confirm your identity.'
                            )}
                        </small>
                        <small className="error">{loginError}</small>
                    </div>
                    <ThemedButton id="submitButton" type="submit" secondary>
                        {t('Continue')}
                    </ThemedButton>
                </Form>
            </div>
        );
    }
}

export default withRouter(SignIn);
