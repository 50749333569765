import React from 'react';
import { useTranslation } from 'react-i18next';

function EmptyItem({ item }) {
    const { t } = useTranslation();
    return (
        <div className="block desktop training-list">
            <h1 className="green">{t(`You have no assigned ${item}`)}</h1>
            <p>{t(`No ${item} assigned to you at this moment`)}</p>
            <div className="training-progress assessment-demo block hovered mobile demo">
                <div className="header"></div>
                <div className="footer"></div>
            </div>
        </div>
    );
}

export default EmptyItem;
