/* eslint-disable no-undef */
import React from 'react';
import { observer } from 'mobx-react';
import ReactJWPlayer from 'react-jw-player';
import DecisionOverlay from './decisionOverlay';
import classes from 'classnames';
import Spinner from '../Spinner';

const CONSTANTS = require('../../utils/constants');

export default observer(
    ({
        subject,
        logEvent,
        t,
        videoPlayerUrl,
        token,
        videoTokenRequested,
        presetCaptionsLanguage,
        captionsSettings,
        onChange,
        enableAssessmentFinish,
    }) => {
        const [videoDecisions, setVideoDecisions] = React.useState(null);
        const [showOverlay, setShowingOverlay] = React.useState(false);
        const [isAtEnd, setIsAtEnd] = React.useState(false);
        const [currentPlaylistIndex, setCurrentPlaylistIndex] =
            React.useState(0);

        //console.log('In decision player with: ', subject)

        React.useEffect(() => {
            if (subject && subject.video_decisions) {
                //console.log('We have decisions!', subject.video_decisions);
                setVideoDecisions(subject.video_decisions);
            }
        }, [subject]);

        const onError = (ev, data) => {
            console.log('onError');
            if (ev.code === 232403) {
                videoTokenRequested(subject.video_id_jw);
            }
        };

        const onSetupError = (ev) => {
            console.log('onSetupError');
            if (ev.code === 102403) {
                videoTokenRequested(subject.video_id_jw);
            }
        };

        const setCaptionsLanguage = () => {
            //console.log('Player is ready');
            let captionsIndex = 0;
            if (presetCaptionsLanguage) {
                const captions = window.jwplayer().getCaptionsList();
                for (let i = 0; i < captions.length; i++) {
                    if (captions[i].label === presetCaptionsLanguage) {
                        captionsIndex = i;
                    }
                }
            }
            window.jwplayer().setCurrentCaptions(captionsIndex);
            hideNextUpPopupWindow();
        };

        const getPlayerCustomProps = () => {
            let props = { skin: { name: 'AwareGO' } };
            if (captionsSettings) {
                props.captions = JSON.parse(captionsSettings);
            }
            return props;
        };

        const handleNinetyFivePercent = () => {
            //console.log('95%!!!!!!');
            //console.log(window.jwplayer().getPlaylistIndex());
            if (subject && subject.video_decisions) {
                window.jwplayer().pause();
                //console.log('videoDecisions[window.jwplayer().getPlaylistIndex()]:', videoDecisions[window.jwplayer().getPlaylistIndex()]);
                if (
                    !videoDecisions[window.jwplayer().getPlaylistIndex()]
                        .decision_1
                ) {
                    //If this index points to a decision question without a decision, don't pause
                    //      console.log('Show the End overlay')
                    setIsAtEnd(true);
                    enableAssessmentFinish();
                } else {
                    setIsAtEnd(false);
                }
                setShowingOverlay(true);
            }
        };

        const onAnswer = (decisionQuestionId, decision) => {
            //console.log('Made decision: ', decision);
            setCurrentPlaylistIndex(decision.playlistIndex);
            setShowingOverlay(false);
            onChange(decisionQuestionId, decision);
            window.jwplayer().playlistItem(decision.playlistIndex);
        };

        const hideNextUpPopupWindow = () => {
            const nextUpElement = window
                .jwplayer()
                .getContainer()
                .querySelector('.jw-nextup-container');
            if (nextUpElement) {
                nextUpElement.style.display = 'none';
            }
        };

        const shouldShowQuestionOverlay = () => {
            return (
                videoDecisions &&
                showOverlay &&
                videoDecisions[currentPlaylistIndex] &&
                (videoDecisions[currentPlaylistIndex].questionText || isAtEnd)
            );
        };

        if (!token) {
            return (
                <div className="video">
                    <div
                        className="spinner-table small"
                        style={{ textAlign: 'center' }}
                    >
                        <Spinner />
                    </div>
                </div>
            );
        } else if (!token.videoId) {
            return (
                <div className="video">
                    {subject.title} video not available.
                </div>
            );
        } else {
            const playlist =
                token &&
                `https://content.jwplatform.com/feeds/${token.videoId}.json${token.sig_feed}`;

            const customProps = getPlayerCustomProps();

            return Modernizr.video ? (
                videoPlayerUrl ? (
                    <>
                        <div
                            id="video"
                            className="video"
                            style={{ position: 'relative' }}
                        >
                            {shouldShowQuestionOverlay() && (
                                <DecisionOverlay
                                    decisionQuestion={
                                        videoDecisions[currentPlaylistIndex]
                                    }
                                    onAnswer={onAnswer}
                                    isAtEnd={isAtEnd}
                                />
                            )}

                            <ReactJWPlayer
                                className={classes('video-item', {
                                    'decision-video-item':
                                        subject.video_decisions,
                                })}
                                playerId={subject.slug || 'video-player'}
                                playerScript={videoPlayerUrl}
                                licenseKey="tbMPLx6pAOjLHsSbrqGJdPVygT90S8O71WG28+3dDJA="
                                playlist={playlist}
                                width="100%"
                                aspectratio="16:9"
                                isAutoPlay={false}
                                onSetupError={() => onSetupError()}
                                onError={() => onError()}
                                onTwentyFivePercent={() =>
                                    logEvent(subject, CONSTANTS.EVENTS.VIDEO_25)
                                }
                                onFiftyPercent={() =>
                                    logEvent(subject, CONSTANTS.EVENTS.VIDEO_50)
                                }
                                onSeventyFivePercent={() =>
                                    logEvent(subject, CONSTANTS.EVENTS.VIDEO_75)
                                }
                                onNinetyFivePercent={() => {
                                    handleNinetyFivePercent();
                                    logEvent(
                                        subject,
                                        CONSTANTS.EVENTS.VIDEO_95
                                    );
                                }}
                                onOneHundredPercent={() =>
                                    logEvent(
                                        subject,
                                        CONSTANTS.EVENTS.VIDEO_100
                                    )
                                }
                                onPlay={() =>
                                    logEvent(
                                        subject,
                                        CONSTANTS.EVENTS.VIDEO_START,
                                        token
                                    )
                                }
                                onReady={() => setCaptionsLanguage()}
                                customProps={customProps}
                            />
                        </div>
                    </>
                ) : null
            ) : (
                <div className="alert alert-danger">
                    {t(
                        'Your browser does not currently recognize any of the video formats available'
                    )}
                </div>
            );
        }
    }
);
