import stores from '../stores';
import axios from 'axios';
import uuid from 'uuid/v4';
import { version } from '../../package.json';
import * as Sentry from '@sentry/react';

const API_ROOT = process.env.REACT_APP_API_URL;
const DOMAIN =
    process.env.REACT_APP_DOMAIN ||
    window.localStorage.getItem('custom_domain');
const appId = uuid();
const appVersion = version;

Sentry.setContext('app', {
    requestId: `${appId}`,
    appVersion: `${appVersion}`,
    appAccountDomain: `${DOMAIN || window.location.host}`,
});

class BaseService {
    handleErrors(err) {
        if (err && err.response && err.response.status === 401) {
            stores.authStore.logout();
        }
        return err;
    }

    commonHeaders() {
        let headers = {
            'X-App-Request-Id': `${appId}`,
            'X-App-Version': `${appVersion}`,
            'X-App-Account-Domain': `${DOMAIN || window.location.host}`,
        };

        if (stores.authStore.token)
            headers['x-access-token'] = `${stores.authStore.token}`;
        return { headers };
    }

    async del(url, body) {
        try {
            let response = await axios.delete(url, {
                baseURL: API_ROOT,
                ...this.commonHeaders(),
                data: body,
            });
            return response.data;
        } catch (e) {
            let notHandled = this.handleErrors(e);
            if (notHandled) throw notHandled;
        }
    }

    async get(url, params) {
        try {
            let response = await axios.get(url, {
                params,
                baseURL: API_ROOT,
                ...this.commonHeaders(),
            });
            return response.data;
        } catch (e) {
            let notHandled = this.handleErrors(e);
            if (notHandled) throw notHandled;
        }
    }

    async put(url, body, options) {
        try {
            let response = await axios.put(url, body, {
                baseURL: API_ROOT,
                ...this.commonHeaders(),
                ...options,
            });
            return response.data;
        } catch (e) {
            let notHandled = this.handleErrors(e);
            if (notHandled) throw notHandled;
        }
    }

    async post(url, body, options) {
        try {
            let config = { baseURL: API_ROOT, ...this.commonHeaders() };
            let response = await axios.post(url, body, config);
            return response.data;
        } catch (e) {
            let notHandled = this.handleErrors(e);
            if (notHandled) throw notHandled;
        }
    }
}

export default BaseService;
