import React, { useEffect } from 'react';
import { Route, Redirect } from 'react-router';
import { inject, observer } from 'mobx-react';
import { useHistory, useLocation } from 'react-router-dom';

function PrivateRoute({
    component: Component,
    notLoggedUrl,
    authStore,
    ...rest
}) {
    const location = useLocation();
    const history = useHistory();

    if (!notLoggedUrl) notLoggedUrl = authStore.loginUrl;

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);

        if (
            queryParams.has('sso') &&
            authStore.isAuthenticated === true &&
            authStore.token != null
        ) {
            queryParams.delete('sso');
            history.replace({
                search: queryParams.toString(),
            });
        }
    }, []);

    return (
        <Route
            {...rest}
            render={(props) => {
                if (
                    notLoggedUrl &&
                    (notLoggedUrl.startsWith('http://') ||
                        notLoggedUrl.startsWith('https://'))
                ) {
                    window.location = notLoggedUrl;
                    return;
                }
                console.log(notLoggedUrl);

                return authStore.isAuthenticated === true &&
                    authStore.token != null ? (
                    <Component {...props} {...rest} />
                ) : (
                    <Redirect to={notLoggedUrl} />
                );
            }}
        />
    );
}

export default inject('authStore')(observer(PrivateRoute));
