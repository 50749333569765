import React, { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import _, { map } from 'lodash';
import omit from 'lodash/omit';
import { useStores } from '../../hooks/use-stores';
import classes from 'classnames';
import ThemedProgress from '../../components/themed/ThemedProgress';
import ThemedFooterButton from '../../components/themed/ThemedFooterButton';
import Question from '../Question';
import services from '../../services';
import Modal from 'react-modal';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionActions from '@material-ui/core/AccordionActions';
import { ThemedButton } from '../../components/themed/ThemedComponents';
import { Col, Row } from 'reactstrap';
import SemiCircleProgress from '../../components/semi-circle-progress';
import {
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
} from '@material-ui/core';
import Snackbar from '../../components/Snackbar';
import { extractErrorMessage } from '../../utils/helpers';

function filterBlockValues(blockValues) {
    let result = {};
    map(blockValues, (v, k) => {
        if (v != null && k !== null && k !== 'undefined') result[k] = v;
    });
    return result;
}

@inject('commonStore')
@observer
class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, lastError: null };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true, lastError: extractErrorMessage(error) };
    }

    componentDidCatch(error, errorInfo) {
        this.props.commonStore.error(extractErrorMessage(error));
        return true;
    }

    render() {
        if (this.state.hasError) {
            return <h1>{extractErrorMessage(this.state.lastError)} </h1>;
        }

        return this.props.children;
    }
}

export default observer(function Playground({
    parentUrl,
    assessmentId,
    language,
}) {
    const { authStore, commonStore } = useStores();
    const { currentUser } = authStore;
    const { t } = useTranslation();
    const [isStarted, setIsStarted] = React.useState(false);
    const [isNextAvailable, setIsNextAvailable] = React.useState(false);
    const [questions, setQuestions] = React.useState([]);
    const [questionsDone, setQuestionsDone] = React.useState([]);
    const [question, setQuestion] = React.useState(null);
    const [questionActions, setQuestionActions] = React.useState(null);
    const [questionId, setQuestionId] = React.useState(null);
    const [blockNum, setBlockNum] = React.useState(-1);
    const [blockValues, setBlockValues] = React.useState(null);
    const [completedBlocks, setCompletedBlocks] = React.useState(null);
    const [questionComplete, setQuestionComplete] = React.useState(false);
    const [devPanel, setDevPanel] = React.useState(false);
    const [scorePanel, setScorePanel] = React.useState(false);
    const [counter, setCounter] = React.useState(0);
    const [newQuestionText, setNewQuestionText] = React.useState('');
    const [questionsSources, setQuestionsSources] = React.useState({});
    const [scoreRecords, setScoreRecords] = React.useState([]);
    const [visibleBlocks, setVisibleBlocks] = useState(null);

    useEffect(() => {
        if (blockNum !== -1 && questionId) {
            window.localStorage.setItem(
                `assessment-playground-block-num-${questionId}`,
                blockNum.toString()
            );
        }
    }, [blockNum, questionId]);

    useEffect(() => {
        if (questionActions && questionId) {
            window.localStorage.setItem(
                `assessment-playground-question-actions-${questionId}`,
                JSON.stringify(questionActions)
            );
        }
    }, [questionActions, questionId]);

    useEffect(() => {
        if (blockValues && questionId) {
            window.localStorage.setItem(
                `assessment-playground-block-values${questionId}`,
                JSON.stringify(blockValues)
            );
        }
        if (blockValues && question) {
            let filteredValues = filterBlockValues(blockValues);

            let visibleBlocks = question.ui.blocks.filter((block) => {
                if (block.condition) {
                    if (blockValues[block.condition.actionId]) {
                        if (
                            Array.isArray(
                                blockValues[block.condition.actionId]
                            ) &&
                            blockValues[block.condition.actionId].includes(
                                block.condition.value
                            )
                        ) {
                            return true;
                        } else if (
                            blockValues[block.condition.actionId] ===
                            block.condition.value
                        ) {
                            return true;
                        }
                    }
                } else {
                    return true;
                }
                return false;
            });
            setVisibleBlocks(visibleBlocks);
            //let completedBlocks =  question.ui.blocks.map(block=>{
            let completedBlocks = visibleBlocks.map((block) => {
                let actionId = block.actionId;
                let x = filteredValues[actionId];

                if (Array.isArray(x) || typeof x === 'object') {
                    if (block.type === 'yes-no-list') {
                        if (
                            typeof x === 'object' &&
                            Object.keys(x).length === block.questions.length
                        ) {
                            return true;
                        }
                    } else if (x.length > 0) {
                        return true;
                    }
                } else if (typeof x === 'string') {
                    return true;
                }
                return false;
            });
            setCompletedBlocks(completedBlocks || []);
        } else {
            setCompletedBlocks([]);
            setVisibleBlocks([]);
        }
    }, [blockValues, questionId]);

    useEffect(() => {
        loadData();
    }, [assessmentId, language]);

    useEffect(() => {
        if (question && completedBlocks) {
            setQuestionComplete(
                question.ui.blocks.length ===
                    completedBlocks.filter((x) => x).length
            );
        } else setQuestionComplete(false);
    }, [questionId, completedBlocks]);

    useEffect(() => {
        (async () => {
            if (questionId) {
                let q = questions.find((x) => x.id === questionId);
                setQuestion(q);

                let _questionActions = [];
                let _blockValues = {};
                let _blockNum;
                try {
                    let v = window.localStorage.getItem(
                        `assessment-playground-question-actions-${questionId}`
                    );
                    if (v) _questionActions = JSON.parse(v);
                } catch (e) {
                    _questionActions = [];
                }
                try {
                    let v = window.localStorage.getItem(
                        `assessment-playground-block-values-${questionId}`
                    );
                    if (v) _blockValues = JSON.parse(v);
                } catch (e) {
                    _blockValues = {};
                }
                _blockNum =
                    parseInt(
                        window.localStorage.getItem(
                            `assessment-playground-block-num-${questionId}`
                        )
                    ) || 0;
                setBlockNum(_blockNum);
                setBlockValues(_blockValues);
                setQuestionActions(_questionActions);
                switchContinueButton(_blockValues[_blockNum]);
            }
        })();
    }, [questionId]);

    const switchContinueButton = (blockValue) => {
        if (Array.isArray(blockValue)) {
            onQuestionDone(blockValue.length > 0);
        } else onQuestionDone(!!blockValue);
    };

    const handleBlockChange = (value, actionId) => {
        let blockActionId = actionId || question.ui.blocks[blockNum].actionId;
        let newVal = { ...blockValues, ...{ [blockActionId]: value } };
        setBlockValues(newVal);
        switchContinueButton(value);
    };

    const loadData = async () => {
        setIsStarted(true);
        setIsNextAvailable(false);

        let _questions = [];
        try {
            let v = window.localStorage.getItem(
                `assessment-playground-questions`
            );
            if (v) _questions = JSON.parse(v);
        } catch (e) {
            _questions = [];
        }
        let sources = {};
        for (let i = 0; i < _questions.length; i++) {
            let id = 999999 + i + 1;

            sources[_questions[i].id] = JSON.stringify(_questions[i], null, 2);
            _questions[i].id = id;
        }

        setQuestions(_questions);
        setQuestionsSources(sources);

        let q = _questions[questionsDone.length];
        if (q) setQuestionId(q.id);
    };

    const onFinish = async () => {
        // complete last question firts:
        let result = await services.Assessments.questionActionsPreview(
            questionId,
            [
                ...questionActions,
                { action: 'complete_question', value: blockValues },
            ],
            questions
        );

        commonStore.showMessage(`Question recorded, score: ${result.total}`);
        setScoreRecords([...scoreRecords, { questionId, scores: result }]);

        let qq = questionsDone;
        if (!qq.includes(questionId)) qq.push(questionId);
        setQuestionsDone(qq);

        setQuestionId(null);
    };

    const onQuestionAction = async (questionId, action, value, immediate) => {
        //if ( immediate ) {
        //    return await services.Assessments.questionAction(assessmentId, questionId, action, value)
        // }
        // else{

        //Keeping questionActions up-to-date, but maybe we don't need this...just POST every action
        let existingQuestionValue = _.find(questionActions, {
            questionId,
            action,
        });
        if (existingQuestionValue) {
            existingQuestionValue.value = value;
            setQuestionActions([...questionActions]);
        } else {
            existingQuestionValue = { questionId, action, value };
            setQuestionActions([...questionActions, existingQuestionValue]);
        }

        //do not post  every time.  at least use immediate flag  for exact actions which should be posted right when happens
        // await assessmentStore.questionAction(assessmentId, questionId, action, value);
    };

    const onNextLesson = async () => {
        //console.log("onNextLesson, push answers here")
        const questionId = questions[questionsDone.length].id;

        //await  assessmentStore.completeQuestion(assessmentId, questionId
        //    , [...questionActions, {action: "complete_question",value: blockValues } ])
        //questionsDone.push( questionId )
        let result = await services.Assessments.questionActionsPreview(
            questionId,
            [
                ...questionActions,
                { action: 'complete_question', value: blockValues },
            ],
            questions
        );

        commonStore.showMessage(`Question recorded, score: ${result.total}`);

        setScoreRecords([...scoreRecords, { questionId, scores: result }]);

        let qq = questionsDone;
        if (!qq.includes(questionId)) qq.push(questionId);

        setQuestionId(questions[questionsDone.length].id);
        setBlockNum(0);
        setQuestionActions([]);
        setBlockValues({});
    };
    const onNextBlock = async () => {
        setBlockNum(Math.min(blockNum + 1, question.ui.blocks.length - 1));
        let blockActionId = question.ui.blocks[blockNum].actionId;
        handleBlockChange(blockValues[blockActionId]);
    };
    const onPrevBlock = async () => {
        setBlockNum(Math.max(blockNum - 1, 0));
        let blockActionId = question.ui.blocks[blockNum].actionId;
        handleBlockChange(blockValues[blockActionId]);
    };

    const onNext = async () => {
        if (!question) return;

        if (questionComplete) return onNextLesson();

        return onNextBlock();
    };

    const onQuestionDone = async (nextAvailable) => {
        setIsNextAvailable(nextAvailable);
    };

    const closeSnackbar = () => {
        commonStore.hideMessage();
    };

    const showDevPanel = () => {
        setScorePanel(false);
        setDevPanel(true);
    };

    const hideDevPanel = () => {
        setDevPanel(false);
    };

    const showScorePanel = () => {
        setDevPanel(false);
        setScorePanel(true);
    };

    const hideScorePanel = () => {
        setScorePanel(false);
    };

    const removeState = (key) => {
        delete localStorage[key];
        setCounter(counter + 1);
    };

    const addQuestion = async () => {
        let text;
        try {
            text = JSON.stringify([...questions, JSON.parse(newQuestionText)]);
        } catch (e) {
            alert('not valid json');
            return;
        }
        window.localStorage.setItem(`assessment-playground-questions`, text);
        await loadData();
    };

    const typeSource = async (value, id) => {
        setQuestionsSources({ ...questionsSources, [id]: value });
    };

    const deleteQuestion = async (id) => {
        setQuestionsSources(omit(questionsSources, id));
    };

    const updateQuestion = async (id) => {
        let existing = questions.find((x) => x.id === id);
        let newOne;

        try {
            newOne = JSON.parse(questionsSources[id]);
        } catch (e) {
            alert('not valid json');
            return;
        }
        Object.assign(existing, newOne);
        window.localStorage.setItem(
            `assessment-playground-questions`,
            JSON.stringify(questions)
        );
        await loadData();
    };

    const done = questionsDone.length / questions.length;
    const isFinished =
        questionsDone.length === questions.length && questions.length > 0;
    const isTimedOut = false;
    // const isFirst = assessment.status.questionsDone.length ===0
    const isLast =
        questionsDone.length === questions.length - 1 && questionComplete;

    let state = Object.entries(localStorage).filter((x) =>
        x[0].startsWith('assessment-playground-')
    );

    console.log('isFinished', isFinished);
    console.log('questions', questions);
    console.log('blockNum', blockNum);

    return (
        <div className={classes('wizard')}>
            <div className={'wizard-header'}>
                <Link className={'wizard-close'} to={parentUrl || ''}>
                    <img src="/static/img/close.svg" alt={t('Close')} />
                </Link>
                <h1> Assessment Playground</h1>

                <div className="wizard-header-status">
                    <span>
                        {questionsDone.length + 1}/{questions.length} question
                    </span>
                    <ThemedButton onClick={showScorePanel}>SCORES</ThemedButton>
                    <ThemedButton onClick={showDevPanel}>
                        DEV PANEL
                    </ThemedButton>
                </div>
            </div>
            {scorePanel && (
                <div
                    style={{
                        position: 'absolute',
                        backgroundColor: '#eeeeee',
                        zIndex: 999,
                        top: 40,
                        left: 40,
                        right: 40,
                        bottom: 40,
                        overflow: 'scroll',
                        border: '2px solid navy',
                        borderRadius: 5,
                    }}
                >
                    <hr />
                    <h3>Recorded Scores</h3>

                    {scoreRecords.map((q) => {
                        return (
                            <div>
                                Question {q.questionId} - total:{' '}
                                {q.scores.total}; recorded Actions:{' '}
                                {q.scores.actionsRecorded}
                                <pre>
                                    {Object.keys(q.scores.perComponent)
                                        .filter(
                                            (x) =>
                                                x !==
                                                'question-complete_question'
                                        )
                                        .map((x) => {
                                            return `${x}: ${q.scores.perComponent[x].score} \n`;
                                        })}
                                </pre>
                            </div>
                        );
                    })}

                    <ThemedButton
                        onClick={hideScorePanel}
                        style={{ position: 'fixed', top: 10, right: 10 }}
                    >
                        CLOSE SCORES PANEL
                    </ThemedButton>
                </div>
            )}
            {devPanel && (
                <div
                    style={{
                        position: 'absolute',
                        backgroundColor: '#eeeeee',
                        zIndex: 999,
                        top: 40,
                        left: 40,
                        right: 40,
                        bottom: 40,
                        overflow: 'scroll',
                        border: '2px solid navy',
                        borderRadius: 5,
                    }}
                >
                    <hr />
                    <h3>LocalStorage State </h3>
                    {state.map((x) => {
                        return (
                            <div>
                                <b>{x[0]}</b>
                                <i
                                    style={{
                                        fontSize: 8,
                                        maxWidth: 450,
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        display: 'inline-block',
                                    }}
                                    title={x[1]}
                                >
                                    {x[1]}
                                </i>
                                <ThemedButton
                                    onClick={(e) => removeState(x[0])}
                                >
                                    X
                                </ThemedButton>
                            </div>
                        );
                    })}
                    <h3>Current Questions </h3>

                    {questions.map((q) => {
                        return (
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1c-content"
                                    id="panel1c-header"
                                >
                                    {' '}
                                    <Typography>{q.id}</Typography>
                                </AccordionSummary>
                                <AccordionDetails className={classes.details}>
                                    <textarea
                                        rows="10"
                                        cols="100"
                                        value={questionsSources[q.id]}
                                        onChange={(e) =>
                                            typeSource(e.target.value, q.id)
                                        }
                                    ></textarea>
                                </AccordionDetails>
                                <AccordionActions>
                                    <Button
                                        size="small"
                                        onClick={(e) => deleteQuestion(q.id)}
                                    >
                                        Delete
                                    </Button>
                                    <Button
                                        size="small"
                                        color="primary"
                                        onClick={(e) => updateQuestion(q.id)}
                                    >
                                        Update
                                    </Button>
                                </AccordionActions>
                            </Accordion>
                        );
                    })}

                    <hr />
                    <h3> Add question</h3>

                    <textarea
                        rows={7}
                        cols={100}
                        value={newQuestionText}
                        onChange={(e) => setNewQuestionText(e.target.value)}
                    ></textarea>
                    <ThemedButton
                        onClick={addQuestion}
                        disabled={newQuestionText.trim() === ''}
                    >
                        Add
                    </ThemedButton>

                    <ThemedButton
                        onClick={hideDevPanel}
                        style={{ position: 'fixed', top: 10, right: 10 }}
                    >
                        CLOSE DEV PANEL
                    </ThemedButton>
                </div>
            )}

            <ThemedProgress narrow value={done} />
            <Fragment>
                {isFinished ? (
                    <div className="wizard-body">
                        <div className="completed-banner d-flex flex-fill flex-column align-items-center justify-content-center">
                            <div className="quiz-slide center">
                                <h3>Congrats, you’ve completed the quiz!</h3>
                                <div className="progress-semicircle with-stars yellow">
                                    <SemiCircleProgress
                                        size="large"
                                        percentage={65}
                                    />
                                </div>
                                <h4>
                                    Your score is:{' '}
                                    <span style={{ color: '#F2C94C' }}>
                                        Very good
                                    </span>
                                </h4>
                                <p>
                                    You have shown a very good understanding of
                                    topics presented in this training. With some
                                    additiononal traning you will easily earn an
                                    Excellent.{' '}
                                </p>
                            </div>
                        </div>

                        <div className="quiz-slide">
                            <div className="red">Needs to be moved</div>
                            <h3>Quiz</h3>
                            <h4>
                                6 questions, completed on Oct 10th, 2020 at
                                10:58 AM.{' '}
                            </h4>

                            <div className="quiz-results block-shadow">
                                <Row>
                                    <Col md="4" xs="12">
                                        <div className="progress-semicircle with-stars yellow">
                                            <SemiCircleProgress
                                                percentage={65}
                                                size="large"
                                            />
                                        </div>
                                    </Col>
                                    <Col md="8" xs="12">
                                        <h4>
                                            Your score is:{' '}
                                            <span style={{ color: '#F2C94C' }}>
                                                Very good
                                            </span>
                                        </h4>
                                        <p>
                                            You have shown a very good
                                            understanding of topics presented in
                                            this training. With some
                                            additiononal traning you will easily
                                            earn an Excellent.{' '}
                                        </p>
                                        <ThemedButton
                                            secondary
                                            onClick={closeTimeOutModal}
                                        >
                                            {t('Review answers')}
                                        </ThemedButton>
                                        <ThemedButton
                                            primary
                                            onClick={closeTimeOutModal}
                                        >
                                            {t('Get certificate')}
                                        </ThemedButton>
                                    </Col>
                                </Row>
                            </div>
                            <br />
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Question</TableCell>
                                        <TableCell>Threat area</TableCell>
                                        <TableCell>Risk component</TableCell>
                                        <TableCell>Score</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>
                                            Phishing confidence
                                        </TableCell>
                                        <TableCell>Phishing</TableCell>
                                        <TableCell>Behaviour</TableCell>
                                        <TableCell>
                                            <div className="progress-semicircle small">
                                                <SemiCircleProgress
                                                    percentage={65}
                                                    size="small"
                                                />
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </div>
                    </div>
                ) : (
                    <div className="wizard-body">
                        <div className={'wizard-content wide'}>
                            <ErrorBoundary>
                                <Question
                                    handleBlockChange={handleBlockChange}
                                    loadingData={false}
                                    question={question}
                                    blockNum={blockNum}
                                    onPrevBlock={onPrevBlock}
                                    onNextBlock={onNextBlock}
                                    blockValues={blockValues}
                                    questionId={questionId}
                                    assessmentId={assessmentId}
                                    visibleBlocks={visibleBlocks}
                                    completedBlocks={completedBlocks}
                                    userId={currentUser.id}
                                    language={'en'}
                                    onQuestionAction={onQuestionAction}
                                />
                            </ErrorBoundary>
                        </div>
                    </div>
                )}

                {isStarted && (
                    <div className={'wizard-footer'}>
                        <div className={'actions'}>
                            {!isLast && (
                                <ThemedFooterButton
                                    primary
                                    continue
                                    disabled={isTimedOut || !isNextAvailable}
                                    onClick={onNext}
                                >
                                    {t('Continue')}
                                </ThemedFooterButton>
                            )}

                            {isLast && !isFinished && (
                                <ThemedFooterButton
                                    primary
                                    continue
                                    disabled={isTimedOut || !isNextAvailable}
                                    onClick={onFinish}
                                >
                                    {t('Finish')}
                                </ThemedFooterButton>
                            )}
                            {isLast && isFinished && (
                                <ThemedFooterButton
                                    primary
                                    continue
                                    to={`${parentUrl}`}
                                >
                                    {t('Finish')}
                                </ThemedFooterButton>
                            )}
                        </div>
                    </div>
                )}

                <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    autoHideDuration={6000}
                    variant={commonStore.messageType}
                    message={commonStore.message || ''}
                    open={commonStore.messageShown}
                    onClose={closeSnackbar}
                ></Snackbar>
            </Fragment>
        </div>
    );
});

//export default withRouter(TrainingView)
