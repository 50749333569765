import { observable, action, reaction } from 'mobx';
import services from '../services';
import { computed } from 'mobx';
import * as Sentry from '@sentry/react';
import { querystring as qs, backOffDelay } from '../utils/helpers';

class AuthStore {
    constructor(commonStore, companyStore, brandingStore) {
        this.commonStore = commonStore;
        this.companyStore = companyStore;
        this.brandingStore = brandingStore;
        reaction(
            () => this.token,
            (token) => {
                if (token) {
                    window.localStorage.setItem('jwt', token);
                    if (!this.currentUser) this.pullUser();
                } else {
                    window.localStorage.removeItem('jwt');
                    if (!this.brandingStore.brandingLoaded)
                        this.brandingStore.loadBranding();
                }
            }
        );
        reaction(
            () => this.currentUser,
            (user) => {
                if (user) {
                    Sentry.setUser(user);
                    Sentry.addBreadcrumb({
                        category: 'auth',
                        message: 'Authenticated user ' + user.email,
                        level: Sentry.Severity.Info,
                    });
                    this.companyStore.loadCompanies();
                } else {
                    this.companyStore.logout();
                    this.brandingStore.loadBranding();
                }
            }
        );
    }

    @observable token = window.localStorage.getItem('jwt');
    @observable inProgress = false;
    @observable currentUser;

    @observable loginError = undefined;
    @observable emailLoginError = undefined;

    @observable signupError = undefined;
    @observable profileError = undefined;
    @observable companyError = undefined;
    @observable email = undefined;

    extractErrorMessage(error) {
        let responseData =
            error.response && error.response.data && error.response.data;
        if (
            responseData &&
            responseData.errors &&
            Array.isArray(responseData.errors)
        ) {
            return responseData.errors
                .map((e) => e.messages.join(';'))
                .join('\n');
        }

        return (responseData && responseData.error) || error.message;
    }

    @action setError(error, type = 'login') {
        if (error instanceof Error) {
            error = this.extractErrorMessage(error);
            console.log(error);
        }
        this.loginError = type === 'login' ? error : null;
        this.emailLoginError = type === 'emailLogin' ? error : null;
        this.signupError = type === 'signup' ? error : null;
        this.profileError = type === 'profile' ? error : null;
    }

    @action
    async logout() {
        this.inProgress = false;
        this.forgetUser();
        this.setToken(null);
    }

    @action setToken(token) {
        this.token = token;
    }

    @action setEmail(email) {
        this.email = email;
    }

    @computed get displayName() {
        if (!this.currentUser) return '';
        return this.currentUser.name || this.currentUser.email;
    }

    @computed get isAuthenticated() {
        return this.currentUser != null;
    }

    @computed get isTeamManager() {
        if (!this.currentUser) return false;
        return this.currentUser.permissions.indexOf('manageUsers') > -1;
    }

    @action setUser(user) {
        this.currentUser = user;
    }

    @action forgetUser() {
        this.currentUser = undefined;
    }

    @action
    async signin(email, company_id, campaign_id, subject_id) {
        this.setError(null);
        try {
            const search = window.location.search; // could be '?foo=bar'
            const params = new URLSearchParams(search);
            const redirect = params.get('redirect'); // bar
            let result = await services.Auth.signin(
                email,
                company_id,
                campaign_id,
                subject_id,
                redirect
            );
            if (result.url) {
                window.location = result.url;
                return 'redirect';
            }
            return true;
        } catch (e) {
            this.setError(e);
        }
    }

    @action
    async signinFromEmail(token, company_id, campaign_id, subject_id) {
        this.setError(null);
        try {
            let jwtToken = await services.Auth.emailsignin(token);
            this.setToken(jwtToken);
            this.pullUser();
            return true;
        } catch (e) {
            this.setError(e, 'emailLogin');
        }
    }

    @action
    async directSignin(token) {
        this.setError(null);
        try {
            let jwtToken = await services.Auth.directSignin(token);
            this.setToken(jwtToken);
            this.pullUser();
            return true;
        } catch (e) {
            this.setError(e, 'emailLogin');
        }
    }

    @action
    async profileUpdate(values) {
        this.setError(null, 'profile');
        try {
            let user = await services.Users.update({
                _id: this.currentUser._id,
                ...values,
            });
            Object.assign(this.currentUser, user);
            this.commonStore.success('Your profile successful updated');
        } catch (e) {
            this.setError(
                (e.response && e.response.data && e.response.data.error) ||
                    e.message,
                'profile'
            );
        }
    }

    @action
    async pullUser(counter = 0) {
        this.inProgress = true;
        try {
            if (this.token) {
                let user = await services.Auth.current();
                if (user) this.setUser(user);
            } else {
                this.logout();
            }
        } catch (e) {
            this.inProgress = false;
            if (
                counter < 10 &&
                e &&
                (!e.response ||
                    e.response.status !== 403 ||
                    e.response.status !== 401)
            ) {
                setTimeout(async () => {
                    await this.pullUser(counter + 1);
                }, backOffDelay(1500, counter));
            } else {
                return this.logout();
            }
        } finally {
            // console.log("done")
            this.inProgress = false;
        }
    }

    @computed get loginUrl() {
        let isSSO = qs('sso', window.location.search);

        return isSSO && this.brandingStore.loginUrl
            ? this.brandingStore.loginUrl
            : `/auth/signin?redirect=${encodeURIComponent(
                  `${window.location.pathname}${window.location.search}`
              )}`;
    }
}

export default AuthStore;
