import React from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import Waiting from '../components/waiting';
import { querystring } from '../utils/helpers';
import qs from 'querystring';

@inject('authStore', 'store')
@observer
class DirectSignIn extends React.Component {
    componentDidMount() {
        let { token } = this.props.match.params;
        this.props.authStore.directSignin(token).then((result) => {
            let redirect = querystring('redirect') || '/';
            if (!redirect.startsWith('/')) redirect = `/${redirect}`;
            if (redirect === '/') {
                redirect = `${redirect}?${qs.stringify(
                    this.props.store.contextParams
                )}`;
            }
            this.props.history.push(result ? redirect : '/auth/error/email');
        });
    }

    render() {
        return <Waiting waitingActive={true} fullScreen={true} />;
    }
}

export default withRouter(DirectSignIn);
