import React from 'react';
import { observer } from 'mobx-react';
import classes from 'classnames';
import { Col, Container, Row } from 'reactstrap';
import { RadioButtons } from '@awarego/awarego-components';
import { CheckBoxes } from '@awarego/awarego-components';
import { NumericalScale } from '@awarego/awarego-components';
import { OrderableList } from '@awarego/awarego-components';
import { Questionwithbuttons } from '@awarego/awarego-components';
import { Questionwithimage } from '@awarego/awarego-components';
import { Questionwithsocialpost } from '@awarego/awarego-components';
import { YesNoList } from '@awarego/awarego-components';
import { PasswordTester } from '@awarego/awarego-components';
import { Decision } from '@awarego/awarego-components';
import { Illustration } from '@awarego/awarego-components';
import { InteractiveImg } from '@awarego/awarego-components';
import { Message } from '@awarego/awarego-components';
import { Password } from '@awarego/awarego-components';
import { Question } from '@awarego/awarego-components';
import { Video } from '@awarego/awarego-components';
import { EmailQuizGeneral } from '@awarego/awarego-components';
import { CustomPresentation } from '@awarego/awarego-components';
import { GroupOfBlocks } from '@awarego/awarego-components';
import { EmailQuizMdlzNewsletter } from '@awarego/awarego-components';
import { Conversation } from '@awarego/awarego-components';
import Icon from '@material-ui/core/Icon';
import Fab from '@material-ui/core/Fab';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { useStores } from '../hooks/use-stores';
import Spinner from '../components/Spinner';
import keyBy from 'lodash/keyBy';
import { useTranslation } from 'react-i18next';

export default observer(
    ({
        question,
        loadingData,
        onQuestionAction,
        enableAssessmentFinish,
        userName,
        userEmail,
        completedBlocks,
        visibleBlocks,
        blockNum,
        blockValues,
        handleBlockChange,
        onNextBlock,
        onPrevBlock,
        onNextQuestion,
        blockFadeOut,
        blockFadeIn,
    }) => {
        const { store } = useStores();
        const [token, setToken] = React.useState(null);

        const {
            videoPlayerUrl,
            tokens,
            playerPresetCaptionsLanguage,
            playerCaptionsSettings,
        } = store;

        const videoTokenRequested = async (videoId) => {
            await store.videoToken(videoId);
        };
        const { t } = useTranslation();

        React.useEffect(() => {
            const loadVideoData = async (block) => {
                if (!store.videoPlayerUrl) await store.loadVideoPlayerUrl();
                if (
                    block.subject &&
                    (block.subject.id || block.subject.subject_id) &&
                    !store.tokenIndex[
                        block.subject.id || block.subject.subject_id
                    ]
                ) {
                    await store.videoToken(
                        block.subject.id || block.subject.subject_id
                    );
                }
                let tokenArray = keyBy(tokens, 'id');
                setToken(
                    tokenArray[block.subject.id || block.subject.subject_id]
                );
            };

            let decisionBlocks = (visibleBlocks || []).filter(
                (b) => b.type === 'decision'
            );
            if (decisionBlocks.length > 0) loadVideoData(decisionBlocks[0]);
        }, [visibleBlocks]);

        const onAction = async (actionId, value, immediate) => {
            onQuestionAction(question.id, actionId, value, immediate);
        };

        const renderBlock = (block) => {
            if (!block || !blockValues) return;
            let blockActionId = visibleBlocks[blockNum].actionId;

            switch (block.type) {
                case 'scale':
                    return 'scale';
                case 'checkbox':
                    return (
                        <CheckBoxes
                            ui={block}
                            handleChange={handleBlockChange}
                            value={blockValues[blockActionId]}
                        />
                    );
                case 'radio':
                    return (
                        <RadioButtons
                            ui={block}
                            handleChange={handleBlockChange}
                            value={blockValues[blockActionId]}
                        />
                    );
                case 'password-tester':
                    return (
                        <PasswordTester
                            ui={block}
                            handleChange={handleBlockChange}
                            value={blockValues[blockActionId]}
                        />
                    );
                case 'numericalscale':
                    return (
                        <NumericalScale
                            ui={block}
                            handleChange={handleBlockChange}
                            value={blockValues[blockActionId]}
                        />
                    );
                case 'orderablelist':
                    return (
                        <OrderableList
                            ui={block}
                            handleChange={handleBlockChange}
                            value={blockValues[blockActionId]}
                        />
                    );
                case 'conversation':
                    return (
                        <Conversation
                            ui={block}
                            handleChange={handleBlockChange}
                            value={blockValues[blockActionId]}
                        />
                    );
                case 'yes-no-list':
                    return (
                        <YesNoList
                            ui={block}
                            handleChange={handleBlockChange}
                            value={blockValues[blockActionId]}
                        />
                    );
                case 'questionwithbuttons':
                    return (
                        <Questionwithbuttons
                            ui={block}
                            handleChange={handleBlockChange}
                            value={blockValues[blockActionId]}
                        />
                    );
                case 'questionwithimage':
                    return (
                        <Questionwithimage
                            ui={block}
                            handleChange={handleBlockChange}
                            value={blockValues[blockActionId]}
                        />
                    );
                case 'questionwithsocialpost':
                    return (
                        <Questionwithsocialpost
                            ui={block}
                            handleChange={handleBlockChange}
                            value={blockValues[blockActionId]}
                        />
                    );
                case 'groupOfBlocks':
                    return (
                        <GroupOfBlocks
                            ui={block}
                            handleChange={handleBlockChange}
                            value={blockValues[blockActionId]}
                        />
                    );
                case 'decision':
                    if (!token) return <Spinner />;
                    else
                        return (
                            <Decision
                                ui={block}
                                handleChange={handleBlockChange}
                                enableAssessmentFinish={enableAssessmentFinish}
                                value={blockValues[blockActionId]}
                                videoTokenRequested={videoTokenRequested}
                                videoPlayerUrl={videoPlayerUrl}
                                token={token}
                                playerPresetCaptionsLanguage={
                                    playerPresetCaptionsLanguage
                                }
                                playerCaptionsSettings={playerCaptionsSettings}
                            />
                        );
                default:
                    return 'Unknown type';
            }
        };

        const renderPresentation = (presentation) => {
            switch (presentation && presentation.type) {
                case 'illustration':
                    return (
                        <Illustration ui={presentation} onAction={onAction} />
                    );
                case 'interactive-img':
                    return (
                        <InteractiveImg ui={presentation} onAction={onAction} />
                    );
                case 'email':
                    return (
                        <EmailQuizGeneral
                            ui={presentation}
                            onAction={onAction}
                            userEmail={userEmail}
                            userName={userName}
                        />
                    );
                case 'custom':
                    return (
                        <CustomPresentation
                            ui={presentation}
                            onAction={onAction}
                            onNextQuestion={onNextQuestion}
                        />
                    );
                case 'email-mdlz-newsletter':
                    return (
                        <EmailQuizMdlzNewsletter
                            ui={presentation}
                            onAction={onAction}
                            userEmail={userEmail}
                            userName={userName}
                        />
                    );
                case 'message':
                    return <Message ui={presentation} onAction={onAction} />;
                case 'password':
                    return <Password ui={presentation} onAction={onAction} />;
                case 'question':
                    return <Question ui={presentation} />;
                case 'video':
                    return <Video ui={presentation} />;
                default:
                    return 'Unknown type';
            }
        };

        /** Returns an array of the length of the actual block count */
        const getBlockCountAsArray = () => {
            return Array(getActualBlockCount()).fill({});
        };

        /** Get the actual block count, taking into account number of visible blocks and defined minimum count */
        const getActualBlockCount = () => {
            if (question && question.ui)
                return Math.max(
                    visibleBlocks ? visibleBlocks.length : 0,
                    question.ui.minimumBlockCount || 0
                );
            return 0;
        };

        if (loadingData || !question) return;

        return (
            <Container>
                <div className="home">
                    <Row>
                        {question &&
                        question.ui.presentation &&
                        !question.ui.presentation.show_only_as_answer ? (
                            <Col
                                md={
                                    !question.ui.presentation.fullScreen
                                        ? '6'
                                        : '12'
                                }
                                xs="12"
                            >
                                <div
                                    className={classes({
                                        'full-screen':
                                            question.ui.presentation.fullScreen,
                                    })}
                                >
                                    {renderPresentation(
                                        question.ui.presentation
                                    )}
                                </div>
                            </Col>
                        ) : null}
                        {!question.ui.presentation.fullScreen && (
                            <Col
                                md={
                                    question &&
                                    question.ui.presentation &&
                                    !question.ui.presentation
                                        .show_only_as_answer
                                        ? '6'
                                        : '12'
                                }
                                xs="12"
                                className="assessment-block-type-2"
                            >
                                <div className="d-flex justify-content-between">
                                    {getActualBlockCount() > 1 ? (
                                        <div className="assessment-carousel">
                                            <div className="assessment-carousel-progress">
                                                {getBlockCountAsArray().map(
                                                    (x, i) => {
                                                        if (blockNum === i) {
                                                            return (
                                                                <Icon
                                                                    key={
                                                                        'icon-' +
                                                                        i
                                                                    }
                                                                    color="primary"
                                                                    className={classes(
                                                                        {
                                                                            completed:
                                                                                completedBlocks[
                                                                                    i
                                                                                ],
                                                                        }
                                                                    )}
                                                                >
                                                                    <img
                                                                        src="/static/img/icon-step-active.svg"
                                                                        alt={t(
                                                                            'Active step'
                                                                        )}
                                                                    />
                                                                </Icon>
                                                            );
                                                        } else if (
                                                            i < blockNum ||
                                                            completedBlocks[i]
                                                        ) {
                                                            return (
                                                                <Icon
                                                                    key={
                                                                        'icon-' +
                                                                        i
                                                                    }
                                                                    color="primary"
                                                                >
                                                                    <img
                                                                        src="/static/img/icon-step.svg"
                                                                        alt={t(
                                                                            'Completed step'
                                                                        )}
                                                                    />
                                                                </Icon>
                                                            );
                                                        } else {
                                                            return (
                                                                <Icon
                                                                    key={
                                                                        'icon-' +
                                                                        i
                                                                    }
                                                                >
                                                                    <img
                                                                        src="/static/img/icon-step-following.svg"
                                                                        alt={t(
                                                                            'Next step'
                                                                        )}
                                                                    />
                                                                </Icon>
                                                            );
                                                        }
                                                    }
                                                )}
                                            </div>

                                            <div
                                                className="assessment-carousel-buttons"
                                                id="carousel-buttons"
                                            >
                                                <a href="#carousel-buttons">
                                                    <Fab
                                                        size={'small'}
                                                        disabled={
                                                            blockNum === 0
                                                        }
                                                        onClick={onPrevBlock}
                                                    >
                                                        <NavigateBeforeIcon />
                                                    </Fab>
                                                    <Fab
                                                        size={'small'}
                                                        disabled={
                                                            !visibleBlocks ||
                                                            blockNum ===
                                                                visibleBlocks.length -
                                                                    1 ||
                                                            !visibleBlocks[
                                                                blockNum
                                                            ] ||
                                                            !blockValues ||
                                                            !blockValues[
                                                                visibleBlocks[
                                                                    blockNum
                                                                ].actionId
                                                            ]
                                                        }
                                                        onClick={onNextBlock}
                                                    >
                                                        <NavigateNextIcon />
                                                    </Fab>
                                                </a>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="assessment-padding" />
                                    )}
                                </div>
                                <div
                                    className={classes(
                                        '',
                                        { 'fade-out': blockFadeOut },
                                        { 'fade-in': blockFadeIn }
                                    )}
                                >
                                    {visibleBlocks &&
                                        renderBlock(visibleBlocks[blockNum])}
                                </div>
                            </Col>
                        )}
                    </Row>
                </div>
            </Container>
        );
    }
);
