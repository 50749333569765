/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import { Helmet } from 'react-helmet';
import VideoPlayer from '../../containers/video-player';
import SCORMPlayer from '../../containers/scorm-player';
import Question from './question';
import { Container } from 'reactstrap';
import { formatTime } from '../../utils/helpers';
import { useTranslation } from 'react-i18next';
import sanitizeHtml from 'sanitize-html';

export default observer(
    ({
        training,
        subject,
        logEvent,
        shuffledAnswers,
        step2Available,
        questionsAvailable,
    }) => {
        const { t } = useTranslation();
        const scrollRef = React.createRef();
        const scrollToMyRef = () => {
            if (scrollRef.current) {
                scrollRef.current.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                });
            }
        };
        React.useEffect(() => {
            if (step2Available) {
                scrollToMyRef();
            }
        }, [step2Available]);

        if (!subject) {
            return null;
        }
        return (
            <div>
                <Helmet>
                    <title>{subject.title}</title>
                </Helmet>
                <div className="subject">
                    {subject.type !== 2 && subject.type !== 3 && (
                        <Fragment>
                            <br />
                            <h3>{subject.title}</h3>
                            <div className="time">
                                {formatTime(subject.duration)} {t('min')}
                            </div>

                            <p>{subject.description}</p>
                        </Fragment>
                    )}
                    {subject.notes && (
                        <div className="custom-note">
                            {subject.notes &&
                                subject.notes.map((note) => {
                                    return (
                                        <div
                                            className="subject-optional-note"
                                            key={note.id}
                                        >
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: sanitizeHtml(
                                                        note.content
                                                    ),
                                                }}
                                            ></div>
                                        </div>
                                    );
                                })}
                        </div>
                    )}

                    {subject.type !== 2 && subject.type !== 3 && (
                        <h5>
                            {t('Step 1')}:{' '}
                            {subject.type === 1
                                ? t('Watch the video')
                                : t('Run the course')}
                        </h5>
                    )}
                    {subject && subject.type === 1 && (
                        <VideoPlayer logEvent={logEvent} subject={subject} />
                    )}
                    {subject && (subject.type === 2 || subject.type === 3) && (
                        <SCORMPlayer
                            training={training}
                            logEvent={logEvent}
                            subject={subject}
                        />
                    )}
                    {!step2Available && questionsAvailable && (
                        <div className="quizHidden-holder">
                            <div className="quizOverlay">
                                <div className="image">
                                    <img
                                        src="/static/img/icon-display.svg"
                                        alt={t('Watch')}
                                    />
                                </div>
                                <div className="quizHiddenText">
                                    <p className="title">
                                        {t('Finish the video')}
                                    </p>
                                    <p>{t('to access the quiz')}</p>
                                </div>
                            </div>
                            <div className="quizHidden">
                                <h5>
                                    {t('Step 2')}: {t('Complete the Quiz')}
                                </h5>
                                <Question
                                    question={subject.question}
                                    subject={subject}
                                    isAnswered={
                                        subject.status.ANSWARE_CORRECT === 1
                                    }
                                    answers={shuffledAnswers}
                                    logEvent={logEvent}
                                />
                            </div>
                        </div>
                    )}
                    {step2Available && questionsAvailable && (
                        <div ref={scrollRef}>
                            <Fragment>
                                <h5>
                                    {t('Step 2')}: {t('Complete the Quiz')}
                                </h5>
                                <Question
                                    question={subject.question}
                                    subject={subject}
                                    isAnswered={
                                        subject.status.ANSWARE_CORRECT === 1
                                    }
                                    answers={shuffledAnswers}
                                    logEvent={logEvent}
                                />
                                {subject.funfact && (
                                    <div className="section-fact">
                                        <Container
                                            dangerouslySetInnerHTML={{
                                                __html: sanitizeHtml(
                                                    subject.funfact
                                                ),
                                            }}
                                        ></Container>
                                    </div>
                                )}

                                {subject.content && (
                                    <div className="section-text">
                                        <h5>
                                            {t('Step 3')}:{' '}
                                            {t('Further reading')}
                                        </h5>
                                        <p
                                            className="subject-text"
                                            dangerouslySetInnerHTML={{
                                                __html: sanitizeHtml(
                                                    subject.content
                                                ),
                                            }}
                                        ></p>
                                        <br />
                                        <br />
                                        <br />
                                    </div>
                                )}
                            </Fragment>
                        </div>
                    )}
                </div>
            </div>
        );
    }
);
