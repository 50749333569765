import React, { Fragment } from 'react';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { Col, Row, Progress, Popover, PopoverBody } from 'reactstrap';
import { Helmet } from 'react-helmet';
import pluralize from 'pluralize';
import { observer } from 'mobx-react';
import SubjectsHome from '../Subjects';
import Subjects from '../../components/company/subjects';
import Assessments from '../../components/assessment/assessments';
import {
    ThemedText,
    ThemedButton,
} from '../../components/themed/ThemedComponents';
import ThemedProgress from '../../components/themed/ThemedProgress';
import ThemedLessonsCompleted from '../../components/themed/ThemedLessonsCompleted';
import ThemedTrainingCompleted from '../../components/themed/ThemedTrainingCompleted';
import { useTranslation } from 'react-i18next';
import Spinner from '../../components/Spinner';
import { percentToColor, formatDate } from '../../utils/helpers';
import SendCertificateForm from '../SendCertificateForm';
import Modal from 'react-modal';
import { useStores } from '../../hooks/use-stores';

export default observer(({ companyId, match, trainingId, language }) => {
    let subjectHomeMatch = useRouteMatch(`${match.url}/subjects`);
    let subjectMatch = useRouteMatch(`${match.url}/subjects/:subjectId`);
    const subjectId = subjectMatch && Number(subjectMatch.params.subjectId);
    const { trainingStore, store } = useStores();
    const history = useHistory();
    const { t } = useTranslation();
    const [certificateModal, setCertificateModal] = React.useState(false);
    const [popoverOpen, setPopoverOpen] = React.useState(false);
    const [popoverOpen2, setPopoverOpen2] = React.useState(false);
    const [popoverOpen3, setPopoverOpen3] = React.useState(false);

    React.useEffect(() => {
        loadData();
    }, [trainingId, language]);

    const toggleHelp = (item) => {
        if (item === 'awarenessScore') {
            setPopoverOpen(!popoverOpen);
        }
        if (item === 'trainingTimeline') {
            setPopoverOpen2(!popoverOpen2);
        }
        if (item === 'mobileHelp') {
            setPopoverOpen3(!popoverOpen3);
        }
    };

    const loadData = async () => {
        await trainingStore.loadTraining(trainingId);
        if (!trainingStore.training) {
            let trainings = await trainingStore.loadTrainings();
            if (trainings && subjectId) {
                let existing = trainings.find((x) =>
                    x.subjectsSentIds.includes(subjectId)
                );
                if (existing)
                    return history.push(`${match.url}/subjects/${subjectId}/`);
            }
            history.push('/trainings');
        }
    };

    const getCertificate = async () => {
        setCertificateModal(true);
    };

    const closeCertificateModal = async () => {
        setCertificateModal(false);
    };

    const sendCertificate = (name, updateName) => {
        return store.sendCertificate(companyId, training.id, name, updateName);
    };

    const {
        subjectsTODO,
        subjectsDone,
        training,
        loadingData,
        loadingTrainingId,
    } = trainingStore;
    const certificateSending = store.certificateSending;

    if (loadingData || loadingTrainingId || !training) {
        return (
            <div className="d-flex flex-column justify-content-center">
                <br />
                <br />
                <h1 className="spinner">{t('One moment')}</h1>
                <Spinner />
            </div>
        );
    }

    let score =
        training.subjectsSent > 0
            ? (100 * (training.viewed || 0)) / training.subjectsSent
            : 0;
    let sent = (100 * (training.subjectsSent || 0)) / training.totalSubjects;
    let training_status_label =
        score !== 0
            ? score >= 100
                ? t('Completed')
                : score < 100
                ? t('In progress')
                : ''
            : t('Not started');

    return (
        <Fragment>
            <div className="dashboard">
                {subjectHomeMatch ? (
                    <SubjectsHome
                        parentUrl={'/trainings/' + trainingId}
                        training={training}
                        subjectId={Number(subjectId)}
                    />
                ) : (
                    <div className="container ">
                        <Helmet>
                            <title>{t('Training program')}</title>
                        </Helmet>
                        <div className="training-header">
                            <Link to={`/trainings/`}>
                                <div className={'navigation back-button'}>
                                    {t('Back to training')}
                                </div>
                            </Link>
                            <h1>{t('Training details')}</h1>
                            <div className="info">
                                <span
                                    className="help-questionmark"
                                    id={'Popover3'}
                                    onClick={() => {
                                        toggleHelp('mobileHelp');
                                    }}
                                ></span>
                            </div>

                            <Popover
                                placement="bottom"
                                isOpen={popoverOpen3}
                                target={'Popover3'}
                                toggle={(e) => {
                                    toggleHelp('mobileHelp');
                                }}
                            >
                                <PopoverBody>
                                    {t(
                                        'Scores improve once you watch the assigned training videos and successfully complete the training quizzes.'
                                    )}
                                </PopoverBody>
                            </Popover>
                        </div>
                        <Row>
                            <Col md="8">
                                <div className="block subjects">
                                    <div className={'section-header'}>
                                        <div className={'left padded'}>
                                            <h3>{t('Training program')}</h3>
                                            <p>
                                                {training.viewed}/
                                                {training.subjectsSent}{' '}
                                                {t('videos watched')}
                                            </p>
                                        </div>
                                        {subjectsTODO.length > 0 && (
                                            <div className="btns-right-align mr20 start-training">
                                                <Link
                                                    to={`/trainings/${trainingId}/subjects/`}
                                                >
                                                    <ThemedButton primary>
                                                        {subjectsDone.length > 0
                                                            ? t(
                                                                  'Resume training'
                                                              )
                                                            : t(
                                                                  'Start training'
                                                              )}
                                                    </ThemedButton>
                                                </Link>
                                            </div>
                                        )}
                                    </div>
                                    {subjectsTODO.length > 0 ? (
                                        <Subjects
                                            match={match}
                                            subjects={subjectsTODO}
                                        />
                                    ) : (
                                        <div className="completed-banner d-flex flex-column align-items-center justify-content-center">
                                            {training.nextSubjectDate ? (
                                                <Fragment>
                                                    <div className="block text-center">
                                                        <ThemedLessonsCompleted />
                                                        <span>
                                                            <strong>
                                                                {t(
                                                                    'All Assigned lessons'
                                                                )}
                                                                &nbsp;
                                                                <ThemedText>
                                                                    {t(
                                                                        'completed'
                                                                    )}
                                                                </ThemedText>
                                                            </strong>
                                                        </span>
                                                        <br />
                                                        <span className="details">
                                                            {t(
                                                                'Great job! Next video will be available on'
                                                            )}{' '}
                                                            {formatDate(
                                                                training.nextSubjectDate
                                                            )}
                                                        </span>
                                                    </div>
                                                </Fragment>
                                            ) : (
                                                <Fragment>
                                                    <ThemedTrainingCompleted />
                                                    <h3>
                                                        {t('Training')}{' '}
                                                        <ThemedText>
                                                            {t(
                                                                'completed successfully'
                                                            )}
                                                        </ThemedText>
                                                    </h3>
                                                    <p
                                                        className="details text-center"
                                                        style={{
                                                            maxWidth: 470,
                                                            fontSize: '14px',
                                                            lineHeight: '1.5em',
                                                        }}
                                                    >
                                                        {t(
                                                            "Great job! We'll notify you by email when a new training gets assigned to you."
                                                        )}
                                                    </p>
                                                    <div>
                                                        <br />
                                                        <ThemedButton
                                                            primary
                                                            onClick={
                                                                getCertificate
                                                            }
                                                        >
                                                            {t(
                                                                'Download Certificate'
                                                            )}
                                                        </ThemedButton>

                                                        <Modal
                                                            className="Modal certificateModal"
                                                            isOpen={
                                                                certificateModal
                                                            }
                                                            onRequestClose={
                                                                closeCertificateModal
                                                            }
                                                        >
                                                            <SendCertificateForm
                                                                certificateSending={
                                                                    certificateSending
                                                                }
                                                                onCancel={
                                                                    closeCertificateModal
                                                                }
                                                                onSend={
                                                                    sendCertificate
                                                                }
                                                                t={t}
                                                            />
                                                        </Modal>
                                                    </div>
                                                </Fragment>
                                            )}
                                        </div>
                                    )}
                                    {training.assessment && (
                                        <Assessments
                                            assessments={[training.assessment]}
                                            match={match}
                                            inactive="false"
                                        />
                                    )}
                                    {subjectsDone.length > 0 && (
                                        <Fragment>
                                            <h3 className="mt20 ">
                                                {t('Completed lessons')}
                                            </h3>
                                            <Subjects
                                                subjects={subjectsDone}
                                                match={match}
                                                inactive="true"
                                            />
                                        </Fragment>
                                    )}
                                </div>
                            </Col>
                            <Col md="4">
                                <div className=" d-flex flex-column">
                                    <div className="block">
                                        <h3>
                                            {t('Your Awareness Score')}{' '}
                                            <span
                                                className="help-questionmark"
                                                id={'Popover1'}
                                                onClick={() => {
                                                    toggleHelp(
                                                        'awarenessScore'
                                                    );
                                                }}
                                            ></span>
                                        </h3>

                                        <Popover
                                            placement="bottom"
                                            isOpen={popoverOpen}
                                            target={'Popover1'}
                                            toggle={(e) => {
                                                toggleHelp('awarenessScore');
                                            }}
                                        >
                                            <PopoverBody>
                                                {t(
                                                    'Improve your awareness score by making sure you’ve watched all the assigned training videos and successfully completed the training quizzes.'
                                                )}
                                            </PopoverBody>
                                        </Popover>
                                        <Progress
                                            color={percentToColor(score)}
                                            value={score}
                                        />
                                        <p className="mt15">
                                            {training.viewed}/
                                            {training.subjectsSent}{' '}
                                            {training_status_label}
                                        </p>
                                        {/* TODO: fix and use 0/100 Not started  and company average       <p >Company average 80/100</p>    */}
                                    </div>
                                    <div className="block">
                                        <h3>
                                            {t('Training timeline')}{' '}
                                            <span
                                                className="help-questionmark"
                                                id={'Popover2'}
                                                onClick={() => {
                                                    toggleHelp(
                                                        'trainingTimeline'
                                                    );
                                                }}
                                            ></span>
                                        </h3>

                                        <Popover
                                            placement="bottom"
                                            isOpen={popoverOpen2}
                                            target={'Popover2'}
                                            toggle={(e) => {
                                                toggleHelp('trainingTimeline');
                                            }}
                                        >
                                            <PopoverBody>
                                                {t(
                                                    'Your assigned lessons are delivered and made available over a period of time. The training timeline shows you how much of the scheduled training course has been delivered.'
                                                )}
                                            </PopoverBody>
                                        </Popover>

                                        <ThemedProgress value={sent} />

                                        {training.nextSubjectDate ? (
                                            <Fragment>
                                                {training.scheduleType === 1 ? (
                                                    <Fragment>
                                                        {t(
                                                            'All lessons delivered.'
                                                        )}
                                                    </Fragment>
                                                ) : (
                                                    <Fragment>
                                                        <p className="mt15">
                                                            {
                                                                training.subjectsSent
                                                            }
                                                            /
                                                            {
                                                                training.totalSubjects
                                                            }{' '}
                                                            {t(
                                                                'lessons delivered.'
                                                            )}
                                                        </p>
                                                        <p>
                                                            {' '}
                                                            {t('Next')}{' '}
                                                            {training.nextSubjectsToSend >
                                                                1 &&
                                                                training.nextSubjectsToSend}{' '}
                                                            {pluralize(
                                                                t('lesson'),
                                                                training.nextSubjectsToSend
                                                            )}{' '}
                                                            {t(
                                                                'will be delivered on'
                                                            )}{' '}
                                                            {formatDate(
                                                                training.nextSubjectDate
                                                            )}
                                                        </p>
                                                    </Fragment>
                                                )}
                                            </Fragment>
                                        ) : (
                                            <p className="mt15">
                                                {t('All delivered')}
                                            </p>
                                        )}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                )}
            </div>
        </Fragment>
    );
});

//export default withRouter(TrainingView)
