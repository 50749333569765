import { action, observable } from 'mobx';

class TestStore {
    @observable logLines = observable([]);

    @action addToLog(message, data) {
        this.logLines.push(message + (data ? JSON.stringify(data) : ''));
    }
}

export default TestStore;
