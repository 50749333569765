import { createTheme } from '@material-ui/core/styles';

export const AwareGOTheme = createTheme({
    palette: {
        primary: { main: '#20A26B' },
        secondary: { main: '#ffffff' },
    },
    overrides: {
        MuiSlider: {
            thumb: {
                color: '#20A26B',
                width: 22,
                height: 22,
                boxShadow: '0 0 0 3px white',
                borderColor: '#ffffff',
                borderWidth: 4,
            },
            track: {
                height: 8,
                color: '#20A26B',
            },
            rail: {
                color: '#afafaf',
                height: 8,
                opacity: 0.4,
            },
        },
    },
    breakpoints: {
        values: {
            md: 992,
        },
    },
});
