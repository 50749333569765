import React from 'react';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

@withTranslation()
class Waiting extends React.Component {
    render() {
        const { t } = this.props;
        if (!this.props.waitingActive) {
            return null;
        } else {
            if (!this.props.fullScreen) {
                return (
                    <Row>
                        <Col md="12" xs="12" className="content">
                            <div className="loading">
                                <div className="loading__dots">
                                    <div className="loading__dots__dot"></div>
                                    <div className="loading__dots__dot"></div>
                                    <div className="loading__dots__dot"></div>
                                </div>
                                <div className="loading__msg">
                                    {t(
                                        'One moment. We are doing some stuff...'
                                    )}
                                </div>
                            </div>
                        </Col>
                    </Row>
                );
            } else {
                return (
                    <div className="loading-fullscreen">
                        <div className="loading">
                            <div className="loading__dots">
                                <div className="loading__dots__dot"></div>
                                <div className="loading__dots__dot"></div>
                                <div className="loading__dots__dot"></div>
                            </div>
                            <div className="loading__msg">
                                {t('One moment. We are doing some stuff...')}
                            </div>
                        </div>
                    </div>
                );
            }
        }
    }
}

Waiting.propTypes = {
    waitingActive: PropTypes.bool,
    fullScreen: PropTypes.bool,
};

Waiting.defaultProps = {
    waitingActive: false,
    fullScreen: true,
};

export default Waiting;
