/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Progress, Popover, PopoverBody } from 'reactstrap';
import {
    formatTime,
    subjectProgress,
    percentToColor,
} from '../../utils/helpers';

export default observer(({ match, subject }) => {
    const { t } = useTranslation();
    const [popoverOpen, setPopoverOpen] = useState(false);

    const toggleLanguageInfo = (e) => {
        e.preventDefault();
        console.log('In toggle toggleLanguageInfo with item: ', e);
        setPopoverOpen(!popoverOpen);
    };

    return (
        <div className="flex-fill listItem">
            {subject.isSubstitute && (
                <>
                    <div
                        className="help-language"
                        id={'lang-info-' + subject.id}
                    ></div>

                    <Popover
                        placement="bottom"
                        trigger={'hover'}
                        isOpen={popoverOpen}
                        target={'lang-info-' + subject.id}
                        toggle={(e) => {
                            toggleLanguageInfo(e);
                        }}
                    >
                        <PopoverBody>
                            {t('Not available in current language')}
                        </PopoverBody>
                    </Popover>
                </>
            )}
            <Row>
                <Col md="4" xs="12">
                    <Link to={`${match.url}/subjects/${subject.id}`}>
                        <div
                            className="img"
                            style={{
                                backgroundImage: 'url(' + subject.thumb + ')',
                            }}
                        />
                    </Link>
                </Col>
                <Col md="8" xs="12">
                    <Link
                        className="subject-list-link"
                        to={`${match.url}/subjects/${subject.id}`}
                    >
                        <h3>{subject.title}</h3>
                        <div className="subject-list-description">
                            <p>{subject.description}</p>
                        </div>
                        <Row className="progress-row">
                            {subject.duration !== null && subject.type !== 3 && (
                                <Col md="2" xs="3">
                                    <span>
                                        {formatTime(subject.duration)}{' '}
                                        {t('min')}
                                    </span>
                                </Col>
                            )}
                            {subject.questions_count !== 0 &&
                                subject.type === 3 && (
                                    <Col md="2" xs="3">
                                        <span>
                                            {subject.questions_count}{' '}
                                            {t('questions')}
                                        </span>
                                    </Col>
                                )}
                            <Col md="8" xs="7">
                                <Progress
                                    className="progress progress-bar-slim flex-fill"
                                    color={percentToColor(
                                        subjectProgress(subject)
                                    )}
                                    value={subjectProgress(subject)}
                                />
                            </Col>
                            <Col md="2" xs="2">
                                {subjectProgress(subject)}%
                            </Col>
                        </Row>
                    </Link>
                </Col>
            </Row>
        </div>
    );
});
