import _ from 'lodash';
import { action, observable, computed, reaction } from 'mobx';
import services from '../services';

class TrainingsStore {
    @observable loadingData = false;
    @observable loadingTrainingId = null;
    @observable error = null;
    @observable trainings = [];
    @observable training = null;
    // @observable subjects = [];
    @observable statusUpdate = 0;

    constructor(authStore, companyStore, mainStore) {
        this.authStore = authStore;
        this.companyStore = companyStore;
        this.mainStore = mainStore;

        reaction(
            () => this.companyStore.currentCompany,
            (company) => {
                if (company.isTrainingEnabled) this.loadTrainings();
            }
        );

        reaction(
            () => this.statusUpdate,
            () => {
                this.reloadTraining();
            }
        );
    }

    @action logout = () => {
        this.trainings.replace([]);
        this.loadingData = false;
    };

    @action setError = (error) => {
        this.error = error;
    };

    @action
    async reloadTraining() {
        this.training && this.loadTraining(this.training.id, true);
    }

    @action
    async loadTraining(id, background) {
        if (!background) {
            if (this.loadingTrainingId === id) return;
            this.loadingTrainingId = id;
        }
        this.setError(null);
        try {
            let data = await services.Trainings.myTraining(id, {
                l: this.mainStore.language,
            });
            this.training = data;
            //this.subjects.replace(data.subjects);
            this.mainStore.setLayoutTitle(data.name);
        } catch (e) {
            this.setError(
                (e.response && e.response.data && e.response.data.error) ||
                    e.message
            );
        } finally {
            this.loadingTrainingId = null;
        }
    }

    @action
    async loadTrainings() {
        if (!this.companyStore.currentCompany) return null;
        if (this.loadingData) {
            return this.loadingData;
        }
        this.setError(null);

        this.loadingData = (async () => {
            try {
                let data = await services.Trainings.myTrainings(
                    this.contextParams
                );
                this.trainings.replace(data);
                return this.trainings;
            } catch (e) {
                this.setError(
                    (e.response && e.response.data && e.response.data.error) ||
                        e.message
                );
            } finally {
                this.loadingData = null;
            }
        })();
        return this.loadingData;
    }

    @action
    onLogEvent = (trainingId, subject, event_id, context, options) => {
        options = Object.assign({}, options, { send: true });
        if (typeof subject === 'string') subject = Number(subject);
        if (typeof subject === 'number')
            subject = this.training.subjects.find((x) => x.id === subject);

        if (subject) this.updateStatus(event_id, subject);
    };

    @action updateStatus = (event_id, subject) => {
        if (subject.status) {
            switch (event_id) {
                case 1:
                    subject.status.LOGIN = 1;
                    break;
                case 2:
                    subject.status.LOGOUT = 1;
                    break;
                case 3:
                    subject.status.SUBJECT_OPEN = 1;
                    break;
                case 4:
                    subject.status.SUBJECT_CLOSE = 1;
                    break;
                case 5:
                    let prevValue_ANSWARE_CORRECT = subject.status.ANSWARE_CORRECT;
                    subject.status.ANSWARE_CORRECT = 1;
                    if ( !prevValue_ANSWARE_CORRECT )
                        this.statusUpdate++;
                    break;
                case 6:
                    let prevValue_ANSWARE_INCORRECT = subject.status.ANSWARE_INCORRECT;
                    subject.status.ANSWARE_INCORRECT = 1;
                    if ( !prevValue_ANSWARE_INCORRECT )
                        this.statusUpdate++;
                    break;
                case 7:
                    subject.status.VIDEO_START = 1;
                    break;
                case 8:
                    subject.status.VIDEO_25 = 1;
                    break;
                case 9:
                    subject.status.VIDEO_50 = 1;
                    break;
                case 10:
                    subject.status.VIDEO_75 = 1;
                    break;
                case 11:
                    let prevValue_VIDEO_95 = subject.status.VIDEO_95
                    subject.status.VIDEO_95 = 1;
                    if ( !prevValue_VIDEO_95 )
                        this.statusUpdate++;
                    break;
                case 12:
                    let prevValue_VIDEO_100 = subject.status.VIDEO_100
                    subject.status.VIDEO_100 = 1;
                    if ( !prevValue_VIDEO_100 )
                        this.statusUpdate++;
                    break;
                default:
                    break;
            }
        }
    };

    isSubjectDone(subject) {
        return (
            subject.status.ANSWARE_CORRECT > 0 &&
            (subject.status.VIDEO_95 > 0 || subject.status.VIDEO_100 > 0)
        );
    }

    isSubjectPassed(subject) {
        return (
            subject.status.ANSWARE_CORRECT > 0 &&
            (subject.status.VIDEO_95 > 0 ||
                subject.status.VIDEO_100 > 0 ||
                subject.type === 3)
        );
    }

    isSubjectDelivered(subject) {
        return subject.sent > 0;
    }

    @computed get subjectsTODO() {
        return this.subjects.filter(
            (x) => this.isSubjectDelivered(x) && !this.isSubjectDone(x)
        );
    }

    @computed get deliveredSubjects() {
        return this.subjects.filter((x) => this.isSubjectDelivered(x));
    }

    @computed get availableUnfinishedSubjects() {
        return this.subjects.filter(
            (x) => this.isSubjectDelivered(x) && !this.isSubjectPassed(x)
        );
    }

    @computed get subjectsDone() {
        return this.subjects.filter(
            (x) => this.isSubjectDelivered(x) && this.isSubjectDone(x)
        );
    }

    @computed get subjects() {
        return this.training && this.training.subjects
            ? this.training.subjects
            : [];
    }

    @computed get certificateQualified() {
        return (
            _.every(this.subjects, this.isSubjectPassed) &&
            (!this.training.assessment_id ||
                (this.training &&
                    this.training.assessment &&
                    this.training.assessment.status &&
                    this.training.assessment.status.finished))
        );
    }

    @computed get assessmentAvailable() {
        return (
            _.every(this.subjects, this.isSubjectPassed) &&
            this.training.assessment_id
        );
    }

    @computed get contextParams() {
        let params = {};
        if (this.companyStore.currentCompany)
            params.o = this.companyStore.currentCompany.company_id;

        if (this.mainStore.language) params.l = this.mainStore.language;

        return params;
    }
}

export default TrainingsStore;
