import React, { useEffect } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { observer } from 'mobx-react';
import AssessmentsView from './AssessmentsView';
import { useStores } from '../../hooks/use-stores';
import { querystring } from '../../utils/helpers';
import AssessmentsAnswerView from './AssessmentsAnswerView';
import AssessmentsList from './AssessmentsList';
import HomeViewContainer from '../Home/HomeViewContainer';

export default observer(({ companyId }) => {
    const { store } = useStores();
    const match = useRouteMatch();

    useEffect(() => {
        store.resetContext();
    }, [companyId]);

    const language = store.language;

    return (
        <Switch>
            <Route
                path={`${match.url}`}
                exact
                render={() => (
                    <HomeViewContainer
                        layoutTitle="Awareness and Compliance Assessment"
                        code={'assessments'}
                        pageTitle={'assessments'}
                        content={<AssessmentsList />}
                    />
                )}
            />

            <Route
                path={`${match.url}/:id/answers`}
                exact
                render={(props) => {
                    let questionIndex = querystring('index');
                    return (
                        <AssessmentsAnswerView
                            companyId={companyId}
                            assessmentId={props.match.params.id}
                            parentUrl={match.url}
                            index={questionIndex}
                        />
                    );
                }}
            />

            <Route
                path={`${match.url}/:id/answers`}
                render={(props) => (
                    <AssessmentsView
                        match={props.match}
                        parentUrl={match.url}
                        companyId={companyId}
                        assessmentId={props.match.params.id}
                        language={language}
                    />
                )}
            />

            <Route
                path={`${match.url}/:id`}
                render={(props) => (
                    <AssessmentsView
                        match={props.match}
                        parentUrl={match.url}
                        companyId={companyId}
                        assessmentId={props.match.params.id}
                        language={language}
                    />
                )}
            />
        </Switch>
    );
});
