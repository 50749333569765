import React, { useState, Fragment, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import Spinner from '../../components/Spinner';
import { formatDate, formatDateAndTime } from '../../utils/helpers';
import { ThemedButton } from '../../components/themed/ThemedComponents';
import { useStores } from '../../hooks/use-stores';
import SemiCircleProgress from '../../components/semi-circle-progress';
import Modal from 'react-modal';
import './AssessmentsList.scss';
import EmptyItem from '../Home/EmptyItem';

export default observer(() => {
    const { t } = useTranslation();
    const { assessmentStore, store } = useStores();
    const { loadingData, assessments } = assessmentStore;

    const [expiredModal, setExpiredModal] = useState(false);
    const [expiredDue, setExpiredDue] = useState(null);
    let history = useHistory();

    useEffect(() => {
        store.setLayoutTitle(t('Awareness and Compliance Assessment'));
    }, [store, t]);

    const openExpiredModal = (due) => {
        setExpiredModal(true);
        setExpiredDue(due);
    };

    const hideExpiredModal = () => {
        setExpiredModal(false);
        setExpiredDue(null);
    };

    return (
        <Fragment>
            {loadingData ? (
                <div className="d-flex flex-column justify-content-center ">
                    <br />
                    <br />
                    <h1 className="spinner">{t('One moment')}</h1>
                    <Spinner />
                </div>
            ) : (
                <Fragment>
                    {assessments.length > 0 ? (
                        assessments.reverse().map((a) => (
                            <div
                                className="assessment-list"
                                key={a.id}
                                onClick={() =>
                                    a.expired && !a.status.started
                                        ? openExpiredModal(a.due)
                                        : history.push(`/assessments/${a.id}`)
                                }
                            >
                                <div className="assessment-item">
                                    <Row>
                                        <Col xs="12" md="2">
                                            <div className="image">
                                                {/* TODO: Started is always false - needs to be checked */}
                                                {!a.status.finished && (
                                                    <img
                                                        src="/static/img/icon-assessment-new.svg"
                                                        alt={t(
                                                            'New assessment'
                                                        )}
                                                    />
                                                )}
                                                {a.status.started &&
                                                    (a.status.finished ||
                                                        a.completed) && (
                                                        <img
                                                            src="/static/img/icon-assessment-done.svg"
                                                            alt={t(
                                                                'Finished assessment'
                                                            )}
                                                        />
                                                    )}
                                                {a.expired &&
                                                    !a.status.started && (
                                                        <img
                                                            src="/static/img/icon-assessment-expired.svg"
                                                            alt={t(
                                                                'Expired assessment'
                                                            )}
                                                        />
                                                    )}
                                            </div>
                                        </Col>
                                        <Col xs="12" md="7">
                                            <div className="description">
                                                <h3>{a.name}</h3>
                                                <p>
                                                    {a.status &&
                                                        !a.status.finished &&
                                                        a.due && (
                                                            <>
                                                                <i className="date-icon due"></i>
                                                                <span className="green-text">
                                                                    {t(
                                                                        'Due on'
                                                                    )}{' '}
                                                                    {formatDateAndTime(
                                                                        a.due
                                                                    )}
                                                                </span>
                                                            </>
                                                        )}
                                                    {a.status.started &&
                                                        (a.status.finished ||
                                                            a.completed) && (
                                                            <>
                                                                <i className="date-icon done"></i>
                                                                {t(
                                                                    'Completed on'
                                                                )}{' '}
                                                                {formatDate(
                                                                    a.end ||
                                                                        a.completed
                                                                )}
                                                            </>
                                                        )}
                                                    {a.expired &&
                                                        !a.status.started && (
                                                            <>
                                                                <i className="date-icon expired"></i>
                                                                {t(
                                                                    'This assessment has expired on'
                                                                )}{' '}
                                                                {formatDateAndTime(
                                                                    a.due
                                                                )}
                                                                .
                                                            </>
                                                        )}
                                                </p>
                                            </div>
                                        </Col>
                                        <Col xs="12" md="3">
                                            {a.status && !a.status.finished && (
                                                <div className="action">
                                                    <ThemedButton primary>
                                                        {a.status.started
                                                            ? t('Continue')
                                                            : t('Start')}
                                                    </ThemedButton>
                                                </div>
                                            )}
                                            {a.status.started &&
                                                (a.status.finished ||
                                                    a.completed) && (
                                                    <div className="score score-and-results">
                                                        <div className="progress-semicircle no-margin-auto small">
                                                            <SemiCircleProgress
                                                                percentage={Math.max(
                                                                    0,
                                                                    Math.round(
                                                                        (a.score /
                                                                            a.max_score) *
                                                                            100
                                                                    )
                                                                )}
                                                                size="small"
                                                            />
                                                        </div>
                                                        <div className="action">
                                                            <span>
                                                                {t(
                                                                    'View results'
                                                                )}
                                                            </span>
                                                        </div>
                                                    </div>
                                                )}
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        ))
                    ) : (
                        <EmptyItem item={'assessment'} />
                    )}
                    <Modal
                        isOpen={expiredModal}
                        onRequestClose={hideExpiredModal}
                        className="modal slim"
                    >
                        <div className={'modalHeader'}>
                            <h3>{t('This assessment has expired.')}</h3>
                            <img
                                className={'modal-close-right'}
                                src="/static/img/close.svg"
                                alt={t('Close')}
                                onClick={hideExpiredModal}
                            />
                        </div>
                        <div className={'modalContent'}>
                            {t('expired_due_date', {
                                due: formatDateAndTime(expiredDue),
                            })}
                        </div>
                        <div className={'modalFooter'}>
                            <ThemedButton primary onClick={hideExpiredModal}>
                                {t('Close')}
                            </ThemedButton>
                        </div>
                    </Modal>
                </Fragment>
            )}
        </Fragment>
    );
});
