import RestService from './RestService';
import Subjects from './SubjectsService';
import Certificates from './CertificatesService';
import Questions from './QuestionsService';
import Branding from './BrandingService';

export default class Companies extends RestService {
    constructor() {
        super('/companies');
    }

    myCompanies() {
        return this.get(`${this.baseUrl}/my_companies?as_user=1`);
    }

    subjectsService(companyId) {
        return new Subjects(this, companyId);
    }

    questionsService(companyId) {
        return new Questions(this, companyId);
    }

    certificateService(companyId) {
        return new Certificates(this, companyId);
    }

    BrandingService(companyId) {
        return new Branding(this, companyId);
    }
}
