import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import React from 'react';
import {
    Nav,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    NavItem,
    NavLink,
    Button,
} from 'reactstrap';
import classes from 'classnames';

@withTranslation()
@inject('store', 'authStore', 'companyStore', 'brandingStore', 'commonStore')
@observer
class UserMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userMenuVisible: false,
            languageMenuVisible: false,
            statusMenuVisible: false,
        };
    }

    playSubjectFinishedAnimation() {
        this.props.store.setShowSubjectDoneAnimation(true);
    }

    toggleUserMenu() {
        const currentState = this.state.userMenuVisible;
        this.setState({
            userMenuVisible: !currentState,
            languageMenuVisible: false,
            statusMenuVisible: false,
        });
    }

    toggleLanguageMenu() {
        const currentState = this.state.languageMenuVisible;
        this.setState({
            languageMenuVisible: !currentState,
            userMenuVisible: false,
            statusMenuVisible: false,
        });
    }

    toggleStatusMenu() {
        const currentState = this.state.statusMenuVisible;
        this.setState({
            statusMenuVisible: !currentState,
            languageMenuVisible: false,
            userMenuVisible: false,
        });
    }

    onChangeCompany(company_id) {
        this.props.companyStore.setCurrentCompany(company_id);
        this.toggleUserMenu();
    }

    changeLanguage(language_two_letter_code) {
        if (this && this.props && this.props.store) {
            if (this.props.switchLanguage) {
                this.props.switchLanguage(language_two_letter_code);
            }
            this.toggleLanguageMenu();
        }
    }

    toggleUserMenu = () => {
        const currentState = this.state.userMenuVisible;
        this.setState({ userMenuVisible: !currentState });
    };

    getInitials(name) {
        if (!name) {
            return '';
        }
        return name.split(' ').map((n) => n[0]);
    }

    render() {
        const { store, t } = this.props;
        const { companies, currentCompany } = this.props.companyStore;
        const { isAuthenticated, currentUser } = this.props.authStore;
        const { effectiveLanguages: languages } = this.props.commonStore;
        const { lmsDomain } = this.props.brandingStore;
        //const currentCompanyProgress = store ? store.currentCompanyProgress : null;
        const language = store.language;
        const currentUserInitials = currentUser
            ? this.getInitials(currentUser.name)
            : '';
        const feedbackEnabled =
            currentCompany &&
            currentCompany.settings['ui.feedback_enabled'] === '1';

        if (isAuthenticated) {
            return (
                <Nav>
                    <NavItem className="dropdown">
                        <Dropdown
                            className="header-dropdown header-user"
                            isOpen={this.state.userMenuVisible}
                            toggle={this.toggleUserMenu}
                        >
                            <DropdownToggle
                                tag="div"
                                className="header-name"
                                onClick={this.toggleUserMenu}
                            >
                                {currentUser.name || currentUser.email}
                                <div className="company">
                                    {currentCompany &&
                                        currentCompany.company_name}
                                </div>
                                <span>{currentUserInitials}</span>
                            </DropdownToggle>

                            <DropdownMenu
                                tag={'ul'}
                                className={'nav-dropdown company-list'}
                            >
                                <NavItem>
                                    {this.props.store &&
                                        this.props.store.company && (
                                            <div className="header-subtext">
                                                {
                                                    this.props.store.company
                                                        .company_name
                                                }
                                            </div>
                                        )}
                                </NavItem>
                                {companies.map((comp, i) => (
                                    <NavItem
                                        key={comp.company_id}
                                        className={classes({
                                            selected:
                                                currentCompany &&
                                                currentCompany.company_id &&
                                                currentCompany.company_id ===
                                                    comp.company_id,
                                        })}
                                        onClick={(e) =>
                                            this.onChangeCompany(
                                                comp.company_id
                                            )
                                        }
                                    >
                                        {comp.company_name}
                                    </NavItem>
                                ))}
                                <hr />

                                <div className="language">
                                    {languages.map((x) => {
                                        return (
                                            <NavLink
                                                key={x.code}
                                                className={
                                                    language === x.code
                                                        ? 'link selected'
                                                        : 'link'
                                                }
                                                onClick={() =>
                                                    this.changeLanguage(x.code)
                                                }
                                            >
                                                <img
                                                    src={x.flag_image}
                                                    alt={t('Choose language')}
                                                />
                                                {x.name}
                                            </NavLink>
                                        );
                                    })}
                                </div>

                                <hr />

                                {this.props.store &&
                                    this.props.companyStore.haveAdminRights && (
                                        <>
                                            <NavItem>
                                                <a href={lmsDomain}>
                                                    {t('Training manager')}
                                                </a>
                                            </NavItem>
                                            <hr />
                                        </>
                                    )}
                                {feedbackEnabled && (
                                    <>
                                        <Button
                                            type="submit"
                                            className="nav-link"
                                            color="secondary"
                                            onClick={this.props.sendFeedback}
                                        >
                                            {t('Send Feedback')}
                                        </Button>
                                        <hr />
                                    </>
                                )}

                                <Button
                                    type="submit"
                                    className="nav-link"
                                    color="secondary"
                                    onClick={this.props.handleSignout}
                                >
                                    {t('Sign out')}
                                </Button>
                            </DropdownMenu>
                        </Dropdown>
                    </NavItem>

                    {this.props.store && (
                        <div
                            className={
                                'done-animation ' +
                                (this.props.store.showSubjectDoneAnimation
                                    ? 'play'
                                    : '')
                            }
                        >
                            <img
                                src="/static/img/icon-medal.png"
                                alt={t('Completed subjects')}
                            />
                            <div className="title">{t('Great!')} </div>
                            <div className="text">{t('Subject completed')}</div>
                        </div>
                    )}
                </Nav>
            );
        } else {
            return (
                <Nav>
                    <NavItem>
                        {/* @TODO Add support for passing current URL path as redirect URL so that users without JavaScript are also
          redirected to the page they were on before they signed in. */}
                        {/*<a href="/auth/signin" className="btn btn-primary">Sign in</a>*/}
                    </NavItem>
                </Nav>
            );
        }
    }
}

export default UserMenu;
