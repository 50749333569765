import React from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Container } from 'reactstrap';

export default observer(() => {
    const { t } = useTranslation();
    return (
        <div style={{ margin: '2em 0' }} className="text-center">
            <Container>
                <div className="block email-sent">
                    <div className="forms">
                        <div className="form-header">
                            <h2 className="green">{t('Check your email')}</h2>
                            <div className="hide-mobile">
                                <img
                                    src="/static/img/icon-email-stars.gif"
                                    alt={t('Email sent')}
                                />
                            </div>
                            <div className="hide-desktop">
                                <img
                                    src="/static/img/icon-email-stars.svg"
                                    alt={'Email sent'}
                                />
                            </div>

                            <br />
                            <p>
                                {t(
                                    'You have been sent an email with a link you can use to sign in.'
                                )}{' '}
                                <br />
                            </p>
                            <br />
                            {/* TODO: Waiting for translation: Add this - delete the line below <p>{t('Not receiving our email? It might have landed in your spam folder.')} <a href="/auth/signin/"><u><span className="green"><Link to={`/`}> {t('Request a new sign in link.')}</Link></span></u></a></p>*/}
                            <p>
                                <a href="/auth/signin/">
                                    <u>
                                        <span className="green">
                                            <Link to={`/`}>
                                                {' '}
                                                {t(
                                                    'Request a new sign in link.'
                                                )}
                                            </Link>
                                        </span>
                                    </u>
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
});
