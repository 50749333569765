import React, {Fragment, useEffect, useMemo} from 'react';
import {observer} from 'mobx-react';
import {useStores} from "../hooks/use-stores";

const CONSTANTS = require('../utils/constants');
const errorString = {
    "0": "No error",
    "101": "General exception",
    "201": "Invalid argument error",
    "202": "Element cannot have children",
    "203": "Element not an array - cannot have count",
    "301": "Not initialized",
    "401": "Not implemented error",
    "402": "Invalid set value, element is a keyword",
    "403": "Element is read only",
    "404": "Element is write only",
    "405": "Incorrect data type",
}


class Scorm2004API {
    constructor() {
        this.cmi = {}

        this.onCommmitEvent = document.createEvent("Event");
        this.onCommmitEvent.initEvent("onSCORMCommit", true, true);

        this.onFinishEvent = document.createEvent("Event");
        this.onFinishEvent.initEvent("onSCORMFinish", true, true);


        this.onSetValueEvent = document.createEvent("Event");
        this.onSetValueEvent.initEvent("onSCORMSetValue", true, true);

        this.errorCode = '0';
    }


    Initialize() {
        // debugger
        console.log("LMSInitialize", arguments)
        this.errorCode = '0';
        return 'true';
    }

    Terminate() {
        console.log("LMSFinish", arguments)
        this.errorCode = '0';
        document.dispatchEvent(this.onFinishEvent);
        return 'true';
    }

    GetValue(element) {
        let value = this.cmi[element]
        console.log("LMSGetValue", element, value)
        return value ? value : '';
    }

    SetValue(element, value) {
        // debugger
        console.log("LMSSetValue", element, value)
        let previousValue = this.cmi[element]
        this.cmi[element] = value;
        if (previousValue !== value)
            document.dispatchEvent(this.onSetValueEvent);
        return "true";
    }

    Commit() {
        console.log("LMSCommit", arguments)
        if (this.cmi["cmi.completion_status"] === "completed")
            document.dispatchEvent(this.onCommmitEvent);
        return "true";
    }

    GetLastError() {
        console.log("LMSGetLastError", arguments)
        return this.errorCode;
    }

    GetErrorString(param) {
        console.log("LMSGetErrorString")
        return param !== '' ? errorString[param] : '';
    }

    GetDiagnostic() {
        console.log("LMSGetDiagnostic", arguments)
        return '';
    }

    init(cmi) {
        console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!init", arguments)
        this.cmi = cmi;
    }


    LMSInitialize() {
        return this.Initialize()
    }

    LMSFinish() {
        return this.Terminate()
    }

    LMSGetValue(element) {
        return this.GetValue(element)
    }

    LMSSetValue(element, value) {
        return this.SetValue(element, value)
    }

    LMSCommit() {
        return this.Commit()
    }

    LMSGetLastError() {
        return this.GetLastError()
    }

    LMSGetErrorString() {
        return this.LMSGetErrorString()
    }

    LMSGetDiagnostic() {
        return this.GetDiagnostic()
    }

}


window.API_1484_11 = new Scorm2004API()
window.API = window.API_1484_11

function VideoPlayerContainer({subject, training, logEvent}) {

    const {authStore, store} = useStores();
    const {currentUser, token} = authStore;
    const {id:subjectId} = subject;
    const {scormIndex} = store

    let scormData =  useMemo(()=>{
        return scormIndex[subjectId]
    }, [scormIndex, subjectId])

    const cmi_key = useMemo(() => {
        return `cmi_${currentUser.id}_${subjectId}`;
    }, [currentUser, subjectId])


    const userProps = useMemo(() => {
        return {
            "cmi.learner_id": currentUser.email
            , "cmi.learner_name": currentUser.name || currentUser.email
        }
    }, [currentUser])


    useEffect(() => {
        function handleEvent() {
            window.localStorage[cmi_key] = JSON.stringify(window.API.cmi)
            if (window.API.cmi["cmi.completion_status"] === "completed") {
                logEvent(null, CONSTANTS.EVENTS.VIDEO_100)
                if (window.API.cmi["cmi.success_status"] === "passed"
                    || window.API.cmi["cmi.core.lesson_status"] === "passed")
                    logEvent(null, CONSTANTS.EVENTS.ANSWARE_CORRECT)
                else
                    logEvent(null, CONSTANTS.EVENTS.ANSWARE_INCORRECT)
            }
        }
        function handleFinish() {
            //window.API.init({})
            //debugger
            //delete window.localStorage[cmi_key]
        }
        console.log("subscribe for cmi events")
        document.addEventListener("onSCORMCommit", handleEvent, false);
        document.addEventListener("onSCORMSetValue", handleEvent, false);
        document.addEventListener("onSCORMFinish", handleFinish, false);
        return () => {
            console.log("unsubscribe out of cmi events")
            document.removeEventListener('onSCORMCommit', handleEvent)
            document.removeEventListener('onSCORMSetValue', handleEvent)
            document.removeEventListener('onSCORMFinish', handleFinish)
        }
    }, [subjectId])

    useEffect(() => {
        if (subject && currentUser) {
            const emptyState = { lesson_id: cmi_key, ...userProps }
            if (window.localStorage[cmi_key]) {
                try {
                    let state = JSON.parse(window.localStorage[cmi_key])
                    if ( state.lesson_id !== cmi_key  || state["cmi.learner_id"] !== currentUser.email  )
                        window.API.init( emptyState );
                    else
                        window.API.init( state );
                } catch (e) {
                    if (authStore.currentUser) {
                        window.API.init(emptyState)
                    }
                }
            } else {
                window.API.init(emptyState)
            }

            window.API.SetValue("cmi.learner_id", currentUser.email)
            window.API.SetValue("cmi.learner_name", currentUser.name || currentUser.email)
            store.scormInfo(subjectId)

        }
    }, [cmi_key, userProps, currentUser, subjectId])

    //
    // const start = () => {
    //     // this.setState({started:true})
    //     logEvent(null, CONSTANTS.EVENTS.VIDEO_START)
    // }

    
    const url = useMemo(() => {
        let actor = {
            "name": [currentUser.name || currentUser.email]
            , "account": [{"accountName": currentUser.email}], "objectType": "Agent"
        };
        let endpoint = `${process.env.REACT_APP_API_URL}/xapi/trainings/${training.id}/subjects/${subjectId}`
        let auth = `JWT ${token}`;
        let width = 800;
        let height = 600;
        return scormData
            && `${scormData.url}?actor=${JSON.stringify(actor)}&endpoint=${endpoint}&auth=${auth}&width=${width}&height=${height}`;
    }, [currentUser, training, subjectId, token, scormData])

    return <div className='scorm-player-container'>
        {scormData &&
        <Fragment>
            <iframe title={"SCORM"} src={url}></iframe>
        </Fragment>
        }
    </div>

}

export default observer(VideoPlayerContainer)