import { action, computed, observable } from 'mobx';
import services from '../services';
import { awareGoTheme } from '../theme';
import { extractErrorMessage, querystring } from '../utils/helpers';

class CompanyStore {
    @observable companyId =
        parseInt(querystring('o', window.location.search)) ||
        parseInt(window.localStorage.getItem('companyId'));
    @observable companies = observable([]);
    @observable loadingCompanies = false;
    @observable noCompanies = false;
    @observable haveAdminRights = false;
    @observable currentCompany = null;
    @observable error = null;

    @action logout = () => {
        this.companies.replace([]);
        this.currentCompany = null;
        this.companyId = null;
        this.haveAdminRights = false;
        this.loadingCompanies = false;
        window.localStorage.removeItem('companyId');
    };

    @action
    async loadCompanies() {
        this.loadingCompanies = true;
        this.error = null;
        this.companies.clear();
        try {
            const result = await services.Companies.myCompanies();
            this.companies.replace(result.companies);
            this.noCompanies = this.companies.length === 0;
            if (this.companies.length > 0)
                this.setCurrentCompany(
                    this.companyId || this.companies[0].company_id
                );
            this.haveAdminRights = result.haveAdminRights;
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.loadingCompanies = false;
        }
    }

    @action
    setCurrentCompany(company_id) {
        company_id = parseInt(company_id);
        let company = this.companies.find((x) => x.company_id === company_id);
        if (!company && this.companies.length > 0) company = this.companies[0];
        if (company) {
            this.currentCompany = company;
            window.localStorage.setItem('companyId', company_id);
        }
    }

    @computed get theme() {
        return {
            primary: this.currentCompany
                ? this.currentCompany.color_main
                    ? this.currentCompany.color_main
                    : awareGoTheme.primary
                : awareGoTheme.primary,
        };
    }
}

export default CompanyStore;
