import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { Helmet } from 'react-helmet';
import '../css/index.scss';
import UserMenu from '../containers/UserMenu';
import { withTranslation } from 'react-i18next';
import { Alert } from 'reactstrap';

@withTranslation()
@inject('store', 'companyStore', 'brandingStore', 'authStore')
@observer
class Layout extends React.Component {
    static propTypes() {
        return {
            session: React.PropTypes.object.isRequired,
            children: React.PropTypes.object.isRequired,
        };
    }

    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            modal: false,
            notification: false,
            notificationType: 'warning',
            notificationMessage: 'All is good',
        };
        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.toggleModal = this.toggleModal.bind(this);

        fetch('https://resources.awarego.com/notification-my.json')
            .then((response) => response.json())
            .then((jsonData) => {
                this.state.notification = jsonData.status;
                this.state.notificationType = jsonData.type;
                this.state.notificationMessage = jsonData.message;
            })
            .catch((error) => {
                console.error(error);
            });
    }

    toggleNavbar() {
        this.setState({
            isOpen: !this.state.isOpen,
        });
    }

    toggleModal(e) {
        if (e) {
            e.preventDefault();
        }

        if (this.state.modal !== true) {
            if (window && window.location) {
                //TODO: redirect URL without cookies
                //Cookies.save('redirect_url', window.location.pathname);
            }
        }
        this.setState({
            modal: !this.state.modal,
        });
    }

    updateFavicon(logo) {
        const favicon = document.getElementById('favicon');
        favicon.href = logo;
        return true;
    }

    render() {
        const { t } = this.props;
        const {
            logo,
            name,
            account_name,
            favicon,
            brandingLoaded,
            privacyUrl,
        } = this.props.brandingStore;
        const { layoutTitle } = this.props.store;
        const date = new Date();

        return (
            <div className="layout_wrapper">
                {brandingLoaded && this.updateFavicon(favicon) && (
                    <Helmet
                        titleTemplate={'%s | ' + account_name}
                        defaultTitle={`${account_name}`}
                    />
                )}
                <div
                    onClick={() => this.toggleNavbar()}
                    className={
                        this.state.isOpen
                            ? 'overlay-black visible'
                            : 'overlay-black'
                    }
                />
                {this.props.authStore.currentUser && (
                    <ul
                        id="navToggle"
                        onClick={() => this.toggleNavbar()}
                        className="burger slide"
                    >
                        <li />
                        <li />
                        <li />
                    </ul>
                )}
                <header className={this.state.isOpen ? 'slide open' : 'slide'}>
                    <div className="container full">
                        <div className="header">
                            {brandingLoaded ? (
                                <Link to="/" className="logo_box">
                                    <img
                                        src={logo}
                                        alt={'My ' + account_name}
                                    />
                                </Link>
                            ) : (
                                <span className="logo_box"></span>
                            )}
                            <div className="header-title">{layoutTitle}</div>
                            <div className="close">
                                <img
                                    src="/static/img/icon-close.png"
                                    alt={t('Close the menu')}
                                />
                            </div>

                            <UserMenu
                                sendFeedback={this.props.sendFeedback}
                                handleSignout={this.props.handleSignout}
                                switchLanguage={this.props.switchLanguage}
                            />
                        </div>
                    </div>
                </header>
                {this.state.notification && (
                    <Alert color={this.state.notificationType}>
                        {this.state.notificationMessage}
                    </Alert>
                )}

                <div className="mobile-header">
                    <div className="logo_mobile">
                        {brandingLoaded && (
                            <Link to="/" className="logo_box">
                                <img src={logo} alt={'My ' + name} />
                            </Link>
                        )}
                    </div>
                </div>

                <div className="container-wrapper">{this.props.children}</div>

                <footer>
                    <a
                        href={privacyUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Privacy Policy
                    </a>{' '}
                    &nbsp;&#183; © {date.getFullYear()}{' '}
                    {(brandingLoaded && account_name) || 'AwareGO'}
                </footer>
            </div>
        );
    }
}

export default withRouter(Layout);
