import CommonStore from './commonStore';
import UserStore from './users';
import MainStore from './viewer';
import CompanyStore from './company';
import QuestionStore from './questions';
import AuthStore from './authStore';
import TrainingStore from './trainings';
import AssessmentStore from './assessments';
import BrandingStore from './brandingStore';
import TestStore from './test';

const companyStore = new CompanyStore();
const commonStore = new CommonStore(companyStore);
const userStore = new UserStore();
const brandingStore = new BrandingStore(companyStore);
const questionStore = new QuestionStore(companyStore);
const authStore = new AuthStore(commonStore, companyStore, brandingStore);
const mainStore = new MainStore(commonStore, authStore, companyStore);

const trainingStore = new TrainingStore(authStore, companyStore, mainStore);
const assessmentStore = new AssessmentStore(
    authStore,
    companyStore,
    mainStore,
    commonStore
);
const stores = {
    authStore,
    store: mainStore,
    commonStore,
    brandingStore,
    userStore,
    companyStore,
    questionStore,
    trainingStore,
    assessmentStore,
    testStore: new TestStore(),
};

export default stores;
