import { Col, Row } from 'reactstrap';
import ThemedFooterButton from '../themed/ThemedFooterButton';
import pluralize from 'pluralize';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { formatDateAndTime } from '../../utils/helpers';

export default ({ onStart, assessment }) => {
    const { t } = useTranslation();

    return assessment != null ? (
        <Row>
            <Col md="6" xs="11" className="quiz-slide">
                <h3>{assessment.name}</h3>
                <h5>
                    {assessment.definition.questions.length}{' '}
                    {pluralize(
                        t('question'),
                        assessment.definition.questions.length
                    )}{' '}
                </h5>
                <h5 className="mobileOnly">
                    <i className="date-icon due"></i>
                    {t('Due on')} {formatDateAndTime(assessment.due)} {t('GMT')}
                </h5>
                <br />
                {assessment.due && (
                    <Trans
                        components={{ p: <p />, italic: <i />, b: <strong /> }}
                    >
                        {t('assessment_intro_message_with_due_date', {
                            assessmentDueDate: formatDateAndTime(
                                assessment.due
                            ),
                        })}
                    </Trans>
                )}
                <img
                    className="hide-ie mobileOnly"
                    src="/static/img/assessment/quiz-demo.svg"
                    width={350}
                    alt={t('Quiz')}
                />
                <br />
                <div className={'actions desktopOnly'}>
                    <ThemedFooterButton primary onClick={onStart}>
                        {t('Start now')}
                    </ThemedFooterButton>
                </div>
            </Col>
            <Col md="6" xs="11">
                <img
                    src="/static/img/assessment/quiz-demo.svg"
                    width={350}
                    className="text-center mt30 hide-ie desktopOnly"
                    alt={t('Quiz')}
                />
            </Col>
        </Row>
    ) : null;
};
