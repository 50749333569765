import RestService from './RestService';
import qs from 'querystring';

export default class Assessments extends RestService {
    constructor(parentService, parentId) {
        if (parentService && parentId) {
            super(`${parentService.baseUrl}/${parentId}/assessments`);
        } else super('/assessments');
    }

    myAssessments(params) {
        return this.get(
            `${this.baseUrl}/my-assessments?${qs.stringify(params)}`
        );
    }

    myAssessment(id, params) {
        return this.get(
            `${this.baseUrl}/my-assessment/${id}?${qs.stringify(params)}`
        );
    }

    startAssessment(id) {
        return this.post(`${this.baseUrl}/my-assessment/${id}/run`, {});
    }

    questionAction(assessmentId, questionId, action, value) {
        return this.post(
            `${this.baseUrl}/my-assessment/${assessmentId}/question/${questionId}/action`,
            { action, value }
        );
    }

    questionActions(assessmentId, questionId, actions) {
        return this.post(
            `${this.baseUrl}/my-assessment/${assessmentId}/question/${questionId}/action`,
            { actions }
        );
    }

    questionActionsPreview(questionId, actions, questions) {
        return this.post(
            `${this.baseUrl}/my-assessment/question/${questionId}/preview`,
            { actions, questions }
        );
    }

    completeQuestion(assessmentId, questionId) {
        return this.questionAction(
            assessmentId,
            questionId,
            'complete_question'
        );
    }

    results(id) {
        return this.get(`${this.baseUrl}/my-assessment/${id}/results`);
    }

    answers(id) {
        return this.get(`${this.baseUrl}/my-assessment/${id}/answers`);
    }
}
