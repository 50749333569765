import React, { Fragment, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Route, Switch, useHistory, Redirect } from 'react-router';
import { Container } from 'reactstrap';
import Layout from './layouts/layout';
import NotFound from './components/NotFound';
import PrivateRoute from './hocs/PrivateRoute';
import AuthRoute from './hocs/AuthRoute';
import Waiting from './components/waiting';
import SignIn from './containers/SignIn';
import TokenSignIn from './containers/TokenSignIn';
import Question from './containers/Question';
import Home from './containers/Home/Home';
import Trainings from './containers/Trainings';
import Assessments from './containers/Assessments';
import CheckEmail from './containers/CheckEmail';
import EmailSignInError from './containers/EmailSignInError';
import DirectSignIn from './containers/DirectSignIn';
import { enforceSlash } from './utils/helpers';
import { EVENTS } from './utils/constants';
import { ThemeProvider } from 'styled-components';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { I18nextProvider } from 'react-i18next';
import DirectionProvider, {
    DIRECTIONS,
} from 'react-with-direction/dist/DirectionProvider';
import AssessmentsTest from './containers/Assessments/AssessmentsTest';
import SSO from './containers/SSO';
import * as Sentry from '@sentry/react';
import Testcomp from './containers/Testcomp';
import TestPage from './containers/testing';
import { useStores } from './hooks/use-stores';
import { useRouteMatch } from 'react-router-dom';

function App() {
    const history = useHistory();

    const { store, companyStore, authStore, commonStore, brandingStore } =
        useStores();
    const { theme, muiTheme, brandingLoaded } = brandingStore;
    const { appLoaded, languagesLoaded, i18n } = commonStore;
    const { isAuthenticated, company_id, subject_id, token, currentUser } =
        authStore;

    const management_url = process.env.REACT_APP_LMS_URL;
    const { currentCompany } = companyStore;
    const [companyId, setCompanyId] = useState();

    useEffect(() => {
        if (
            companyId &&
            currentCompany &&
            companyId !== currentCompany.company_id
        ) {
            history.push('/');
        }
        setCompanyId(currentCompany && currentCompany.company_id);
    }, [companyId, currentCompany, history]);

    const trainingMatch = useRouteMatch(`/trainings/:trainingId`);

    useEffect(() => {
        if (currentCompany && currentUser) {
            //process utm when both company and user initialized
            const queryParams = new URLSearchParams(location.search);
            let utmSource, utmTerm;
            if (queryParams.has('utm_source')) {
                utmSource = queryParams.get('utm_source');
                queryParams.delete('utm_source');
            }
            if (queryParams.has('utm_term')) {
                utmTerm = queryParams.get('utm_term');
                queryParams.delete('utm_term');
            }
            if (utmSource || utmTerm) {
                const trainingId = trainingMatch
                    ? trainingMatch.params.trainingId
                    : queryParams.get('t');
                store.onLogEvent(trainingId, null, EVENTS.NOTIFICATION_LINK, {
                    utm_source: utmSource,
                    utm_term: utmTerm,
                });
                history.replace({
                    search: queryParams.toString(),
                });
            }
        }
    }, [currentCompany, currentUser, trainingMatch, history]);

    useEffect(() => {
        (async () => {
            if (token) {
                try {
                    await Promise.all([
                        authStore.pullUser(),
                        commonStore.loadLanguages(),
                    ]);
                } finally {
                    commonStore.setAppLoaded();
                }
            } else {
                await Promise.all([
                    brandingStore.loadBranding(),
                    commonStore.loadLanguages(),
                ]);
                await commonStore.setAppLoaded();
            }
        })();
    }, []);

    const handleSignout = () => {
        authStore.logout();
    };

    const sendFeedback = () => {
        Sentry.withScope(function (scope) {
            scope.setTag('feedback', 'yes');
            // eslint-disable-next-line no-undef
            scope.setTag('modernizr.video', Modernizr.video);
            // eslint-disable-next-line no-undef
            scope.setTag('modernizr.video.h264', Modernizr.video.h264);
            // eslint-disable-next-line no-undef
            scope.setTag('modernizr.video.ogg', Modernizr.video.ogg);
            // eslint-disable-next-line no-undef
            scope.setTag('modernizr.video.vp9', Modernizr.video.vp9);
            // eslint-disable-next-line no-undef
            scope.setTag('modernizr.video.webm', Modernizr.video.webm);
            // eslint-disable-next-line no-undef
            scope.setTag('modernizr.video.hls', Modernizr.video.hls);
            scope.setLevel('warning');
            Sentry.captureException(new Error('User Feedback Submitted'));
            Sentry.showReportDialog({ labelSubmit: 'Send Feedback' });
        });
    };

    const switchLanguage = async (language_code) => {
        if (authStore.isAuthenticated) {
            await store.setLanguage(language_code);
        }
    };

    const getDirection = () => {
        const languageObject = commonStore.languages.find((obj) => {
            return obj.code === store.language;
        });

        if (languageObject && languageObject.rtl) {
            return DIRECTIONS.RTL;
        }
        return DIRECTIONS.LTR;
    };

    return (
        <MuiThemeProvider theme={muiTheme}>
            <ThemeProvider theme={theme}>
                {languagesLoaded && (
                    <I18nextProvider i18n={i18n}>
                        <DirectionProvider direction={getDirection()}>
                            <Layout
                                sendFeedback={sendFeedback}
                                handleSignout={handleSignout}
                                isAuthenticated={isAuthenticated}
                                switchLanguage={switchLanguage}
                            >
                                <Route
                                    path="/"
                                    render={(props) => {
                                        let normalizedPath = enforceSlash(
                                            props.location.pathname.replace(
                                                /\/\//g,
                                                '/'
                                            )
                                        );
                                        if (
                                            commonStore.lastPage !==
                                            normalizedPath
                                        ) {
                                            commonStore.analyticsPageView(
                                                `${normalizedPath}`
                                            );
                                        }
                                        commonStore.lastPage = normalizedPath;
                                        return null;
                                    }}
                                />
                                <Switch>
                                    {/*<Route path="/auth/email/signin/:token" exact component={EmailSignIn}/>*/}
                                    <Route
                                        path="/auth/direct/signin/:token"
                                        exact
                                        component={DirectSignIn}
                                    />
                                    <Route
                                        path="/auth/jwt/signin/:token"
                                        exact
                                        component={TokenSignIn}
                                    />
                                    <Route
                                        render={() => {
                                            return (
                                                <Fragment>
                                                    {appLoaded &&
                                                    brandingLoaded ? (
                                                        <Switch>
                                                            {process.env
                                                                .REACT_APP_TESTING && (
                                                                <Route
                                                                    path="/testing"
                                                                    component={
                                                                        TestPage
                                                                    }
                                                                />
                                                            )}
                                                            <AuthRoute
                                                                path="/auth/signin"
                                                                exact
                                                                component={
                                                                    SignIn
                                                                }
                                                                company_id={
                                                                    company_id
                                                                }
                                                                subject_id={
                                                                    subject_id
                                                                }
                                                            />
                                                            <AuthRoute
                                                                path="/auth/check-email"
                                                                exact
                                                                component={
                                                                    CheckEmail
                                                                }
                                                            />
                                                            <Route
                                                                path="/auth/error/email"
                                                                exact
                                                                component={
                                                                    EmailSignInError
                                                                }
                                                            />
                                                            <Route
                                                                path="/sso"
                                                                exact
                                                                component={SSO}
                                                            />
                                                            {currentCompany &&
                                                            currentCompany.isTrainingEnabled ? (
                                                                <PrivateRoute
                                                                    path="/trainings"
                                                                    component={
                                                                        Trainings
                                                                    }
                                                                    companyId={
                                                                        companyId
                                                                    }
                                                                />
                                                            ) : (
                                                                <PrivateRoute path="/trainings">
                                                                    <Redirect
                                                                        to={'/'}
                                                                    />
                                                                </PrivateRoute>
                                                            )}
                                                            {currentCompany &&
                                                            currentCompany.isAssessmentEnabled ? (
                                                                <PrivateRoute
                                                                    path="/assessments"
                                                                    component={
                                                                        Assessments
                                                                    }
                                                                    companyId={
                                                                        companyId
                                                                    }
                                                                />
                                                            ) : (
                                                                <PrivateRoute path="/assessments">
                                                                    <Redirect
                                                                        to={'/'}
                                                                    />
                                                                </PrivateRoute>
                                                            )}
                                                            <Route
                                                                path={`/assessment/question/:id`}
                                                                render={(
                                                                    props
                                                                ) => (
                                                                    <Question
                                                                        match={
                                                                            props.match
                                                                        }
                                                                        companyId={
                                                                            companyId
                                                                        }
                                                                        questionId={
                                                                            props
                                                                                .match
                                                                                .params
                                                                                .id
                                                                        }
                                                                    />
                                                                )}
                                                            />

                                                            <Route
                                                                path={`/assessment/playground/`}
                                                                render={() => (
                                                                    <AssessmentsTest />
                                                                )}
                                                            />
                                                            <Route
                                                                path={`/testcomp`}
                                                                render={() => (
                                                                    <Testcomp />
                                                                )}
                                                            />
                                                            <PrivateRoute
                                                                path="/"
                                                                exact
                                                                component={Home}
                                                            />
                                                            <Route
                                                                path="/manager"
                                                                component={() =>
                                                                    (window.location =
                                                                        management_url)
                                                                }
                                                            />
                                                            {/* Finally, catch all unmatched routes */}
                                                            <Route
                                                                component={
                                                                    NotFound
                                                                }
                                                            />
                                                        </Switch>
                                                    ) : (
                                                        <Container className="main-content">
                                                            <Waiting
                                                                waitingActive={
                                                                    appLoaded
                                                                }
                                                                fullScreen={
                                                                    true
                                                                }
                                                            />
                                                        </Container>
                                                    )}
                                                </Fragment>
                                            );
                                        }}
                                    />
                                </Switch>
                            </Layout>
                        </DirectionProvider>
                    </I18nextProvider>
                )}
            </ThemeProvider>
        </MuiThemeProvider>
    );
}

export default observer(App);
