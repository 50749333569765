import { observable, action, computed } from 'mobx';
import ReactGA from 'react-ga';
import services from '../services';
import i18n from '../tools/i18n';
import keyBy from 'lodash/keyBy';

ReactGA.initialize('UA-6397241-1', {
    testMode: process.env.NODE_ENV === 'test',
    gaOptions: {
        storage: 'none',
    },
});

class CommonStore {
    @observable message;
    @observable appLoaded;
    @observable messageType;
    @observable messageShown = false;
    @observable confirmationRequired = false;
    @observable confirmationDescription = '';
    @observable confirmationAction = '';
    @observable confirmationOnConfirmAction;
    @observable confirmationTitle = '';
    @observable languagesLoaded = false;
    @observable languages = [];
    @observable i18n;

    constructor(companyStore) {
        this.companyStore = companyStore;
        this.languagesLoader = new Promise((resolve) => {
            this.languagesLoaderResolver = resolve;
        });
    }

    messageTimeout;

    @action hideMessage() {
        this.message = null;
        this.messageShown = false;
        this.messageShown = false;
        try {
            clearTimeout(this.messageTimeout);
        } catch (e) {}
        this.messageTimeout = null;
    }

    @action setAppLoaded() {
        this.appLoaded = true;
    }

    @action success(message) {
        this.showMessage(message, 'success');
    }

    @action warn(message) {
        this.showMessage(message, 'warning');
    }

    @action error(message) {
        this.showMessage(message, 'error');
    }

    @action showMessage(message, type = 'info', delay = 5000) {
        this.hideMessage();
        if (message) {
            this.message = message;
            this.messageShown = true;
            this.messageType = type;
            this.messageTimeout = setTimeout(() => {
                this.hideMessage();
            }, delay);
        }
    }

    getEntityName(entityType, entity) {
        switch (entityType) {
            case 'Request':
                return entity.location;
            case 'User':
                return entity.name || entity.email;
            default:
                return entity;
        }
    }

    @action showDeleteConfirm(entityType, entity, callback) {
        this.showConfirm(
            `Do you really want to delete ${this.getEntityName(
                entityType,
                entity
            )}`,
            'Delete',
            `Delete ${entityType}`,
            callback
        );
    }

    @action showConfirm(
        confirmationDescription,
        confirmationAction,
        confirmationTitle,
        confirmationOnConfirmAction
    ) {
        this.confirmationDescription = confirmationDescription;
        this.confirmationAction = confirmationAction;
        this.confirmationTitle = confirmationTitle;
        this.confirmationOnConfirmAction = confirmationOnConfirmAction;
        this.confirmationRequired = true;
    }

    @action cancelConfirm() {
        this.confirmationDescription = null;
        this.confirmationAction = null;
        this.confirmationTitle = null;
        this.confirmationOnConfirmAction = null;
        this.confirmationRequired = false;
    }

    @action doConfirm() {
        if (this.confirmationOnConfirmAction)
            this.confirmationOnConfirmAction();

        this.cancelConfirm();
    }

    @action
    async loadLanguages() {
        this.languagesLoaded = false;

        try {
            let result = await services.Languages.list();
            this.languages.replace(result);
            this.i18n = i18n.init(this);
            this.languagesLoaderResolver();
            this.languagesLoaded = true;
        } catch (e) {
            console.error(e);
            setTimeout(async () => {
                await this.loadLanguages();
            }, 5000);
        }
    }

    @action analyticsEvent(category, action, label) {
        ReactGA.event({
            category,
            action,
            label,
        });
    }

    @action analyticsPageView(pagePath) {
        ReactGA.pageview(pagePath);
    }

    @computed get languagesIndex() {
        return keyBy(this.languages, 'code');
    }

    @computed get effectiveLanguages() {
        if (!this.languages) return [];
        if (!this.companyStore.currentCompany) return this.languages;

        return (
            this.languages &&
            this.languages.filter(
                (x) =>
                    this.companyStore.currentCompany.languages &&
                    this.companyStore.currentCompany.languages.find(
                        (l) => l.code === x.code
                    )
            )
        );
    }

    @computed get languagesCodes() {
        return this.languages.map((x) => x.code);
    }
}

export default CommonStore;
